import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import { faBook, faChartLine, faCode, faCoins, faMobile, faServer } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react-lite';
import { generatePath } from 'react-router-dom';
import { useCurrentSlug } from '../domain/hook/useCurrentSlug';
import { AppRoutes } from '../router/Routes';
import { SidebarItem } from './SidebarItem';
import { SidebarLinkItem } from './SidebarLinkItem';
import { OrganizationSelector } from './components/organization-selector/OrganizationSelector';

interface IProps {
  sidebarWidth: string;
  borderRightWidth?: number;
  alwaysShow?: boolean;
  onItemSelected?: () => void;
}

export const Sidebar = observer(function Sidebar(props: IProps) {
  const { borderRightWidth = 1 } = props;
  const slug = useCurrentSlug();

  return (
    <Box
      as="nav"
      bg={useColorModeValue('gray.900', 'gray.800')}
      borderColor={useColorModeValue('inherit', 'gray.700')}
      borderRightWidth={borderRightWidth}
      display={props.alwaysShow ? 'unset' : { base: 'none', md: 'unset' }}
      height="100vh"
      left={0}
      overflowX="hidden"
      overflowY="auto"
      pos="fixed"
      top={0}
      w={props.sidebarWidth}
      zIndex={10}
    >
      <Flex direction="column" height="100%" justify="space-between">
        <Flex aria-label="Main Navigation" as="nav" color="gray.600" direction="column" fontSize="sm">
          <OrganizationSelector />
          <SidebarItem
            icon={faChartLine}
            onItemSelected={props.onItemSelected}
            route={generatePath(AppRoutes.dashboard, { slug })}
            text="Dashboard"
          />
          <SidebarItem
            icon={faCode}
            onItemSelected={props.onItemSelected}
            route={generatePath(AppRoutes.smartContract.list, { slug })}
            text="Smart Contracts"
          />
          <SidebarItem
            icon={faServer}
            onItemSelected={props.onItemSelected}
            route={generatePath(AppRoutes.blockchainEndpoint.list, { slug })}
            text="Node/Bundler RPC Endpoints"
          />
          <SidebarItem
            icon={faCoins}
            onItemSelected={props.onItemSelected}
            route={generatePath(AppRoutes.paymaster.list, { slug })}
            text="Paymasters"
          />
          <SidebarLinkItem
            href="https://docs.kriptonio.com/mobile"
            icon={faMobile}
            onItemSelected={props.onItemSelected}
            text="Mobile Apps"
          />
          <SidebarLinkItem
            href="https://docs.kriptonio.com"
            icon={faBook}
            onItemSelected={props.onItemSelected}
            text="Docs"
          />
        </Flex>
      </Flex>
    </Box>
  );
});
