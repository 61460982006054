import { Button } from '@chakra-ui/button';
import { HStack, Text } from '@chakra-ui/layout';
import { Badge, VStack } from '@chakra-ui/react';
import { PaymasterEndpointResponse } from '@web/dto/paymaster/paymasterEndpointResponse';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router';
import { useCurrentSlug } from '../../../../domain/hook/useCurrentSlug';
import { useInstance } from '../../../../domain/hook/useInstance';
import { BlockchainStore } from '../../../../domain/store/BlockchainStore';
import { AppRoutes } from '../../../../router/Routes';
import { usePaymasterTitle } from '../../hooks/usePaymasterTitle';

interface IProps {
  readonly paymasterEndpoint: PaymasterEndpointResponse;
}

export function PaymasterEndpointListItem(props: IProps) {
  const navigate = useNavigate();
  const slug = useCurrentSlug();
  const blockchainStore = useInstance(BlockchainStore);
  const title = usePaymasterTitle(props.paymasterEndpoint);

  const blockchain = useMemo(() => {
    return blockchainStore.blockchains.find((b) => b.chainId === props.paymasterEndpoint.paymaster.chainId);
  }, [blockchainStore.blockchains, props.paymasterEndpoint.paymaster.chainId]);

  const showDetails = useCallback(() => {
    navigate(generatePath(AppRoutes.paymaster.general, { id: props.paymasterEndpoint.id, slug }));
  }, [navigate, props.paymasterEndpoint.id, slug]);

  return (
    <HStack
      borderRadius="lg"
      borderWidth={1}
      cursor="pointer"
      justify="space-between"
      minH={50}
      onClick={showDetails}
      p={4}
      shadow="md"
      w="100%"
    >
      <VStack alignItems="flex-start">
        <HStack justify="flex-start" width="100%">
          <Text fontWeight="semibold">{title}</Text>
        </HStack>
        <Text fontSize="sm">
          Created on {format(new Date(props.paymasterEndpoint.createdAt), 'MMM dd yyy, HH:mm:ss')}
        </Text>
      </VStack>
      <HStack flexFlow="row wrap" spacing={2}>
        <Badge colorScheme="green">{blockchain?.fqdn}</Badge>
        <Button onClick={showDetails}>Details</Button>
      </HStack>
    </HStack>
  );
}
