import { Button, Container, FormLabel, Heading, HStack, VStack } from '@chakra-ui/react';
import { CInput, ValidatedForm, ValidatedFormControl } from '@web/toolkit';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import * as yup from 'yup';
import { useVm } from '../../../../domain/hook/useVm';
import { BlockchainNetworkSelect } from './components/BlockchainNetworkSelect';
import { NewBlockchainEndpointRouteVm } from './NewBlockchainEndpointRouteVm';

export const NewBlockchainEndpointRoute = observer(function NewBlockchainEndpointRoute() {
  useTitle('New Blockchain Endpoint | kriptonio');
  const vm = useVm(NewBlockchainEndpointRouteVm);
  const navigate = useNavigate();

  return (
    <Container maxW="container.sm">
      <>
        <HStack justify="space-between" marginY={6}>
          <Heading>New Blockchain Endpoint</Heading>
        </HStack>
        <ValidatedForm onSubmit={vm.createBlockchainEndpoint.run}>
          <VStack spacing={4} width="100%">
            <ValidatedFormControl
              isRequired
              schema={yup.string().required('Please provide blockchain endpoint title')}
              value={vm.form.title}
            >
              <FormLabel>Title</FormLabel>
              <CInput autoFocus onValueChange={vm.setTitle} type="text" value={vm.form.title} />
            </ValidatedFormControl>
            <BlockchainNetworkSelect blockchains={vm.blockchains} onBlockchainChange={vm.setBlockchain} />
          </VStack>
          <HStack mt={6} spacing={2} w="100%">
            <Button onClick={() => navigate(-1)} variant="outline" w="100%">
              Cancel
            </Button>
            <Button colorScheme="blue" isLoading={vm.createBlockchainEndpoint.isBusy} type="submit" w="100%">
              Save
            </Button>
          </HStack>
        </ValidatedForm>
      </>
    </Container>
  );
});
