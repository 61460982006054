import { Select, SelectProps } from '@chakra-ui/react';
import { ChangeEvent, useCallback } from 'react';

interface CCSelectProps extends SelectProps {
  onValueChange?: (value: string) => void;
}

export function CSelect(props: CCSelectProps) {
  const { onValueChange, onChange, ...selectProps } = props;

  const internalOnChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    onChange?.(e);
    onValueChange?.(e.target.value);
  }, [onChange, onValueChange]);

  return <Select onChange={internalOnChange} {...selectProps} />;
}
