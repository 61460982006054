import { ApiErrorCode } from '@web/dto/api/apiErrorCode';
import type { ResetPasswordBody } from '@web/dto/api/resetPasswordBody';
import { action, makeObservable, observable } from 'mobx';
import { ViewModel } from '../../../domain/ViewModel';
import { AuthApi } from '../../../domain/api/AuthApi';
import { AsyncAction } from '../../../domain/async/AsyncAction';
import { NotificationService } from '../../../domain/service/NotificationService';
import { PasswordService } from '../../../domain/service/PasswordService';
import { transient } from '../../../inversify/decorator';
import { AppRoutes } from '../../../router/Routes';

interface RouteParams {
  token: string;
}

@transient()
export class ResetPasswordRouteVm extends ViewModel<unknown, RouteParams> {
  @observable
  public form: ResetPasswordBody = {
    password: '',
    token: '',
  };

  constructor(
    private readonly authApi: AuthApi,
    private readonly notification: NotificationService,
    private readonly passwordService: PasswordService
  ) {
    super();
    makeObservable(this);
  }

  @action
  public override onInit = () => {
    this.form.token = this.params.token;
  };

  @action
  public onPasswordChange = (password: string) => {
    this.form.password = password;
  };

  public validatePasswordStrength = (value: unknown) => {
    const validationError = this.passwordService.validatePassword(value as string);
    if (validationError) {
      throw new Error(validationError);
    }

    return true;
  };

  public resetPassword = new AsyncAction(async () => {
    try {
      const result = await this.authApi.resetPassword(this.form);
      if (result.ok) {
        this.notification.success('Success', 'You can login with your new password');
        return this.navigate(AppRoutes.auth.login);
      }

      if (result.error.code === ApiErrorCode.PasswordWeak) {
        return this.notification.warn(
          'Password weak',
          'Password should contain numbers, special characters and lowercase and uppercase letters.'
        );
      }

      this.notification.error('Error', `Error while processing request. ${result.error.code}`);
    } catch (e) {
      console.error('exception while calling forgetPassword endpoint', e);
      this.notification.error('Exception', 'Exception while processing request. Please check provided data');
    }
  });
}
