import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef } from 'react';

interface IProps {
  readonly isLoading: boolean;
  readonly onClick: () => void;
}

export function DeleteSmartContractDeployment(props: IProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  return (
    <>
      <Button alignSelf="flex-start" colorScheme="red" isLoading={props.isLoading} onClick={onOpen} w="100%">
        Delete Smart Contract Deployment
      </Button>

      <AlertDialog blockScrollOnMount={false} isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Deployment
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure you want to delete deployment?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onClose} ref={cancelRef}>
                Cancel
              </Button>
              <Button colorScheme="red" ml={3} onClick={props.onClick}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
