import type { ActivateUserBody } from '@web/dto/api/activateUserBody';
import type { AppleLoginBody } from '@web/dto/api/appleLoginBody';
import type { ForgotPasswordBody } from '@web/dto/api/forgotPasswordBody';
import type { GoogleLoginBody } from '@web/dto/api/googleLoginBody';
import type { LoginBody } from '@web/dto/api/loginBody';
import type { RegisterBody } from '@web/dto/api/registerBody';
import type { ResendActivationBody } from '@web/dto/api/resendActivationBody';
import type { ResetPasswordBody } from '@web/dto/api/resetPasswordBody';
import type { SessionResponse } from '@web/dto/api/sessionResponse';
import { singleton } from '../../inversify/decorator';
import { AjaxService, IHttpResponse } from '../service/AjaxService';
import { Api } from './Api';

@singleton()
export class AuthApi extends Api {
  constructor(private readonly ajax: AjaxService) {
    super();
  }

  public login = async (data: LoginBody): Promise<IHttpResponse<SessionResponse>> => {
    const result = await this.ajax.post<SessionResponse>('v1/auth/login', { data });
    return this.processLoginResponse(result);
  };

  public googleLogin = async (data: GoogleLoginBody): Promise<IHttpResponse<SessionResponse>> => {
    const result = await this.ajax.post<SessionResponse>('v1/auth/google-login', { data });
    return this.processLoginResponse(result);
  };

  public appleLogin = async (data: AppleLoginBody): Promise<IHttpResponse<SessionResponse>> => {
    const result = await this.ajax.post<SessionResponse>('v1/auth/apple-login', { data });
    return this.processLoginResponse(result);
  };

  public register = (data: RegisterBody): Promise<IHttpResponse<void>> => {
    return this.ajax.post<void>('v1/auth/register', { data });
  };

  public forgotPassword = (data: ForgotPasswordBody): Promise<IHttpResponse<void>> => {
    return this.ajax.post<void>('v1/auth/forgot-password', { data });
  };

  public resetPassword = (data: ResetPasswordBody): Promise<IHttpResponse<void>> => {
    return this.ajax.post<void>('v1/auth/reset-password', { data });
  };

  public activateUser = async (data: ActivateUserBody): Promise<IHttpResponse<SessionResponse>> => {
    const result = await this.ajax.post<SessionResponse>('v1/auth/activate', { data });
    return this.processLoginResponse(result);
  };

  public resendActivation = (data: ResendActivationBody): Promise<IHttpResponse<void>> => {
    return this.ajax.post('v1/auth/resend-activation', { data });
  };

  public restore = async (): Promise<IHttpResponse<SessionResponse>> => {
    const result = await this.ajax.get<SessionResponse>('v1/auth/session');
    return this.processLoginResponse(result);
  };

  public logout = async (): Promise<IHttpResponse<SessionResponse>> => {
    const result = await this.ajax.post<SessionResponse>('v1/auth/logout');
    return this.processLoginResponse(result);
  };

  private processLoginResponse = (result: IHttpResponse<SessionResponse>): IHttpResponse<SessionResponse> => {
    return result;
  };
}
