/* eslint-disable react-hooks/rules-of-hooks */
import { Flex, Heading, Spinner, Text, useColorMode } from '@chakra-ui/react';
import type { DataPeriod } from '@web/dto/rpc/dataPeriod';
import type { ApexOptions } from 'apexcharts';
import { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useGetBlockchainEndpointUsage } from '../../../domain/api/ReportApi';
import { useInstance } from '../../../domain/hook/useInstance';
import { OrganizationStore } from '../../../domain/store/OrganizationStore';
import { dataPeriodToString } from '../../../utils/dataPeriod';

interface IProps {
  readonly period: DataPeriod;
  readonly blockchainEndpointId?: string;
}

export function BlockchainEndpointUsageChart(props: IProps) {
  const { colorMode } = useColorMode();
  const organizationStore = useInstance(OrganizationStore);

  const { data, isValidating } = useGetBlockchainEndpointUsage(organizationStore.currentOrganization?.id ?? '', {
    period: props.period,
    blockchainEndpointId: props.blockchainEndpointId,
  });

  const chartData = useMemo(() => {
    return [
      {
        name: 'Api Calls',
        data:
          data?.map((item) => ({
            x: new Date(item.date),
            y: item.count,
          })) ?? [],
      },
    ];
  }, [data]);

  const options = useMemo((): ApexOptions => {
    return {
      chart: {
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: true,
        },
      },
      theme: {
        mode: colorMode,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false,
        },
      },
      yaxis: {
        min: 0,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.4,
          opacityTo: 0.8,
          stops: [0, 90, 100],
        },
      },
    };
  }, [colorMode]);

  return (
    <Flex borderWidth={1} boxShadow="lg" flexDir="column" pb={2} pt={6} px={4} rounded="lg">
      <Flex justify="space-between" mb={2} mx={4}>
        <Heading size="sm">Usage ({dataPeriodToString(props.period)})</Heading>
      </Flex>
      {!data?.length ? (
        <Flex align="center" height={400} justify="center" mx={6}>
          <Text textAlign="center">
            {isValidating ? (
              <Spinner as="span" />
            ) : (
              'After you interact with blockchain endpoint, stats will show up here'
            )}
          </Text>
        </Flex>
      ) : (
        <Chart height={400} options={options} series={chartData} type="area" />
      )}
    </Flex>
  );
}
