import { ApiErrorCode } from '@web/dto/api/apiErrorCode';
import type { GoogleLoginBody } from '@web/dto/api/googleLoginBody';
import type { InvitationDetailResponse } from '@web/dto/api/invitationDetailResponse';
import type { RegisterBody } from '@web/dto/api/registerBody';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { generatePath } from 'react-router-dom';
import { ViewModel } from '../../../domain/ViewModel';
import { AuthApi } from '../../../domain/api/AuthApi';
import { OrganizationApi } from '../../../domain/api/OrganizationApi';
import { AsyncAction } from '../../../domain/async/AsyncAction';
import { NotificationService } from '../../../domain/service/NotificationService';
import { PasswordService } from '../../../domain/service/PasswordService';
import { AnalyticsEvent } from '../../../domain/service/analytics/AnalyticsEvent';
import { AnalyticsService } from '../../../domain/service/analytics/AnalyticsService';
import { SessionStore } from '../../../domain/store/SessionStore';
import { UiStore } from '../../../domain/store/UiStore';
import { transient } from '../../../inversify/decorator';
import { AppRoutes } from '../../../router/Routes';

@transient()
export class RegisterRouteVm extends ViewModel {
  @observable
  public waitingActivation = false;

  @observable
  public activationEmail = '';

  public invitation: InvitationDetailResponse | null = null;

  @observable
  public registerForm: RegisterBody = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    invitationId: null,
  };

  @observable
  public googleLoginData: GoogleLoginBody = {
    accessToken: '',
    twoFactorCode: null,
  };

  constructor(
    private readonly notification: NotificationService,
    private readonly session: SessionStore,
    public readonly uiStore: UiStore,
    private readonly authApi: AuthApi,
    private readonly analytics: AnalyticsService,
    private readonly passwordService: PasswordService,
    private readonly organizationApi: OrganizationApi
  ) {
    super();
    makeObservable(this);
    this.analytics.track(AnalyticsEvent.RegisterStarted);
  }

  public override onInit = () => {
    const invitationId = this.searchParams.get('invitation');
    if (invitationId) {
      void this.loadInvitation.run(invitationId);
    }
  };

  public loadInvitation = new AsyncAction(async (invitationId: string) => {
    try {
      const result = await this.organizationApi.getInvitation(invitationId);
      if (result.ok) {
        return runInAction(() => {
          this.invitation = result.data;
          this.registerForm.invitationId = invitationId;
          this.registerForm.email = result.data.email;
        });
      }

      console.error('non-200 code while fetching invitation', result.error.code);
    } catch (e) {
      console.error('exception while loading invitation', e);
    }
  });

  @action
  public onFirstNameChange = (firstName: string) => {
    this.registerForm.firstName = firstName;
  };

  @action
  public onLastNameChange = (lastName: string) => {
    this.registerForm.lastName = lastName;
  };

  @action
  public onEmailChange = (email: string) => {
    this.registerForm.email = email;
  };

  @action
  public onPasswordChange = (password: string) => {
    this.registerForm.password = password;
  };

  public validatePasswordStrength = (value: unknown) => {
    const validationError = this.passwordService.validatePassword(value as string);
    if (validationError) {
      throw new Error(validationError);
    }

    return true;
  };

  public googleLogin = new AsyncAction(async () => {
    try {
      const result = await this.session.googleLogin(this.googleLoginData);
      if (result.ok) {
        this.analytics.track(AnalyticsEvent.RegisterCompleted);
        return this.openApp();
      }

      if (result.error.code === ApiErrorCode.MissingTwoFactorCode) {
        this.notification.warn('Account already created', 'You have already created account. Please login');
        return this.navigate(AppRoutes.auth.login);
      }

      this.notification.warn('Registration failed', `Authentication data invalid. ${result.error.code}`);
    } catch (e) {
      console.error(`exception while doing registration. ${e}`);
      this.notification.error('Registration failed', 'Error while registering user');
    }
  });

  public register = new AsyncAction(async () => {
    try {
      const result = await this.authApi.register(this.registerForm);

      if (result.ok) {
        this.analytics.track(AnalyticsEvent.RegisterCompleted);

        return runInAction(() => {
          this.waitingActivation = true;
          this.activationEmail = this.registerForm.email;
        });
      }

      if (result.error.code === ApiErrorCode.UserAlreadyExist) {
        return this.notification.warn('Registration failed', 'User with provided email already exist');
      }

      if (result.error.code === ApiErrorCode.PasswordWeak) {
        return this.notification.warn(
          'Password weak',
          'Password should contain numbers, special characters and lowercase and uppercase letters.'
        );
      }

      this.notification.error('Registration failed', 'Error while registering user');
    } catch (e) {
      console.error(`exception while doing register. ${e}`);
      this.notification.error('Registration failed', 'Error while registering user');
    }
  });

  @computed
  public get isBusy() {
    return this.resendActivation.isBusy || this.activate.isBusy;
  }

  public resendActivation = new AsyncAction(async () => {
    const result = await this.authApi.resendActivation({
      email: this.activationEmail,
    });

    if (!result.ok) {
      return this.notification.warn(
        'Failed',
        'Currently we are having issues with resending activation code to your email'
      );
    }

    this.notification.success('Success', 'Please check your email for activation instructions');
  });

  public activate = new AsyncAction(async (code: string) => {
    const result = await this.authApi.activateUser({
      email: this.activationEmail,
      code,
    });

    if (!result.ok) {
      return this.notification.warn(
        'Activation failed',
        'Cannot activate your account. Please check is provided code is valid'
      );
    }

    await this.session.setSession(result.data);
    this.openApp();
  });

  public openApp = () => {
    if (this.invitation) {
      return this.navigate(
        generatePath(AppRoutes.dashboard, {
          slug: this.invitation.organization.slug,
        })
      );
    }

    this.navigate(AppRoutes.subscription.welcome);
  };
}
