import type { CloneSmartContractBody } from '@web/dto/api/cloneSmartContractBody';
import { CreateSmartContractDeploymentBody } from '@web/dto/api/createSmartContractDeploymentBody';
import type { CreateSmartContractFromTemplateBody } from '@web/dto/api/createSmartContractFromTemplateBody';
import type { CreateSmartContractResponse } from '@web/dto/api/createSmartContractResponse';
import { SmartContractDeploymentResponse } from '@web/dto/api/smartContractDeploymentResponse';
import type { SmartContractDetailResponse } from '@web/dto/api/smartContractDetailResponse';
import type { SmartContractResponse } from '@web/dto/api/smartContractResponse';
import { env } from '../../env';
import { singleton } from '../../inversify/decorator';
import { useSwr } from '../hook/useSwr';
import { AjaxService, IHttpResponse } from '../service/AjaxService';
import { Api } from './Api';

@singleton()
export class SmartContractApi extends Api {
  constructor(private readonly ajax: AjaxService) {
    super();
  }

  public getSmartContract = (id: string): Promise<IHttpResponse<SmartContractDetailResponse>> => {
    return this.ajax.get(`v1/smart-contracts/${id}`);
  };

  public cloneSmartContract = (
    id: string,
    data: CloneSmartContractBody
  ): Promise<IHttpResponse<CreateSmartContractResponse>> => {
    return this.ajax.post(`v1/smart-contracts/${id}/clone`, { data });
  };

  public createSmartContractFromFile = (
    organizationId: string,
    data: FormData
  ): Promise<IHttpResponse<CreateSmartContractResponse>> => {
    return this.ajax.post(`v1/organizations/${organizationId}/smart-contracts/file`, { data });
  };

  public createSmartContractFromTemplate = (
    organizationId: string,
    data: CreateSmartContractFromTemplateBody
  ): Promise<IHttpResponse<CreateSmartContractResponse>> => {
    return this.ajax.post(`v1/organizations/${organizationId}/smart-contracts/template`, { data });
  };

  public createDeployment = (id: string, data: CreateSmartContractDeploymentBody) => {
    return this.ajax.post<SmartContractDeploymentResponse>(`/v1/smart-contracts/${id}/deployment`, {
      data,
    });
  };

  public contractCodeUrl = (id: string) => `${env.api}/v1/smart-contracts/${id}/contract`;

  public deleteDeployment = (id: string): Promise<IHttpResponse<void>> => {
    return this.ajax.delete(`v1/smart-contracts/${id}/deployment`);
  };

  public delete = (id: string): Promise<IHttpResponse<void>> => {
    return this.ajax.delete(`v1/smart-contracts/${id}`);
  };
}

export function useGetSmartContracts(organizationId: string) {
  return useSwr<SmartContractResponse[]>(`v1/organizations/${organizationId}/smart-contracts`);
}
