import { Container, Heading, HStack, SimpleGrid } from '@chakra-ui/react';
import { faExclamation, faServer } from '@fortawesome/free-solid-svg-icons';
import type { DataPeriod } from '@web/dto/rpc/dataPeriod';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTitle } from 'react-use';
import { useGetBlockchainEndpointCallStats } from '../../domain/api/ReportApi';
import { useInstance } from '../../domain/hook/useInstance';
import { OrganizationStore } from '../../domain/store/OrganizationStore';
import { dataPeriodToString } from '../../utils/dataPeriod';
import { BlockchainEndpointMethodUsageChart } from './components/BlockchainEndpointMethodUsageChart';
import { BlockchainEndpointUsageChart } from './components/BlockchainEndpointUsageChart';
import { EntityStats } from './components/EntityStats';
import { PeriodSelector } from './components/PeriodSelector';
import { Stat } from './components/Stat';

export const DashboardRoute = observer(function DashboardRoute() {
  useTitle('Dashboard | kriptonio');
  const [period, setPeriod] = useState<DataPeriod>('TwentyFourHours');
  const organizationStore = useInstance(OrganizationStore);

  const { data: callStats, isValidating } = useGetBlockchainEndpointCallStats(
    organizationStore.currentOrganization?.id ?? '',
    {
      period,
    }
  );

  return (
    <Container maxW="container.xl" pb={10} position="relative">
      <HStack justify="space-between">
        <Heading mb={4} mt={6}>
          {organizationStore.currentOrganization?.isPersonal
            ? 'Dashboard'
            : `${organizationStore.currentOrganization?.name} Dashboard`}
        </Heading>
      </HStack>
      <EntityStats />
      <SimpleGrid mt={10} spacing={4} width="100%">
        <PeriodSelector
          onPeriodSelected={setPeriod}
          period={period}
          title="Summary of Blockchain Endpoints Activities"
        />
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
          <Stat
            icon={faServer}
            loading={isValidating}
            number={callStats?.calls}
            title={`RPC Calls (${dataPeriodToString(period)})`}
          />
          <Stat
            icon={faExclamation}
            loading={isValidating}
            number={callStats?.errors}
            title={`RPC Errors (${dataPeriodToString(period)})`}
          />
        </SimpleGrid>
        <BlockchainEndpointMethodUsageChart period={period} />
        <BlockchainEndpointUsageChart period={period} />
      </SimpleGrid>
    </Container>
  );
});
