import { Button, Center, Heading, Spinner, Text, VStack } from '@chakra-ui/react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { SubscriptionApi } from '../../../domain/api/SubscriptionApi';
import { useInstance } from '../../../domain/hook/useInstance';
import { AnalyticsEvent } from '../../../domain/service/analytics/AnalyticsEvent';
import { AnalyticsService } from '../../../domain/service/analytics/AnalyticsService';
import { OrganizationStore } from '../../../domain/store/OrganizationStore';
import { AppRoutes } from '../../../router/Routes';

export function SubscriptionPaymentSuccessRoute() {
  const [queryParams] = useSearchParams();
  const analytics = useInstance(AnalyticsService);
  const subscriptionApi = useInstance(SubscriptionApi);
  const sessionId = queryParams.get('session_id');
  const organizationStore = useInstance(OrganizationStore);
  const [processed, setProcessed] = useState<boolean | null>(sessionId ? null : true);

  useEffect(() => {
    analytics.track(AnalyticsEvent.PurcaseCompleted);
  }, [analytics]);

  const [, doFetch] = useAsyncFn(async () => {
    if (!sessionId) {
      console.warn('missing session id');
      return false;
    }

    const result = await subscriptionApi.getSubscriptionBySessionId(sessionId);
    return result.ok;
  }, [sessionId]);

  const checkStatus = useCallback(
    (retries: number) => {
      setTimeout(() => {
        doFetch()
          .then((processed) => {
            if (processed) {
              return setProcessed(processed);
            }

            if (!processed && retries < 2) {
              return checkStatus(retries + 1);
            }

            return setProcessed(processed);
          })
          .catch((e) => {
            console.error('error while fetching status', e);
          });
      }, 1000);
    },
    [doFetch]
  );

  useEffect(() => {
    if (processed) {
      void organizationStore.load();
    }
  }, [organizationStore, processed]);

  useEffect(() => {
    if (!processed) {
      checkStatus(0);
    }
  }, [processed, checkStatus]);

  return (
    <Center pt="10%">
      <VStack maxW="container.sm" align="center" spacing={8}>
        {processed == null ? (
          <Spinner size="lg" />
        ) : (
          <>
            <VStack spacing={4}>
              <Center w={50} h={50} borderRadius="50%" bgColor="green.400" p={10}>
                <FontAwesomeIcon icon={faCheck} color="white" size="3x" />
              </Center>
              <Heading>Payment successful</Heading>
            </VStack>
            {processed === true ? (
              <>
                <Text textAlign="center" fontSize="lg">
                  Your subscription has been successfully updated.
                  <br />
                  Enjoy your subscription plan!
                </Text>
                <Button as={Link} to={AppRoutes.index} colorScheme="blue">
                  Continue
                </Button>
              </>
            ) : (
              <>
                <Text textAlign="center" fontSize="lg">
                  We are processing your order now. Processing usually takes a few minutes. <br />
                  We will notify you via email when the processing is done.
                </Text>
                <Button as={Link} to={AppRoutes.index} colorScheme="blue">
                  Continue
                </Button>
              </>
            )}
          </>
        )}
      </VStack>
    </Center>
  );
}
