import { Box, Button, Divider, FormControl, FormLabel, Heading, HStack, Stack } from '@chakra-ui/react';
import { CInput, ValidatedForm, ValidatedFormControl } from '@web/toolkit';
import { observer } from 'mobx-react-lite';
import { useTitle } from 'react-use';
import * as yup from 'yup';
import { useVm } from '../../../domain/hook/useVm';
import { capitalize } from '../../../utils/strings';
import { AccountSettingsRouteVm } from './AccountSettingsRouteVm';

export const AccountSettingsRoute = observer(function AccountSettingsRoute() {
  useTitle('Account | kriptonio');
  const vm = useVm(AccountSettingsRouteVm);

  return (
    <Box maxW={700} pb={10} w="100%">
      <HStack justify="space-between">
        <Heading size="lg">Account</Heading>
      </HStack>
      <Divider mb={4} mt={2} />

      <ValidatedForm onSubmit={vm.update.run}>
        <Stack spacing={4}>
          <ValidatedFormControl schema={yup.string().required('Please provide First Name')} value={vm.form.firstName}>
            <FormLabel>First Name</FormLabel>
            <CInput onValueChange={vm.onFirstNameChange} value={vm.form.firstName} />
          </ValidatedFormControl>
          <ValidatedFormControl schema={yup.string().required('Please provide Last Name')} value={vm.form.lastName}>
            <FormLabel>Last Name</FormLabel>
            <CInput onValueChange={vm.onLastNameChange} value={vm.form.lastName} />
          </ValidatedFormControl>
          <FormControl>
            <FormLabel>Email address</FormLabel>
            <CInput isDisabled type="email" value={vm.email} />
          </FormControl>
          <FormControl>
            <FormLabel>Auth Method</FormLabel>
            <CInput isDisabled value={capitalize(vm.authMethod)} />
          </FormControl>
          <Button colorScheme="blue" isLoading={vm.update.isBusy} type="submit">
            Save
          </Button>
        </Stack>
      </ValidatedForm>
    </Box>
  );
});
