import { Box, Divider, Heading, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useInstance } from '../../../domain/hook/useInstance';
import { SessionStore } from '../../../domain/store/SessionStore';
import { DisableTwoFactorModal } from '../account/components/disable-two-factor/DisableTwoFactorModal';
import { SetupTwoFactorModal } from '../account/components/setup-two-factor/SetupTwoFactorModal';

export function SecuritySettingsRoute() {
  const session = useInstance(SessionStore);
  const user = session.session?.user;

  const { isOpen: isSetupModalOpen, onOpen: onOpenSetup, onClose: onCloseSetup } = useDisclosure();

  const { isOpen: isDisableModalOpen, onOpen: onOpenDisable, onClose: onCloseDisable } = useDisclosure();

  return (
    <Box maxW={700} pb={10} w="100%">
      <HStack justify="space-between">
        <Heading size="lg">Security</Heading>
      </HStack>
      <Divider mb={4} mt={2} />
      <Stack mt={4}>
        <Box>
          <Text fontSize="md" fontWeight="semibold">
            Two Factor Authentication
          </Text>
          {user?.twoFactorEnabled ? (
            <Box cursor="pointer" onClick={onOpenDisable}>
              <Text color="blue.500">Disable</Text>
            </Box>
          ) : (
            <Box cursor="pointer" onClick={onOpenSetup}>
              <Text color="blue.500">Enable</Text>
            </Box>
          )}

          <SetupTwoFactorModal isOpen={isSetupModalOpen} onClose={onCloseSetup} />
          <DisableTwoFactorModal isOpen={isDisableModalOpen} onClose={onCloseDisable} />
        </Box>
      </Stack>
    </Box>
  );
}
