import type { InviteUserBody } from '@web/dto/api/inviteUserBody';
import { action, computed, makeObservable, observable } from 'mobx';
import { OrganizationApi } from '../../../domain/api/OrganizationApi';
import { AsyncAction } from '../../../domain/async/AsyncAction';
import { NotificationService } from '../../../domain/service/NotificationService';
import { OrganizationStore } from '../../../domain/store/OrganizationStore';
import { SessionStore } from '../../../domain/store/SessionStore';
import { ViewModel } from '../../../domain/ViewModel';
import { transient } from '../../../inversify/decorator';

@transient()
export class OrganizationUsersRouteVm extends ViewModel {
  constructor(
    private readonly sessionStore: SessionStore,
    private readonly organizationStore: OrganizationStore,
    private readonly organizationApi: OrganizationApi,
    private readonly notification: NotificationService
  ) {
    super();
    makeObservable(this);
  }

  @observable
  public form: InviteUserBody = {
    email: '',
  };

  @action
  public setEmail = (value: string) => {
    this.form.email = value;
  };

  @computed
  public get currentOrganization() {
    return this.organizationStore.currentOrganization;
  }

  @computed
  public get currentUserRole() {
    return this.currentOrganization?.members.find((m) => m.user.id === this.currentUser?.id)?.role;
  }

  @computed
  public get currentUser() {
    return this.sessionStore.session?.user;
  }

  @action
  public reset = () => {
    this.form = {
      email: '',
    };
  };

  public invite = new AsyncAction(async () => {
    try {
      const result = await this.organizationApi.invite(this.currentOrganization?.id ?? '', this.form);
      if (result.ok) {
        this.reset();
        void this.organizationStore.load();
        return this.notification.success('Success', 'User invited');
      }

      this.notification.error('Error', `Error while inviting user. ${result.error.code}`);
    } catch (e) {
      console.error(e);
      this.notification.error('Exception', 'Exception while inviting user.');
    }
  });
}
