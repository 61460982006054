import { decorate, injectable, interfaces } from 'inversify';

import { container } from './container';

export enum LifecycleScope {
  Transient,
  Singleton,
}

const bindOrRebind = <T>(service: interfaces.ServiceIdentifier<T>) => {
  return container.isBound(service) ? container.rebind(service) : container.bind(service);
};

/**
 * The provide() helper from inversify cannot reference the underlying class
 * This is a workaround to register the class as itself within Inversify
 */
const provideDecorator = (scope: LifecycleScope) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (ctor: { new (...args: any[]): any }) => {
    if (scope === LifecycleScope.Singleton) {
      bindOrRebind(ctor).toSelf().inSingletonScope();
    } else if (scope === LifecycleScope.Transient) {
      bindOrRebind(ctor).toSelf().inTransientScope();
    } else {
      throw new Error('Unknown lifecycle scope: ' + scope);
    }

    decorate(injectable(), ctor);
    return ctor;
  };
};

export const singleton = provideDecorator.bind(this, LifecycleScope.Singleton);
export const transient = provideDecorator.bind(this, LifecycleScope.Transient);
