import { Box, Button, Divider, FormLabel, Heading, HStack, Stack } from '@chakra-ui/react';
import { CInput, ValidatedForm, ValidatedFormControl } from '@web/toolkit';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';
import { useVm } from '../../../domain/hook/useVm';
import { OrganizationSettingsRouteVm } from './OrganizationSettingsRouteVm';

export const OrganizationSettingsRoute = observer(function OrganizationSettingsRoute() {
  const vm = useVm(OrganizationSettingsRouteVm);

  return (
    <Box maxW={700} pb={10} w="100%">
      <HStack justify="space-between">
        <Heading size="lg">Organization</Heading>
      </HStack>
      <Divider mb={4} mt={2} />

      <ValidatedForm onSubmit={vm.update.run}>
        <Stack spacing={4}>
          <ValidatedFormControl schema={yup.string().required('Please provide First Name')} value={vm.form.name}>
            <FormLabel>Name</FormLabel>
            <CInput onValueChange={vm.onNameChange} value={vm.form.name} />
          </ValidatedFormControl>
          <Button colorScheme="blue" isLoading={vm.update.isBusy} type="submit">
            Save
          </Button>
        </Stack>
      </ValidatedForm>
    </Box>
  );
});
