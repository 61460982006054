import { createWeb3Modal, defaultConfig } from '@web3modal/ethers';
import { Chain } from '@web3modal/scaffold-utils/ethers';
import { env } from '../../env';
import { singleton } from '../../inversify/decorator';
import { getChain } from '../../utils/chain';
import { BlockchainEndpointApi } from '../api/BlockchainEndpointApi';
import { OrganizationStore } from '../store/OrganizationStore';

@singleton()
export class WalletConnectService {
  constructor(
    private readonly organizationStore: OrganizationStore,
    private readonly blockchainEndpointApi: BlockchainEndpointApi
  ) {}

  public connect = async (chainId: number) => {
    if (!this.organizationStore.currentOrganization?.id) {
      throw new Error('Organization not found, cannot connect WalletConnect');
    }

    const result = await this.blockchainEndpointApi.getOrCreate(this.organizationStore.currentOrganization.id, chainId);
    if (!result.ok) {
      throw new Error('Error while getting rpc url, cannot connect WalletConnect');
    }

    const chains: Chain[] = [getChain(chainId)].map(
      (chain) =>
        ({
          chainId: chain.id,
          name: chain.name,
          currency: chain.nativeCurrency.symbol,
          explorerUrl: chain.blockExplorers?.default.url,
          rpcUrl: result.data.url,
        }) as Chain
    );

    const metadata = {
      name: 'Kriptonio',
      description: 'Web3 Platform',
      url: 'https://kriptonio.com',
      icons: ['https://kriptonio.com/icon.png'],
    };

    const modal = createWeb3Modal({
      ethersConfig: defaultConfig({ metadata }),
      featuredWalletIds: ['50df7da345f84e5a79aaf617df5167335a4b6751626df2e8a38f07029b3dde7b'],
      includeWalletIds: ['50df7da345f84e5a79aaf617df5167335a4b6751626df2e8a38f07029b3dde7b'],
      chains,
      themeMode: 'light',
      projectId: env.walletConnect.projectId,
    });

    return modal;
  };
}
