import { KriptonioSdk } from '@kriptonio/sdk';
import type { OrganizationDetailResponse } from '@web/dto/api/organizationDetailResponse';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { env } from '../../env';
import { singleton } from '../../inversify/decorator';
import { OrganizationApi } from '../api/OrganizationApi';
import { SessionStore } from './SessionStore';

@singleton()
export class OrganizationStore {
  @observable
  private internalOrganizations: OrganizationDetailResponse[] = [];

  @observable
  private currentOrganizationId: string | null = null;

  private lastOrganizationKey = 'kriptonio-last-organization-id';

  constructor(
    private readonly organizationApi: OrganizationApi,
    private readonly session: SessionStore
  ) {
    makeObservable(this);
  }

  @computed
  public get sdk() {
    const sdk = new KriptonioSdk({ accessToken: '' });
    sdk.configure({
      organizationId: this.currentOrganizationId ?? undefined,
      sessionToken: this.session.session?.token ?? '',
      apiUrl: env.api,
      rpcApiUrl: env.rpc,
      paymasterApiUrl: env.paymaster,
    });

    return sdk;
  }

  @computed
  public get organizations() {
    return this.internalOrganizations.slice().sort((o1, o2) => (o1.isPersonal && !o2.isPersonal ? -1 : 1));
  }

  @computed
  public get currentOrganization() {
    return this.internalOrganizations.find((o) => o.id === this.currentOrganizationId);
  }

  @computed
  public get currentRole() {
    const member = this.currentOrganization?.members.find((m) => m.user.id === this.session.session?.user.id);
    return member?.role;
  }

  @computed
  public get currentSlug() {
    return this.currentOrganization?.slug;
  }

  public orgForSlug = (slug: string) => {
    return this.organizations.find((o) => o.slug.toLowerCase() === slug.toLowerCase());
  };

  @action
  public selectDefault = (slug: string | undefined) => {
    const lastOrganizationId = window.localStorage.getItem(this.lastOrganizationKey);

    if (slug) {
      this.selectOrganizationId(
        this.internalOrganizations.find((o) => o.slug.toLowerCase() === slug.toLowerCase())?.id ?? null
      );
    } else if (lastOrganizationId) {
      this.selectOrganizationId(lastOrganizationId);
    } else {
      // try to select personal
      this.selectOrganizationId(this.internalOrganizations.find((o) => o.isPersonal)?.id ?? null);
    }

    // if organization not resolved, select first one
    if (!this.currentOrganization && this.internalOrganizations.length) {
      this.selectOrganizationId(this.internalOrganizations[0].id);
    }
  };

  @action
  public selectOrganizationId = (id: string | null) => {
    this.currentOrganizationId = id;

    if (id) {
      window.localStorage.setItem(this.lastOrganizationKey, id);
    } else {
      window.localStorage.removeItem(this.lastOrganizationKey);
    }
  };

  @action
  public pushOrganization = (organization: OrganizationDetailResponse) => {
    this.internalOrganizations.push(organization);
    this.selectOrganizationId(organization.id);
  };

  public load = async () => {
    try {
      const result = await this.organizationApi.getOrganizations();
      if (result.ok) {
        return runInAction(() => {
          this.internalOrganizations = result.data;
        });
      }

      console.error(`error while loading organizations. ${result.error.stringify()}`);
    } catch (e) {
      console.error('exception while loading organizations', e);
    }
  };

  @action
  public reset = () => {
    this.internalOrganizations = [];
    this.selectOrganizationId(null);
  };
}
