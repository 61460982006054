import { KriptonioError } from '@kriptonio/sdk';
import type { AccessTokenResponse } from '@web/dto/api/accessTokenResponse';
import { BlockchainResponse } from '@web/dto/api/blockchainResponse';
import type { SmartContractDetailResponse } from '@web/dto/api/smartContractDetailResponse';
import type { ValidatedFormRef } from '@web/toolkit';
import { Eip1193Provider, ethers } from 'ethers';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import React from 'react';
import type { Hex } from 'viem';
import { Abi, AbiFunction, BaseError, createWalletClient, custom } from 'viem';
import { ViewModel } from '../../../../domain/ViewModel';
import { BlockchainEndpointApi } from '../../../../domain/api/BlockchainEndpointApi';
import { AsyncAction } from '../../../../domain/async/AsyncAction';
import { NotificationService } from '../../../../domain/service/NotificationService';
import { OrganizationStore } from '../../../../domain/store/OrganizationStore';
import { SessionStore } from '../../../../domain/store/SessionStore';
import { transient } from '../../../../inversify/decorator';
import { getChain } from '../../../../utils/chain';
import { formatKriptonioError } from '../../../../utils/rpc';
import { CallParam } from '../../smart-contract/components/CallParams';

export interface SmartContractUiProps {
  abi: Abi;
  smartContract: SmartContractDetailResponse;
  blockchain: BlockchainResponse;
  accessTokens: AccessTokenResponse[];
}

@transient()
export class SmartContractUiVm extends ViewModel<SmartContractUiProps> {
  @observable
  public selectedFunction: AbiFunction | null = null;

  @observable
  public weiValue: bigint | null = null;

  @observable
  public transactionHash: Hex | null = null;

  @observable
  public rawResult: unknown | null = null;

  public formRef = React.createRef<ValidatedFormRef>();

  @observable
  public rpcUrl: string | null = null;

  constructor(
    private readonly session: SessionStore,
    private readonly organizationStore: OrganizationStore,
    private readonly notification: NotificationService,
    private readonly blockchainEndpointApi: BlockchainEndpointApi
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = async () => {
    if (this.organizationStore.currentOrganization?.id) {
      const result = await this.blockchainEndpointApi.getOrCreate(
        this.organizationStore.currentOrganization.id,
        this.props.blockchain.chainId
      );

      if (result.ok) {
        runInAction(() => {
          this.rpcUrl = result.data.url;
        });
      }
    }
  };

  @computed
  public get hasMoreOptions() {
    return this.canTransferValue;
  }

  @computed
  public get canTransferValue() {
    return this.selectedFunction?.stateMutability === 'payable';
  }

  @action
  public setWeiFromCoinValue = (value: number | undefined) => {
    if (value == null) {
      this.weiValue = null;
    } else {
      this.weiValue = BigInt(ethers.parseUnits(value.toString(), this.props.blockchain.coinDecimals).toString());
    }
  };

  @action
  public setFunction = (value: string) => {
    this.selectedFunction = this.functions.find((i) => i.name === value) ?? null;
    this.transactionHash = null;
    this.rawResult = null;
    this.weiValue = null;
  };

  @computed
  public get isReadFunction() {
    return this.selectedFunction?.stateMutability === 'pure' || this.selectedFunction?.stateMutability === 'view';
  }

  @computed
  public get functions(): AbiFunction[] {
    return this.props.abi.filter((i) => i.type === 'function') as AbiFunction[];
  }

  @computed
  public get functionParams(): CallParam[] | null {
    if (!this.selectedFunction) {
      return null;
    }

    return this.selectedFunction.inputs.map((i) => new CallParam(i.name ?? '', i.type)) ?? [];
  }

  @computed
  public get accessToken() {
    if (this.props.accessTokens.length) {
      return this.props.accessTokens[0].accessToken;
    }

    return '';
  }

  public readFunction = new AsyncAction(async () => {
    if (!this.props.smartContract.deployment) {
      return this.notification.warn('Deployment missing', 'You cannot call smart contract before deploying it');
    }

    if (!this.selectedFunction) {
      return this.notification.warn('Function missing', 'You cannot call smart contract before choosing a function');
    }

    // reset previous result
    this.transactionHash = null;
    this.rawResult = null;

    try {
      const currentParams = this.getCurrentCallParams();
      const smartContract = await this.organizationStore.sdk.smartContract.get({
        id: this.props.smartContract.id,
      });

      const result = await smartContract.read(this.selectedFunction.name, {
        params: currentParams,
        value: this.weiValue ?? undefined,
      });

      runInAction(() => {
        this.rawResult = result;
      });
    } catch (e) {
      console.error('exception while calling read function', e);

      if (e instanceof KriptonioError) {
        return this.notification.error('Error', formatKriptonioError(e));
      }

      this.notification.error('Exception', 'Exception while calling read function');
    }
  });

  public writeFunction = new AsyncAction(async (walletProvider: Eip1193Provider | undefined) => {
    if (!this.props.smartContract.deployment) {
      return this.notification.warn('Deployment missing', 'You cannot call smart contract before deploying it');
    }

    if (!this.selectedFunction) {
      return this.notification.warn('Function missing', 'You cannot call smart contract before choosing a function');
    }

    if (!walletProvider) {
      return this.notification.warn('Wallet missing', 'You cannot call smart contract without wallet');
    }

    // reset previous result
    this.transactionHash = null;
    this.rawResult = null;

    try {
      const currentParams = this.getCurrentCallParams();
      const smartContract = await this.organizationStore.sdk.smartContract.get({
        id: this.props.smartContract.id,
      });

      const chain = getChain(this.props.blockchain.chainId);
      const wallet = createWalletClient({
        chain,
        transport: custom(walletProvider),
      });
      const addresses = await wallet.getAddresses();

      const tx = await wallet.writeContract({
        address: this.props.smartContract.deployment.address as Hex,
        functionName: this.selectedFunction.name,
        abi: smartContract.abi,
        args: currentParams,
        account: addresses[0],
        value: this.weiValue ?? BigInt(0),
      });

      runInAction(() => {
        this.transactionHash = tx;
      });
    } catch (e) {
      console.error('exception while calling write function', e);

      if (e instanceof BaseError) {
        return this.notification.error('Error', e.shortMessage);
      }

      this.notification.error('Exception', 'Exception while calling write function');
    }
  });

  private getCurrentCallParams = () => {
    return this.functionParams?.map((p) => p.value) ?? [];
  };
}
