import { MenuItem, useDisclosure } from '@chakra-ui/react';
import { PaymasterEndpointDetailResponse } from '@web/dto/paymaster/paymasterEndpointDetailResponse';
import { generatePath, useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { PaymasterEndpointApi } from '../../../../domain/api/PaymasterEndpointApi';
import { useCurrentSlug } from '../../../../domain/hook/useCurrentSlug';
import { useInstance } from '../../../../domain/hook/useInstance';
import { NotificationService } from '../../../../domain/service/NotificationService';
import { AppRoutes } from '../../../../router/Routes';
import { DeleteConfirmation } from '../../../blockchain/components/DeleteConfirmation';

interface IProps {
  readonly paymasterEndpoint: PaymasterEndpointDetailResponse;
}

export function DeletePaymasterOptionsMenuItem(props: IProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const paymasterEndpointApi = useInstance(PaymasterEndpointApi);
  const notification = useInstance(NotificationService);
  const slug = useCurrentSlug();
  const navigate = useNavigate();

  const [state, runDelete] = useAsyncFn(async () => {
    try {
      const result = await paymasterEndpointApi.delete(props.paymasterEndpoint.id);
      if (result.ok) {
        notification.success('Success', 'Paymaster removed');
        return navigate(generatePath(AppRoutes.paymaster.list, { slug }));
      }

      notification.error('Error', `Error while deleting paymaster. ${result.error.stringify()}`);
    } catch (e) {
      console.error('exception while deleting paymaster', e);
      notification.error('Exception', 'Exception while deleting paymaster');
    }
  }, [props.paymasterEndpoint, paymasterEndpointApi]);

  return (
    <>
      <MenuItem color="red.500" onClick={onOpen}>
        Delete Paymaster
      </MenuItem>
      <DeleteConfirmation
        confirmationText={props.paymasterEndpoint.title}
        description="After deleting endpoint you won't be able to access it anymore."
        isOpen={isOpen}
        isLoading={state.loading}
        onCancel={onClose}
        onConfirm={runDelete}
        title="Are you sure you want to delete this paymaster?"
      />
    </>
  );
}
