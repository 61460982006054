import { FormLabel, Stack } from '@chakra-ui/react';
import type { BlockchainResponse } from '@web/dto/api/blockchainResponse';
import { CSelect, ValidatedFormControl } from '@web/toolkit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

interface IProps {
  readonly chainId?: number;
  readonly blockchains: BlockchainResponse[];
  readonly onBlockchainChange: (blockchainId: string | null) => void;
  readonly horizontal?: boolean;
}

export function BlockchainNetworkSelect(props: IProps) {
  const blockchains = useMemo(() => {
    return props.blockchains.reduce((acc, curr) => {
      if (acc.find((b) => b.blockchain === curr.blockchain)) {
        return acc;
      }

      return [...acc, { blockchain: curr.blockchain }];
    }, new Array<{ blockchain: string }>());
  }, [props.blockchains]);

  const preSelected = useMemo(() => {
    if (!props.chainId) {
      return undefined;
    }

    return props.blockchains.find((b) => b.chainId === props.chainId);
  }, [props.blockchains, props.chainId]);

  const [chain, setChain] = useState<string | undefined>(preSelected?.blockchain);
  const [network, setNetwork] = useState<string | undefined>(preSelected?.network);

  const networks = useMemo(() => {
    if (!chain) {
      return [];
    }

    const networks: { network: string }[] = [];

    props.blockchains
      .filter((b) => b.blockchain === chain)
      .forEach((b) => {
        if (!networks.some((n) => n.network === b.network)) {
          networks.push({ network: b.network });
        }
      });

    const result = Array.from(networks);

    if (result.length === 1) {
      setNetwork(result[0].network);
    }

    return result;
  }, [props.blockchains, chain]);

  useEffect(() => {
    if (!chain || !network) {
      return props.onBlockchainChange(null);
    }

    const blockchain = props.blockchains.find((b) => b.blockchain === chain && b.network === network);

    if (blockchain) {
      props.onBlockchainChange(blockchain.id);
    } else {
      console.warn(`blockchain not found. ${chain} ${network}`);
    }
  }, [props, chain, network]);

  const selectChain = useCallback((value: string) => {
    setChain(value);
    setNetwork(undefined);
  }, []);

  const selectNetwork = useCallback((value: string) => {
    setNetwork(value);
  }, []);

  return (
    <Stack w="100%" flexDir={props.horizontal ? 'row' : 'column'}>
      <ValidatedFormControl isRequired schema={yup.string().required('Please select blockchain')} value={chain}>
        <FormLabel>Blockchain</FormLabel>
        <CSelect onValueChange={selectChain} placeholder="Select Blockchain" value={chain}>
          {blockchains.map((blockchain) => (
            <option key={`blockchain-${blockchain.blockchain}`} value={blockchain.blockchain}>
              {blockchain.blockchain}
            </option>
          ))}
        </CSelect>
      </ValidatedFormControl>
      <ValidatedFormControl
        isDisabled={networks.length === 0}
        isRequired
        schema={yup.string().required('Please select network')}
        value={network}
      >
        <FormLabel>Network</FormLabel>
        <CSelect onValueChange={selectNetwork} placeholder="Select Network" value={network}>
          {networks.map((n) => (
            <option key={`blockchain-network-${n.network}`} value={n.network}>
              {n.network}
            </option>
          ))}
        </CSelect>
      </ValidatedFormControl>
    </Stack>
  );
}
