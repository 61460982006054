import { Box } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { useTitle } from 'react-use';
import { EndpointLogs } from '../../components/endpoint-logs/EndpointLogs';
import { BlockchainEndpointDetailRouteVmContext } from '../detail/BlockchainEndpointDetailRoute';

export const BlockchainEndpointLogsRoute = observer(function BlockchainEndpointLogsRoute() {
  useTitle('Blockchain Endpoint Logs | kriptonio');
  const context = useContext(BlockchainEndpointDetailRouteVmContext);

  return (
    <Box h="100%" w="100%">
      {context.blockchainEndpoint ? (
        <EndpointLogs title="Logs" blockchainEndpoint={context.blockchainEndpoint} />
      ) : null}
    </Box>
  );
});
