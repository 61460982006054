import { Button, Center, Heading, Text, VStack } from '@chakra-ui/react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generatePath, Link, useSearchParams } from 'react-router-dom';
import { useCurrentSlug } from '../../../domain/hook/useCurrentSlug';
import { AppRoutes } from '../../../router/Routes';

export function SubscriptionChangeSuccessRoute() {
  const currentSlug = useCurrentSlug();
  const [searchParams] = useSearchParams();
  const freeDowngraded = searchParams.get('free_downgraded') === 'true';

  return (
    <Center pt="10%">
      <VStack maxW="container.sm" align="center" spacing={6}>
        <>
          <VStack spacing={4}>
            <Center w={50} h={50} borderRadius="50%" bgColor="green.400" p={10}>
              <FontAwesomeIcon icon={faCheck} color="white" size="3x" />
            </Center>
            <Heading>Success</Heading>
          </VStack>
          <Text textAlign="center" fontSize="lg">
            Your subscription has been successfully changed.
            <Text mt={2}>
              {freeDowngraded
                ? 'At the end of current month subscription plan will be automatically cancelled. Until then you can continue using paid plan.'
                : 'Enjoy your new subscription plan!'}
            </Text>
          </Text>
          <Button
            as={Link}
            to={generatePath(AppRoutes.dashboard, {
              slug: currentSlug,
            })}
            colorScheme="blue"
          >
            Continue
          </Button>
        </>
      </VStack>
    </Center>
  );
}
