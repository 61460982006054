import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react';

import { singleton } from '../../inversify/decorator';

export const { ToastContainer, toast } = createStandaloneToast();

@singleton()
export class NotificationService {
  public success = (title: string, description: string, opts: UseToastOptions = {}) => {
    toast({
      title,
      description,
      status: 'success',
      duration: 4000,
      isClosable: true,
      position: 'top-right',
      ...opts,
    });
  };

  public info = (title: string, description: string, opts: UseToastOptions = {}) => {
    toast({
      title,
      description,
      status: 'info',
      duration: 4000,
      isClosable: true,
      position: 'top-right',
      ...opts,
    });
  };

  public warn = (title: string, description: string, opts: UseToastOptions = {}) => {
    toast({
      title,
      description,
      status: 'warning',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
      ...opts,
    });
  };

  public error = (title: string, description: string, opts: UseToastOptions = {}) => {
    toast({
      title,
      description,
      status: 'error',
      duration: 7000,
      isClosable: true,
      position: 'top-right',
      ...opts,
    });
  };
}
