import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { PaymasterEndpointDetailResponse } from '@web/dto/paymaster/paymasterEndpointDetailResponse';
import { observer } from 'mobx-react-lite';
import { DeletePaymasterOptionsMenuItem } from './DeletePaymasterOptionsMenuItem';

interface PaymasterOptionsMenuProps {
  paymasterEndpoint: PaymasterEndpointDetailResponse;
}

export const PaymasterOptionsMenu = observer(function PaymasterOptionsMenu(props: PaymasterOptionsMenuProps) {
  return (
    <Menu>
      <MenuButton pl={8} py={3} w="100%" textAlign="left" as={Button} variant="outline" rightIcon={<ChevronDownIcon />}>
        Options
      </MenuButton>
      <MenuList>
        <DeletePaymasterOptionsMenuItem paymasterEndpoint={props.paymasterEndpoint} />
      </MenuList>
    </Menu>
  );
});
