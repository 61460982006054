import { Box, Button, Flex, FormLabel, Heading, Stack, useColorModeValue } from '@chakra-ui/react';
import { CInput, ValidatedForm, ValidatedFormControl } from '@web/toolkit';
import { observer } from 'mobx-react-lite';
import { useTitle } from 'react-use';
import * as yup from 'yup';
import authBackground from '../../../assets/auth-background-gradient-blur.jpeg';
import { useVm } from '../../../domain/hook/useVm';
import { ResetPasswordRouteVm } from './ResetPasswordRouteVm';

export const ResetPasswordRoute = observer(function ForgotPasswordRoute() {
  useTitle('Reset Password | kriptonio');
  const vm = useVm(ResetPasswordRouteVm);

  return (
    <Flex align="center" bgImage={`url(${authBackground})`} bgSize="cover" height="100vh" w="100%">
      <Box maxW="lg" minW={{ md: '450px' }} mx="auto" px={6}>
        <Box bg={useColorModeValue('white', 'gray.700')} boxShadow="lg" p={8} rounded="lg">
          <Heading fontSize="3xl" mb={3} textAlign="center">
            Set your new password
          </Heading>
          <ValidatedForm onSubmit={vm.resetPassword.run}>
            <Stack spacing={4}>
              <ValidatedFormControl
                schema={yup
                  .string()
                  .min(8, 'Password must be at least 8 characters long')
                  .test('password-strength', vm.validatePasswordStrength)
                  .required('Please provide email')}
                value={vm.form.password}
              >
                <FormLabel>New Password</FormLabel>
                <CInput autoFocus onValueChange={vm.onPasswordChange} type="password" value={vm.form.password} />
              </ValidatedFormControl>
              <Button colorScheme="blue" isLoading={vm.resetPassword.isBusy} type="submit">
                Reset password
              </Button>
            </Stack>
          </ValidatedForm>
        </Box>
      </Box>
    </Flex>
  );
});
