import { computed, makeObservable, observable, runInAction } from 'mobx';
import { ViewModel } from '../../domain/ViewModel';
import { BlockchainStore } from '../../domain/store/BlockchainStore';
import { OrganizationStore } from '../../domain/store/OrganizationStore';
import { SessionStore } from '../../domain/store/SessionStore';
import { transient } from '../../inversify/decorator';

@transient()
export class AuthenticatedRouteVm extends ViewModel {
  @observable
  public loading = true;

  constructor(
    public readonly session: SessionStore,
    private readonly blockchainStore: BlockchainStore,
    private readonly organizationStore: OrganizationStore
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get authenticated() {
    return this.session.authenticated;
  }

  public override onInit = async () => {
    try {
      const result = await this.session.restore();
      if (result.ok) {
        return await Promise.allSettled([this.blockchainStore.load(), this.organizationStore.load()]);
      }
    } catch (e) {
      console.error('error while restoring session', e);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
