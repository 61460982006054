import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, Box, Heading, Link, Text, Tooltip, VStack } from '@chakra-ui/react';
import { TransactionStatus } from '@web/dto/api/transactionStatus';
import { ValidatedForm } from '@web/toolkit';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { Hex } from 'viem';
import { useVm } from '../../../../domain/hook/useVm';
import { CtaButton } from '../../../components/CtaButton';
import { CallParams } from '../../smart-contract/components/CallParams';
import { HexAddress } from '../HexAddress';
import { WalletButton } from '../WalletButton';
import { TransactionAwait } from '../transaction-await/TransactionAwait';
import { SmartContractDeploymentProps, SmartContractDeploymentVm } from './SmartContractDeploymentVm';
import { DeleteSmartContractDeployment } from './components/DeleteSmartContractDeployment';

export const SmartContractDeployment = observer(function SmartContractDeployment(props: SmartContractDeploymentProps) {
  const vm = useVm(SmartContractDeploymentVm, props);
  const { isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const addressUrl = `${props.blockchain.addressUrlPrefix}/${props.smartContract.deployment?.address}`;

  const transactionUrl = useMemo(() => {
    if (props.smartContract.deployment?.transaction) {
      return `${props.blockchain.transactionUrlPrefix}/${props.smartContract.deployment?.transaction.hash}`;
    } else {
      return 'unknown';
    }
  }, [props.blockchain.transactionUrlPrefix, props.smartContract.deployment]);

  const transactionStatus = useMemo(() => {
    if (props.smartContract.deployment?.transaction) {
      return props.smartContract.deployment.transaction.status;
    }
  }, [props.smartContract.deployment]);

  const deploy = useCallback(() => {
    return vm.deploySmartContract.run(walletProvider);
  }, [walletProvider, vm]);

  // transaction has status
  if (props.smartContract.deployment && transactionStatus !== TransactionStatus.Pending) {
    return (
      <VStack alignItems="flex-start">
        <Alert marginBottom={2} status={transactionStatus === TransactionStatus.Success ? 'success' : 'error'}>
          <AlertIcon />
          {transactionStatus === TransactionStatus.Success
            ? 'Your smart contract is deployed'
            : 'Deployment transaction failed'}
        </Alert>
        <Box marginBottom={2}>
          <Text fontSize="md" fontWeight="semibold">
            Deployer
          </Text>
          <HexAddress fontSize="sm" address={props.smartContract.deployment.deployer} />
        </Box>
        <Box marginBottom={2}>
          <Text fontSize="md" fontWeight="semibold">
            Contract Url
          </Text>
          <Link fontSize="sm" href={addressUrl} isExternal wordBreak="break-word">
            {addressUrl} <ExternalLinkIcon mx="2px" />
          </Link>
        </Box>
        <Box marginBottom={2}>
          <Text fontSize="md" fontWeight="semibold">
            Transaction Url
          </Text>
          <Link fontSize="sm" href={transactionUrl} isExternal wordBreak="break-word">
            {transactionUrl} <ExternalLinkIcon mx="2px" />
          </Link>
        </Box>
        {transactionStatus === TransactionStatus.Error && (
          <DeleteSmartContractDeployment isLoading={vm.deleteDeployment.isBusy} onClick={vm.deleteDeployment.run} />
        )}
      </VStack>
    );
  }

  return !props.smartContract.deployment ? (
    <VStack>
      <ValidatedForm onSubmit={deploy} ref={vm.form} style={{ width: '100%' }}>
        <VStack alignItems="flex-start" spacing={3}>
          {vm.constructorParams.length > 0 && (
            <Box w="100%">
              <Heading pb={4} size="md">
                Constructor Parameters
              </Heading>
              <CallParams params={vm.constructorParams} />
            </Box>
          )}
          <Tooltip label={!isConnected ? 'In order to deploy the smart contract, connect a wallet first' : undefined}>
            <Box w="100%">
              <CtaButton
                w="100%"
                type="submit"
                isDisabled={!isConnected}
                isLoading={vm.deploySmartContract.isBusy}
                loadingText={vm.loadingText}
                text="Deploy Smart Contract"
                upgrade={vm.needsUpgrade}
                upgradeText="Upgrade to deploy to mainnet"
              />
            </Box>
          </Tooltip>
          <WalletButton chainId={props.blockchain.chainId} />
        </VStack>
      </ValidatedForm>
    </VStack>
  ) : (
    <VStack spacing={3}>
      {props.blockchain &&
      vm.rpcUrl &&
      props.smartContract.deployment.transaction?.status !== TransactionStatus.Success ? (
        <TransactionAwait
          blockchain={props.blockchain}
          onTransactionStatus={vm.processDeploymentResult}
          hash={props.smartContract.deployment.transaction?.hash as Hex}
          rpcUrl={vm.rpcUrl}
        />
      ) : null}
    </VStack>
  );
});
