import type { CompileSmartContractTemplateBody } from '@web/dto/api/compileSmartContractTemplateBody';
import type { SmartContractTemplateResponse } from '@web/dto/api/smartContractTemplateResponse';
import type { TemplateCompileResponse } from '@web/dto/api/templateCompileResponse';
import { singleton } from '../../inversify/decorator';
import { AjaxService } from '../service/AjaxService';
import { Api } from './Api';

@singleton()
export class TemplateApi extends Api {
  constructor(private readonly ajax: AjaxService) {
    super();
  }

  public getTemplates = () => {
    return this.ajax.get<SmartContractTemplateResponse[]>(`v1/smart-contract-templates`);
  };

  public compileTemplate = (id: string, data: CompileSmartContractTemplateBody) => {
    return this.ajax.post<TemplateCompileResponse>(`v1/smart-contract-templates/${id}/compile`, { data });
  };
}
