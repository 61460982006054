import { Divider, Flex } from '@chakra-ui/layout';

interface IProps {
  readonly marginTop?: number;
}

export function SectionDivider(props: IProps) {
  return (
    <Flex direction="column" marginBottom={5} mt={props.marginTop ?? 2}>
      <Divider />
    </Flex>
  );
}
