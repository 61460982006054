import { Container, Flex, Heading, HStack, Link, Spinner, Text, VStack } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import { useGetBlockchainEndpoints } from '../../../../domain/api/BlockchainEndpointApi';
import { useCurrentSlug } from '../../../../domain/hook/useCurrentSlug';
import { useInstance } from '../../../../domain/hook/useInstance';
import { OrganizationStore } from '../../../../domain/store/OrganizationStore';
import { AppRoutes } from '../../../../router/Routes';
import { CtaButton } from '../../../components/CtaButton';
import { SegmentedControl } from '../../../components/SegmentedControl';
import { BlockchainEndpointListItem } from './BlockchainEndpointListItem';

export function BlockchainEndpointListRoute() {
  useTitle('Blockchain Endpoints | kriptonio');
  const slug = useCurrentSlug();
  const organizationStore = useInstance(OrganizationStore);
  const { data: allData, isValidating } = useGetBlockchainEndpoints(organizationStore.currentOrganization?.id ?? '');
  const navigate = useNavigate();
  const [showing, setShowing] = useState<'custom' | 'managed'>('custom');

  const toNewBlockchainEndpoint = useCallback(() => {
    navigate(
      generatePath(AppRoutes.blockchainEndpoint.new, {
        slug: slug,
      })
    );
  }, [navigate, slug]);

  const filteredData = useMemo(() => {
    return allData?.filter((endpoint) => endpoint.managed === (showing === 'managed'));
  }, [showing, allData]);

  return (
    <Container maxW="container.xl" pb={6}>
      <HStack justify="space-between" marginY={6}>
        <VStack flex={1} w="100%" align="flex-start">
          <Heading>RPC Blockchain Endpoints</Heading>
          <SegmentedControl
            buttons={[
              {
                text: 'My RPCs',
                active: showing === 'custom',
                onPress: () => setShowing('custom'),
              },
              {
                text: 'Wallet RPCs',
                active: showing === 'managed',
                onPress: () => setShowing('managed'),
              },
            ]}
          />
        </VStack>
        {showing === 'custom' && (
          <CtaButton
            onClick={toNewBlockchainEndpoint}
            text="New Blockchain Endpoint"
            isLoading={isValidating}
            upgradeText="Upgrade for more blockchain endpoints"
            upgrade={
              !isValidating &&
              (organizationStore.currentOrganization?.subscription?.subscriptionLimit.endpoints ?? 0) <=
                (filteredData?.length ?? 0)
            }
          />
        )}
      </HStack>
      <VStack spacing={4}>
        {isValidating ? (
          <Spinner />
        ) : !filteredData?.length ? (
          <Flex
            alignItems="center"
            borderRadius="lg"
            borderWidth={1}
            h={20}
            justifyContent="center"
            p={5}
            shadow="md"
            w="100%"
          >
            {showing === 'custom' ? (
              <Text textAlign="center">
                Currently you don't have any blockchain endpoints. Click{' '}
                <Link onClick={toNewBlockchainEndpoint}>here</Link> to create first one.
              </Text>
            ) : (
              <Text textAlign="center">
                Currently you don't have any blockchain endpoints. Wallet endpoints will be shown here after you
                interact with the wallets.
              </Text>
            )}
          </Flex>
        ) : (
          filteredData.map((endpoint) => (
            <BlockchainEndpointListItem blockchainEndpoint={endpoint} key={`blockchain-endpoint-${endpoint.id}`} />
          ))
        )}
      </VStack>
    </Container>
  );
}
