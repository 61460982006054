import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import { useVm } from '../../../../../domain/hook/useVm';
import { DisableTwoFactorModalVm, IDisableTwoFactorModalProps } from './DisableTwoFactorModalVm';

export const DisableTwoFactorModal = observer(function CreateTokenModal(props: IDisableTwoFactorModalProps) {
  const vm = useVm(DisableTwoFactorModalVm, props);

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Disabling Two Factor Authentication</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <Text paddingTop={4} textAlign="center">
              Enter generated 2FA code
            </Text>
            <HStack>
              {vm.disableTwoFactor.isBusy ? (
                <Flex justify="center" w="100%">
                  <Spinner />
                </Flex>
              ) : (
                <PinInput onComplete={vm.disableTwoFactor.run} otp type="number">
                  <PinInputField autoFocus />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              )}
            </HStack>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <HStack spacing={2}>
            <Button onClick={props.onClose}>Cancel</Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
