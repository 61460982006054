import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Button, HStack, IconButton, Input, Select, Text, Tooltip } from '@chakra-ui/react';
import { useCallback, useState } from 'react';

interface IProps {
  readonly pageCount: number;
  readonly page: number;
  readonly pageSize: number;
  readonly onCurrentPageChange: (page: number) => void;
  readonly onPageSizeChange: (pageSize: number) => void;
}

export function Pagination(props: IProps) {
  const [goToPage, setGoToPage] = useState('');

  const prevPage = useCallback(() => {
    if (props.page - 1 < 1) {
      return;
    }

    return props.onCurrentPageChange(props.page - 1);
  }, [props]);

  const nextPage = useCallback(() => {
    if (props.page + 1 > props.pageCount) {
      return;
    }

    return props.onCurrentPageChange(props.page + 1);
  }, [props]);

  return (
    <HStack justify="space-between">
      <HStack>
        <Tooltip label="Previous">
          <IconButton
            aria-label="previous page"
            isDisabled={props.page < 2}
            icon={<ArrowBackIcon />}
            onClick={prevPage}
          />
        </Tooltip>
        {props.pageCount > 6 ? (
          <>
            {/* first page */}
            {props.page - 1 > 0 && <Button onClick={() => props.onCurrentPageChange(1)}>1</Button>}
            {props.page - 3 > 0 && <Text>...</Text>}
            {props.page > 2 && (
              <Button onClick={() => props.onCurrentPageChange(props.page - 1)}>{props.page - 1}</Button>
            )}

            {/* current page */}
            <Button isDisabled onClick={() => props.onCurrentPageChange(props.page)}>
              {props.page}
            </Button>

            {props.page < props.pageCount && (
              <Button onClick={() => props.onCurrentPageChange(props.page + 1)}>{props.page + 1}</Button>
            )}
            {props.page + 2 < props.pageCount && <Text>...</Text>}
            {/* last page */}
            {props.page + 1 < props.pageCount && (
              <Button onClick={() => props.onCurrentPageChange(props.pageCount)}>{props.pageCount}</Button>
            )}
          </>
        ) : (
          Array.from(Array(props.pageCount)).map((_, page) => (
            // eslint-disable-next-line react/no-array-index-key
            <Button
              isDisabled={props.page === page + 1}
              key={`pagination-page-${page}`}
              onClick={() => props.onCurrentPageChange(page + 1)}
            >
              {page + 1}
            </Button>
          ))
        )}

        <Tooltip label="Next">
          <IconButton
            aria-label="next page"
            isDisabled={props.page >= props.pageCount}
            icon={<ArrowForwardIcon />}
            onClick={nextPage}
          />
        </Tooltip>
        <Select onChange={(e) => props.onPageSizeChange(parseInt(e.target.value))} value={props.pageSize}>
          <option value={20}>20 Per Page</option>
          <option value={50}>50 Per Page</option>
          <option value={100}>100 Per Page</option>
        </Select>
      </HStack>
      <HStack>
        <Text>Jump to page</Text>
        <Input maxW={14} onChange={(e) => setGoToPage(e.target.value)} textAlign="center" value={goToPage} />
        <Button
          colorScheme="blue"
          isDisabled={!goToPage || props.pageCount < parseInt(goToPage)}
          onClick={() => goToPage != null && props.onCurrentPageChange(parseInt(goToPage))}
        >
          Go
        </Button>
      </HStack>
    </HStack>
  );
}
