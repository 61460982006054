export enum SolcErrorCode {
  CompileError = 9000,
  MetadataParsingError = 9001,
  MissingCompilationTarget = 9002,
  UnknownContract = 9003,
  UnsupportedPublicLibraries = 9004,
  CompilationResultEmpty = 9005,
  Unknown = 9006,
  CannotResolveImport = 9007,
  ValidationError = 9008,
  InputDataInvalid = 9009,
}
