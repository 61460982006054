import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../router/Routes';

export function WalletConnectRoute() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(AppRoutes.index, { replace: true });
  }, [navigate]);

  return null;
}
