import type { ContractsResponse } from '@web/dto/api/contractsResponse';
import { singleton } from '../../inversify/decorator';
import { AjaxService, IHttpResponse } from '../service/AjaxService';
import { Api } from './Api';

@singleton()
export class SolcApi extends Api {
  constructor(private readonly ajax: AjaxService) {
    super();
  }

  public getContracts = (data: FormData): Promise<IHttpResponse<ContractsResponse>> => {
    return this.ajax.post('v1/solc/contracts', { data });
  };
}
