import type { CreateSmartContractResponse } from '@web/dto/api/createSmartContractResponse';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { generatePath } from 'react-router-dom';
import { ViewModel } from '../../../../../../domain/ViewModel';
import { SmartContractApi } from '../../../../../../domain/api/SmartContractApi';
import { SolcApi } from '../../../../../../domain/api/SolcApi';
import { AsyncAction } from '../../../../../../domain/async/AsyncAction';
import type { ApiErrorModel } from '../../../../../../domain/model/ApiErrorModel';
import { NotificationService } from '../../../../../../domain/service/NotificationService';
import { AnalyticsEvent } from '../../../../../../domain/service/analytics/AnalyticsEvent';
import { AnalyticsService } from '../../../../../../domain/service/analytics/AnalyticsService';
import { BlockchainStore } from '../../../../../../domain/store/BlockchainStore';
import { OrganizationStore } from '../../../../../../domain/store/OrganizationStore';
import { transient } from '../../../../../../inversify/decorator';
import { AppRoutes } from '../../../../../../router/Routes';
import { SolcErrorCode } from '../../../../../../typings/SolcErrorCode';

@transient()
export class SmartContractViaUploadVm extends ViewModel {
  @observable
  public title = '';

  @observable
  public selectedFile: File | null = null;

  @observable
  public smartContracts: string[] = [];

  @observable
  public selectedSmartContract: string | undefined = undefined;

  @observable
  public compileError: ApiErrorModel | null = null;

  @observable
  public processingSmartContract = false;

  @observable
  public blockchainId: string | null = null;

  @observable
  public walletId: string | null = null;

  constructor(
    private readonly smartContractApi: SmartContractApi,
    private readonly notificationService: NotificationService,
    private readonly organizationStore: OrganizationStore,
    private readonly analytics: AnalyticsService,
    private readonly notification: NotificationService,
    private readonly solcApi: SolcApi,
    private readonly blockchainStore: BlockchainStore
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get blockchains() {
    return this.blockchainStore.blockchains.filter((b) => b.features.includes('SmartContract'));
  }

  @action
  public setTitle = (value: string) => {
    this.title = value;
  };

  @action
  public setSelectedContract = (value: string) => {
    this.selectedSmartContract = value;
  };

  @action
  public setBlockchainId = (value: string | null) => {
    this.blockchainId = value;
  };

  @action
  public setWalletId = (value: string) => {
    this.walletId = value;
  };

  @action
  public setFile = (file: File | null) => {
    this.resetContract();
    this.selectedFile = file;

    if (this.selectedFile) {
      void this.readContract(this.selectedFile);
    }
  };

  @action
  private resetContract = () => {
    this.smartContracts = [];
    this.selectedSmartContract = undefined;
    this.compileError = null;
  };

  @action
  private readContract = async (file: File) => {
    try {
      this.processingSmartContract = true;
      const form = new FormData();
      form.append('file', file);

      const result = await this.solcApi.getContracts(form);
      if (!result.ok) {
        return runInAction(() => {
          this.compileError = result.error;
          if (this.compileError.rootCause?.code !== SolcErrorCode.CompileError) {
            return this.notification.error('Error', result.error.stringify());
          }
        });
      }

      return runInAction(() => {
        this.smartContracts = result.data.contracts ?? [];

        if (this.smartContracts.length === 1) {
          this.selectedSmartContract = this.smartContracts[0];
        }
      });
    } finally {
      runInAction(() => {
        this.processingSmartContract = false;
      });
    }
  };

  @computed
  public get blockchain() {
    if (!this.blockchainId) {
      return null;
    }

    return this.blockchains.find((b) => b.id === this.blockchainId);
  }

  public showDetails = (contract: CreateSmartContractResponse) => {
    this.navigate(
      generatePath(AppRoutes.smartContract.general, { id: contract.id, slug: this.organizationStore.currentSlug })
    );
  };

  public createSmartContract = new AsyncAction(async () => {
    try {
      const form = new FormData();
      form.append('title', this.title);
      form.append('walletId', this.walletId ?? '');

      if (this.blockchainId) {
        form.append('blockchainId', this.blockchainId);
      }

      if (this.selectedFile) {
        form.append('contractFile', this.selectedFile);
      }

      if (this.selectedSmartContract) {
        form.append('contract', this.selectedSmartContract);
      }

      const result = await this.smartContractApi.createSmartContractFromFile(
        this.organizationStore.currentOrganization?.id ?? '',
        form
      );

      if (result.ok) {
        this.analytics.track(AnalyticsEvent.SmartContractCreated);
        return this.showDetails(result.data);
      }

      this.notificationService.warn('Error while creating smart contract', result.error.stringify());
    } catch (e) {
      console.error(e);
      this.notificationService.error(
        'Unexpected Error',
        'Unexpected error while creating smart contract. Please check provided data'
      );
    }
  });
}
