import { action, makeObservable, observable } from 'mobx';
import { ViewModel } from '../../../../domain/ViewModel';
import { transient } from '../../../../inversify/decorator';

export type CreationMethod = 'template' | 'upload' | 'plugin';

@transient()
export class NewSmartContractRouteVm extends ViewModel {
  @observable
  public creationMethod: CreationMethod | null = null;

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  public setCreationMethod = (method: CreationMethod) => {
    this.creationMethod = method;
  };
}
