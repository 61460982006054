import { MenuItem, useDisclosure } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useVm } from '../../../../../../domain/hook/useVm';
import { DeleteConfirmation } from '../../../../components/DeleteConfirmation';
import { SmartContractDeleteMenuItemProps, SmartContractDeleteMenuItemVm } from './SmartContractDeleteMenuItemVm';

export const SmartContractDeleteMenuItem = observer(function SmartContractDeleteMenuItem(
  props: SmartContractDeleteMenuItemProps
) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const vm = useVm(SmartContractDeleteMenuItemVm, props);

  const deleteSmartContract = useCallback(() => {
    void vm.delete.run().then(onClose);
  }, [vm, onClose]);

  return (
    <>
      <MenuItem color="red.500" onClick={onOpen}>
        Delete
      </MenuItem>
      <DeleteConfirmation
        confirmationText={props.smartContract.title}
        description="After deleting smart contract you will not be able to recover smart contract or smart contract data."
        isOpen={isOpen}
        onCancel={onClose}
        onConfirm={deleteSmartContract}
        isLoading={vm.delete.isBusy}
        title="Are you sure you want to delete this smart contract?"
      />
    </>
  );
});
