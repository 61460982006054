import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import type { SmartContractDetailResponse } from '@web/dto/api/smartContractDetailResponse';
import { observer } from 'mobx-react-lite';
import { SmartContractCloneMenuItem } from './clone-button/SmartContractCloneMenuItem';
import { SmartContractDeleteMenuItem } from './delete-button/SmartContractDeleteMenuItem';

interface SmartContractOptionsMenuProps {
  smartContract: SmartContractDetailResponse;
}

export const SmartContractOptionsMenu = observer(function SmartContractOptionsMenu(
  props: SmartContractOptionsMenuProps
) {
  return (
    <Menu>
      <MenuButton pl={8} py={3} w="100%" textAlign="left" as={Button} variant="outline" rightIcon={<ChevronDownIcon />}>
        Options
      </MenuButton>
      <MenuList>
        <SmartContractCloneMenuItem smartContract={props.smartContract} />
        <SmartContractDeleteMenuItem smartContract={props.smartContract} />
      </MenuList>
    </Menu>
  );
});
