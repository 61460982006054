import { Badge, Tooltip, useClipboard } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useInstance } from '../../../domain/hook/useInstance';
import { NotificationService } from '../../../domain/service/NotificationService';

interface IProps {
  readonly text: string;
}

export function CopyableText(props: IProps) {
  const { onCopy, setValue } = useClipboard(props.text ?? 'empty');
  const notification = useInstance(NotificationService);

  useEffect(() => {
    setValue(props.text);
  }, [props.text, setValue]);

  const copy = useCallback(() => {
    onCopy();
    notification.success('Success', 'Copied to clipboard');
  }, [onCopy, notification]);

  return (
    <Tooltip label="Click to copy">
      <Badge
        cursor="pointer"
        fontSize="sm"
        onClick={copy}
        textTransform="none"
        whiteSpace="normal"
        wordBreak="break-word"
      >
        {props.text}
      </Badge>
    </Tooltip>
  );
}
