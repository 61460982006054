import { Alert, AlertIcon, CircularProgress, Link, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useVm } from '../../../../domain/hook/useVm';
import { ITransactionAwaitProps, TransactionAwaitVm } from './TransactionAwaitVm';

export const TransactionAwait = observer(function TransactionAwait(props: ITransactionAwaitProps) {
  const vm = useVm(TransactionAwaitVm, props);

  useEffect(() => {
    return () => {
      vm.unmounted = true;
    };
  }, [vm]);

  if (vm.success === true) {
    return (
      <Alert status="success">
        <AlertIcon />
        <Text marginLeft={2}>
          {vm.txUrl ? (
            <>
              Transaction successful. You can check transaction details{' '}
              <Link href={vm.txUrl} isExternal>
                here
              </Link>
              .
            </>
          ) : (
            <>Transaction successful.</>
          )}
        </Text>
      </Alert>
    );
  }

  if (vm.success === false) {
    return (
      <Alert status="error">
        <AlertIcon />
        <Text marginLeft={2}>
          {vm.txUrl ? (
            <>
              Transaction failed. You can check transaction details{' '}
              <Link href={vm.txUrl} isExternal>
                here
              </Link>
              .
            </>
          ) : (
            <>Transaction failed.</>
          )}
        </Text>
      </Alert>
    );
  }

  return (
    <Alert status="info">
      <CircularProgress color="green.300" isIndeterminate size={7} />
      <Text marginLeft={2}>
        {vm.txUrl ? (
          <>
            Waiting for{' '}
            <Link href={vm.txUrl} isExternal>
              transaction
            </Link>{' '}
            to be finished. Please be patient...
          </>
        ) : (
          <>Waiting for transaction data. Please be patient...</>
        )}
      </Text>
    </Alert>
  );
});
