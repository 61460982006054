import type { SetPaymasterPolicyRequest } from '@web/dto/paymaster/setPaymasterPolicyRequest';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { ViewModel } from '../../../domain/ViewModel';
import { PaymasterEndpointApi } from '../../../domain/api/PaymasterEndpointApi';
import { AsyncAction } from '../../../domain/async/AsyncAction';
import { NotificationService } from '../../../domain/service/NotificationService';
import { OrganizationStore } from '../../../domain/store/OrganizationStore';
import { transient } from '../../../inversify/decorator';

@transient()
export class PaymasterGlobalPolicyRouteVm extends ViewModel {
  @observable
  public form: SetPaymasterPolicyRequest = {};

  constructor(
    private readonly organizationStore: OrganizationStore,
    private readonly paymasterEndpointApi: PaymasterEndpointApi,
    private readonly notification: NotificationService
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = () => {
    void this.getSponsorshipPolic.run();
  };

  public getSponsorshipPolic = new AsyncAction(async () => {
    const result = await this.paymasterEndpointApi.getSponsorshipPolicy(
      this.organizationStore.currentOrganization?.id ?? ''
    );

    if (result.ok) {
      runInAction(() => {
        this.form.maxUsdPerMonth = result.data.maxUsdPerMonth;
      });
    }
  });

  @action
  public changeNoLimit = (noLimit: boolean) => {
    if (noLimit) {
      this.form.maxUsdPerMonth = undefined;
    } else {
      this.form.maxUsdPerMonth = 100;
    }
  };

  public setMaxUsdPerMonth = (value: number | undefined) => {
    this.form.maxUsdPerMonth = value;
  };

  public setPolicy = new AsyncAction(async () => {
    try {
      const result = await this.paymasterEndpointApi.setSponsorshipPolicy(
        this.organizationStore.currentOrganization?.id ?? '',
        this.form
      );

      if (result.ok) {
        return this.notification.success('Success', 'Policy updated');
      }

      this.notification.error('Error', result.error.stringify());
    } catch (e) {
      console.error('exception while updating sponsorship policy', e);
      this.notification.error('Exception', 'Exception while updating sponsorship policy');
    }
  });
}
