import type { BlockchainEndpointCallResponsePaged } from '@web/dto/rpc/blockchainEndpointCallResponsePaged';
import { DataPeriod } from '@web/dto/rpc/dataPeriod';
import { EndpointCallStatus } from '@web/dto/rpc/endpointCallStatus';
import type { GetBlockchainCallsBody } from '@web/dto/rpc/getBlockchainCallsBody';
import axios from 'axios';
import type { MultiValue } from 'chakra-react-select';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ViewModel } from '../../../../domain/ViewModel';
import { BlockchainEndpointApi } from '../../../../domain/api/BlockchainEndpointApi';
import { SmartContractApi } from '../../../../domain/api/SmartContractApi';
import { AsyncAction } from '../../../../domain/async/AsyncAction';
import { NotificationService } from '../../../../domain/service/NotificationService';
import { env } from '../../../../env';
import { transient } from '../../../../inversify/decorator';

export interface EndpointLogsProps {
  title?: string;
  blockchainEndpoint: { id: string; accessToken: string };
}

@transient()
export class EndpointLogsVm extends ViewModel<EndpointLogsProps> {
  @observable
  public currentPage = 1;

  @observable
  public currentPageSize = 20;

  @observable
  public data: BlockchainEndpointCallResponsePaged | null = null;

  @observable
  public filters: GetBlockchainCallsBody = {
    filterByMethod: [],
    filterByCallStatus: [],
    period: DataPeriod.TwentyFourHours,
    page: this.currentPage,
    pageSize: this.currentPageSize,
  };

  public callStatuses = [EndpointCallStatus.Success, EndpointCallStatus.Error];

  public rpcMethods: string[] = [];

  constructor(
    private readonly smartContractApi: SmartContractApi,
    private readonly blockchainEndpointApi: BlockchainEndpointApi,
    private readonly notification: NotificationService
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = () => {
    void this.loadRpcMethods();
  };

  private loadRpcMethods = async () => {
    try {
      const result = await axios.get<{ name: string }[]>(`${env.rpc}/v1/rpc-methods`);
      if (result.status === 200) {
        return runInAction(() => {
          this.rpcMethods = result.data.map((m) => m.name);
        });
      } else {
        console.error(`Error while loading rpc methods. code: ${result.status}`);
      }
    } catch (e) {
      console.error('exception while loading rpc methods', e);
    }
  };

  @action
  public setPeriod = (period: DataPeriod) => {
    this.filters.period = period;
    void this.getBlockchainCalls.run();
  };

  @action
  public setFilterByMethod = (filter: MultiValue<{ value: string }>) => {
    this.filters.filterByMethod = filter.map((f) => f.value);
    void this.getBlockchainCalls.run();
  };

  @action
  public setFilterByCallStatus = (filter: MultiValue<{ value: EndpointCallStatus }>) => {
    this.filters.filterByCallStatus = filter.map((f) => f.value);
    void this.getBlockchainCalls.run();
  };

  @computed
  public get pageCount() {
    return Math.ceil((this.data?.count ?? 0) / this.currentPageSize);
  }

  @action
  public handleCurrentPageChange = (page: number) => {
    if (page > this.pageCount) {
      return;
    }

    this.currentPage = page;
    void this.getBlockchainCalls.run();
  };

  @action
  public handleCurrentPageSizeChange = (size: number) => {
    this.currentPage = 1;
    this.currentPageSize = size;
    void this.getBlockchainCalls.run();
  };

  public getBlockchainCalls = new AsyncAction(async () => {
    try {
      const body: GetBlockchainCallsBody = {
        ...this.filters,
        page: this.currentPage,
        pageSize: this.currentPageSize,
      };

      const result = await this.blockchainEndpointApi.getBlockchainCalls(this.props.blockchainEndpoint.id, body);

      if (result?.ok) {
        return runInAction(() => {
          if (result?.ok) {
            this.data = result.data;
          }
        });
      }

      this.notification.error('Error', 'Error while loading endpoint logs');
    } catch (e) {
      console.error(e);
      this.notification.error('Exception', 'Exception while loading endpoint logs');
    }
  });
}
