import { injectable } from 'inversify';
import type { IHttpOkResponse, IHttpResponse } from '../service/AjaxService';

@injectable()
export abstract class Api {
  protected withData = <T>(apiResponse: IHttpResponse<unknown>, data: T): IHttpOkResponse<T> => {
    return {
      ok: true,
      data,
      httpStatusCode: apiResponse.httpStatusCode,
    };
  };
}
