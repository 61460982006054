import { Button, useDisclosure } from '@chakra-ui/react';
import { useAsyncFn } from 'react-use';
import { SubscriptionApi } from '../../../../domain/api/SubscriptionApi';
import { useInstance } from '../../../../domain/hook/useInstance';
import { NotificationService } from '../../../../domain/service/NotificationService';
import { OrganizationStore } from '../../../../domain/store/OrganizationStore';
import { DeleteConfirmation } from '../../../blockchain/components/DeleteConfirmation';

export function SubscriptionCancelButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const subscriptionApi = useInstance(SubscriptionApi);
  const organizationStore = useInstance(OrganizationStore);
  const notification = useInstance(NotificationService);

  const [state, cancel] = useAsyncFn(async () => {
    try {
      const result = await subscriptionApi.cancelOrganizationSubscription(
        organizationStore.currentOrganization?.id ?? ''
      );
      if (result.ok) {
        onClose();
        await organizationStore.load();
        return notification.success('Success', 'Subscription cancelled');
      }

      notification.error('Cancel failed', 'Error while cancelling subscription');
    } catch (e) {
      console.error(`exception while cancelling subscription. ${e}`);
      notification.error('Cancel failed', 'Exception while cancelling subscription');
    }
  }, [subscriptionApi, notification, organizationStore]);

  return (
    <>
      <Button onClick={onOpen} isLoading={state.loading} colorScheme="red" variant="outline">
        Cancel
      </Button>
      <DeleteConfirmation
        confirmationText="yes"
        description="After you cancel the subscription, you will be able to access features until the end of the current month."
        isOpen={isOpen}
        isLoading={state.loading}
        onCancel={onClose}
        onConfirm={cancel}
        title="Are you sure you want to cancel subscription?"
      />
    </>
  );
}
