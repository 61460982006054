import type { AccessTokenResponse } from '@web/dto/api/accessTokenResponse';
import type { CreateOrganizationBody } from '@web/dto/api/createOrganizationBody';
import type { InvitationDetailResponse } from '@web/dto/api/invitationDetailResponse';
import type { InviteUserBody } from '@web/dto/api/inviteUserBody';
import type { OrganizationDetailResponse } from '@web/dto/api/organizationDetailResponse';
import type { OrganizationResponse } from '@web/dto/api/organizationResponse';
import type { UpdateOrganizationBody } from '@web/dto/api/updateOrganizationBody';
import { singleton } from '../../inversify/decorator';
import { useSwr } from '../hook/useSwr';
import { AjaxService } from '../service/AjaxService';

@singleton()
export class OrganizationApi {
  constructor(private readonly ajax: AjaxService) {}

  public getOrganizations = () => {
    return this.ajax.get<OrganizationDetailResponse[]>('v1/organizations');
  };

  public createOrganization = (data: CreateOrganizationBody) => {
    return this.ajax.post<OrganizationDetailResponse>('v1/organizations', { data });
  };

  public updateOrganization = (id: string, data: UpdateOrganizationBody) => {
    return this.ajax.put<OrganizationDetailResponse>(`v1/organizations/${id}`, { data });
  };

  public removeUser = (organizationId: string, userId: string) => {
    return this.ajax.delete(`v1/organizations/${organizationId}/users/${userId}`);
  };

  public getAccessTokens = (organizationId: string) => {
    return this.ajax.get<AccessTokenResponse[]>(`v1/organizations/${organizationId}/access-tokens`);
  };

  public invite = (id: string, data: InviteUserBody) => {
    return this.ajax.post(`v1/organizations/${id}/invite`, { data });
  };

  public acceptInvitation = (invitationId: string) => {
    return this.ajax.post<OrganizationResponse>(`v1/organizations/invitations/${invitationId}/accept`);
  };

  public getInvitation = (invitationId: string) => {
    return this.ajax.get<InvitationDetailResponse>(`v1/organizations/invitations/${invitationId}`);
  };
}

export function useGetCustomerPortalUrl(organizationId: string) {
  return useSwr<string>(`v1/organizations/${organizationId}/customer-portal-url`);
}

export function useGetAccessTokens(organizationId: string) {
  return useSwr<AccessTokenResponse[]>(`v1/organizations/${organizationId}/access-tokens`);
}
