import { BrowserTracing } from '@sentry/browser';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import 'reflect-metadata';
import pkg from '../../../package.json';
import { App } from './App';
import { ToastContainer } from './domain/service/NotificationService';
import { env } from './env';
import './index.css';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: 'https://fcdf9f438b7e415bab5e7859ab1183d9@o1237754.ingest.sentry.io/6388062',
  enabled: env.isProd,
  integrations: [new BrowserTracing(), new CaptureConsoleIntegration({ levels: ['error'] }), new Sentry.Replay()],
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.5,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.5,
  environment: env.isProd ? 'production' : 'development',
  release: pkg.version,
});

const container = window.document.getElementById('root');
if (container) {
  const root = createRoot(container);
  const content = (
    <>
      <App />
      <ToastContainer />
    </>
  );

  root.render(content as never);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
