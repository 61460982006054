/**
 * Kriptonio Private API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: private
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InputType = 'String' | 'Number' | 'Boolean';

export const InputType = {
    String: 'String' as InputType,
    Number: 'Number' as InputType,
    Boolean: 'Boolean' as InputType
};

