const isProd = !!process.env.NX_IS_PRODUCTION;
const isDev = !isProd;

export const env = {
  isDev,
  isProd,
  rpc: isProd ? 'https://rpc.kriptonio.com' : 'http://127.0.0.1:5251',
  api: isProd ? 'https://api.kriptonio.com' : 'http://127.0.0.1:8000',
  paymaster: isProd ? 'https://paymaster.kriptonio.com' : 'http://127.0.0.1:5050',
  sessionTokenKey: 'kriptonio-session-token',
  googleTrackingId: 'G-8FZZ8H1EYT',
  googleTrackingParams: { app_name: 'app.kriptonio.com' },
  google: {
    clientId: '281351419939-nalumijhfeihte3369vbu5ehq968sqej.apps.googleusercontent.com',
  },
  walletConnect: {
    projectId: 'd8853705f2da837bb6fb536808b22a6f',
  },
};
