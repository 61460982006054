import { Box, Heading, HStack, SimpleGrid } from '@chakra-ui/react';
import { faExclamation, faServer } from '@fortawesome/free-solid-svg-icons';
import type { DataPeriod } from '@web/dto/rpc/dataPeriod';
import { useContext, useState } from 'react';
import { useTitle } from 'react-use';
import { useGetBlockchainEndpointCallStats } from '../../../../domain/api/ReportApi';
import { useInstance } from '../../../../domain/hook/useInstance';
import { OrganizationStore } from '../../../../domain/store/OrganizationStore';
import { dataPeriodToString } from '../../../../utils/dataPeriod';
import { BlockchainEndpointMethodUsageChart } from '../../../dashboard/components/BlockchainEndpointMethodUsageChart';
import { BlockchainEndpointUsageChart } from '../../../dashboard/components/BlockchainEndpointUsageChart';
import { PeriodSelector } from '../../../dashboard/components/PeriodSelector';
import { Stat } from '../../../dashboard/components/Stat';
import { SectionDivider } from '../../components/SectionDivider';
import { BlockchainEndpointDetailRouteVmContext } from '../detail/BlockchainEndpointDetailRoute';

export function BlockchainEndpointActivityRoute() {
  useTitle('Blockchain Endpoint Activity | kriptonio');

  const context = useContext(BlockchainEndpointDetailRouteVmContext);
  const [period, setPeriod] = useState<DataPeriod>('TwentyFourHours');
  const organizationStore = useInstance(OrganizationStore);

  const { data: callStats, isValidating } = useGetBlockchainEndpointCallStats(
    organizationStore.currentOrganization?.id ?? '',
    {
      period,
      blockchainEndpointId: context.blockchainEndpoint?.id,
    }
  );

  return (
    <Box pb={10} w="100%">
      <HStack justify="space-between">
        <Heading size="lg">Activity</Heading>
      </HStack>
      {context.blockchainEndpoint ? (
        <>
          <SectionDivider />
          <SimpleGrid mt={2} spacing={4} width="100%">
            <PeriodSelector onPeriodSelected={setPeriod} period={period} title="" />
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
              <Stat
                icon={faServer}
                loading={isValidating}
                number={callStats?.calls}
                title={`RPC Calls (${dataPeriodToString(period)})`}
              />
              <Stat
                icon={faExclamation}
                loading={isValidating}
                number={callStats?.errors}
                title={`RPC Errors (${dataPeriodToString(period)})`}
              />
            </SimpleGrid>
            <BlockchainEndpointMethodUsageChart blockchainEndpointId={context.blockchainEndpoint.id} period={period} />
            <BlockchainEndpointUsageChart blockchainEndpointId={context.blockchainEndpoint.id} period={period} />
          </SimpleGrid>
        </>
      ) : null}
    </Box>
  );
}
