import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import type { BlockchainEndpointResponse } from '@web/dto/rpc/blockchainEndpointResponse';
import { observer } from 'mobx-react-lite';
import { DeleteBlockchainEndpointMenuItem } from './DeleteBlockchainEndpointMenuItem';

interface BlockchainEndpointOptionsMenuProps {
  blockchainEndpoint: BlockchainEndpointResponse;
}

export const BlockchainEndpointOptionsMenu = observer(function BlockchainEndpointOptionsMenu(
  props: BlockchainEndpointOptionsMenuProps
) {
  return (
    <Menu>
      <MenuButton pl={8} py={3} w="100%" textAlign="left" as={Button} variant="outline" rightIcon={<ChevronDownIcon />}>
        Options
      </MenuButton>
      <MenuList>
        <DeleteBlockchainEndpointMenuItem blockchainEndpoint={props.blockchainEndpoint} />
      </MenuList>
    </Menu>
  );
});
