import { BlockchainResponse } from '@web/dto/api/blockchainResponse';
import type { SmartContractDetailResponse } from '@web/dto/api/smartContractDetailResponse';
import { ContractEventName, ethers } from 'ethers';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ViewModel } from '../../../../domain/ViewModel';
import { AsyncAction } from '../../../../domain/async/AsyncAction';
import { OrganizationStore } from '../../../../domain/store/OrganizationStore';
import { transient } from '../../../../inversify/decorator';
import { getEvents } from '../../../../utils/abi';
import { isNumeric } from '../../../../utils/number';

export interface SmartContractEventsProps {
  smartContract: SmartContractDetailResponse;
  blockchain: BlockchainResponse;
}

@transient()
export class SmartContractEventsVm extends ViewModel<SmartContractEventsProps> {
  @observable
  public latestBlock: number | null = null;

  @observable
  public filterByEvent: string | null = null;

  @observable
  public fromBlock: string | number = '';

  @observable
  public toBlock = 'latest';

  @observable
  public events: ethers.EventLog[] = [];

  private rpcUrl: string | null = null;

  constructor(private readonly organizationStore: OrganizationStore) {
    super();
    makeObservable(this);
  }

  public override onInit = () => {
    void this.fetchLatestBlock.run();
  };

  private getRpcUrl = async () => {
    if (this.rpcUrl) {
      return this.rpcUrl;
    }

    const rpc = await this.organizationStore.sdk.rpc.getOrCreate({
      chainId: this.props.blockchain.chainId,
    });
    this.rpcUrl = rpc.url;

    return this.rpcUrl;
  };

  public fetchLatestBlock = new AsyncAction(async () => {
    const rpcUrl = await this.getRpcUrl();
    const provider = new ethers.JsonRpcProvider(rpcUrl);

    const lastBlock = await provider.getBlock('latest');

    if (!lastBlock) {
      return console.warn('last block is null');
    }

    runInAction(() => {
      this.latestBlock = lastBlock.number;
      this.fromBlock = this.latestBlock - 500;
      void this.fetchEvents.run();
    });
  });

  @computed
  public get abiEvents() {
    return getEvents(this.props.smartContract.abi);
  }

  @action
  public setFilterByEvent = (value: string | null) => {
    this.filterByEvent = value;
    void this.fetchEvents.run();
  };

  @action
  public setFromBlock = (value: string) => {
    this.fromBlock = value;
  };

  @action
  public setToBlock = (value: string) => {
    this.toBlock = value;
  };

  public fetchEvents = new AsyncAction(async () => {
    if (!this.props.smartContract.deployment) {
      return [];
    }

    const rpcUrl = await this.getRpcUrl();
    const provider = new ethers.JsonRpcProvider(rpcUrl);

    const contract = new ethers.Contract(
      this.props.smartContract.deployment.address,
      this.props.smartContract.abi,
      provider
    );
    const filter: ContractEventName = this.filterByEvent ? [ethers.id(this.filterByEvent)] : [];

    let fromBlockParsed = this.fromBlock;
    if (typeof fromBlockParsed === 'string') {
      if (isNumeric(fromBlockParsed)) {
        fromBlockParsed = parseInt(fromBlockParsed, 10);
      }
    }

    let toBlockParsed: number | string | undefined = this.toBlock;
    if (typeof toBlockParsed === 'string') {
      if (isNumeric(toBlockParsed)) {
        toBlockParsed = parseInt(toBlockParsed);
      }
    }

    const events = await contract.queryFilter(filter, fromBlockParsed, toBlockParsed);
    runInAction(() => {
      this.events = events.reverse() as ethers.EventLog[];
    });
  });
}
