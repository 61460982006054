import { env } from '../../env';
import { singleton } from '../../inversify/decorator';

@singleton()
export class UrlService {
  public createBlockchainEndpointUrl = (accessToken: string) => {
    return `${env.rpc}/v1/endpoints/${accessToken}`;
  };

  public createPaymasterEndpointUrl = (accessToken: string) => {
    return `${env.paymaster}/v1/endpoints/${accessToken}/sponsor`;
  };
}
