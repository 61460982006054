import { BlockchainEndpointResponse } from '@web/dto/rpc/blockchainEndpointResponse';
import type { CreateBlockchainEndpointBody } from '@web/dto/rpc/createBlockchainEndpointBody';
import { action, computed, makeObservable, observable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { ViewModel } from '../../../../domain/ViewModel';
import { BlockchainEndpointApi } from '../../../../domain/api/BlockchainEndpointApi';
import { AsyncAction } from '../../../../domain/async/AsyncAction';
import { NotificationService } from '../../../../domain/service/NotificationService';
import { AnalyticsEvent } from '../../../../domain/service/analytics/AnalyticsEvent';
import { AnalyticsService } from '../../../../domain/service/analytics/AnalyticsService';
import { BlockchainStore } from '../../../../domain/store/BlockchainStore';
import { OrganizationStore } from '../../../../domain/store/OrganizationStore';
import { transient } from '../../../../inversify/decorator';
import { AppRoutes } from '../../../../router/Routes';

@transient()
export class NewBlockchainEndpointRouteVm extends ViewModel {
  @observable
  public form: CreateBlockchainEndpointBody = {
    title: '',
    chainId: 0,
  };

  constructor(
    private readonly blockchainEndpointApi: BlockchainEndpointApi,
    private readonly notificationService: NotificationService,
    private readonly blockchainStore: BlockchainStore,
    private readonly organizationStore: OrganizationStore,
    private readonly analytics: AnalyticsService
  ) {
    super();
    makeObservable(this);
  }

  @action
  public setTitle = (value: string) => {
    this.form.title = value;
  };

  @action
  public setBlockchain = (blockchainId: string | null) => {
    if (!blockchainId) {
      this.form.chainId = 0;
      return;
    }

    const blockchain = this.blockchains.find((b) => b.id === blockchainId);
    this.form.chainId = blockchain?.chainId ?? 0;
  };

  @computed
  public get blockchains() {
    return this.blockchainStore.blockchains.filter((b) => b.features.includes('RpcEndpoint'));
  }

  public createBlockchainEndpoint = new AsyncAction(async () => {
    try {
      const result = await this.blockchainEndpointApi.createBlockchainEndpoint(
        this.organizationStore.currentOrganization?.id ?? '',
        this.form
      );

      if (result.ok) {
        this.analytics.track(AnalyticsEvent.BlockchainEndpointCreated);
        return this.showDetails(result.data);
      }

      this.notificationService.warn('Error while creating blockchain endpoint', result.error.stringify());
    } catch (e) {
      console.error(e);
      this.notificationService.error(
        'Unexpected Error',
        'Unexpected error while creating blockchain endpoint. Please check provided data'
      );
    }
  });

  public showDetails = (endpoint: BlockchainEndpointResponse) => {
    this.navigate(
      generatePath(AppRoutes.blockchainEndpoint.general, { id: endpoint.id, slug: this.organizationStore.currentSlug })
    );
  };
}
