import { Table, TableContainer, Tbody, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import { BlockchainResponse } from '@web/dto/api/blockchainResponse';
import { SponsoredTransactionResponse } from '@web/dto/paymaster/sponsoredTransactionResponse';
import { SponsoredTransactionListItem } from './SponsoredTransactionListItem';

interface IProps {
  readonly transactions: SponsoredTransactionResponse[];
  readonly blockchain: BlockchainResponse;
}

export function LastSponsoredTransactions(props: IProps) {
  return (
    <VStack align="flex-start" borderRadius="lg" borderWidth={1}>
      <TableContainer width="100%">
        <Table variant="simple" whiteSpace="normal">
          <Thead>
            <Tr>
              <Th>User Op Hash</Th>
              <Th>Sponsored {props.blockchain.coin}</Th>
              <Th>Date</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {props.transactions?.map((tx) => (
              <SponsoredTransactionListItem
                blockchain={props.blockchain}
                userOp={tx}
                key={`sponsored-transaction-${tx.id}`}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
}
