import { BlockchainEndpointResponse } from '@web/dto/rpc/blockchainEndpointResponse';
import { useMemo } from 'react';
import { useInstance } from '../../../../domain/hook/useInstance';
import { BlockchainStore } from '../../../../domain/store/BlockchainStore';

export function useEndpointTitle(blockchainEndpoint: BlockchainEndpointResponse | null) {
  const blockchainStore = useInstance(BlockchainStore);

  const blockchain = useMemo(() => {
    if (!blockchainEndpoint) {
      return null;
    }

    return blockchainStore.blockchains.find((b) => b.chainId === blockchainEndpoint.chainId);
  }, [blockchainEndpoint, blockchainStore.blockchains]);

  return useMemo(() => {
    if (!blockchainEndpoint) {
      return '';
    }

    if (!blockchainEndpoint.managed) {
      return blockchainEndpoint.title;
    }

    if (blockchainEndpoint.wallet) {
      return `RPC of Wallet ${blockchainEndpoint.wallet} for ${blockchain?.fqdn ?? blockchainEndpoint.chainId}`;
    }

    if (blockchainEndpoint.organizationId) {
      return `Default Organization RPC for ${blockchain?.fqdn ?? blockchainEndpoint.chainId}`;
    }

    return blockchainEndpoint.title;
  }, [blockchainEndpoint, blockchain]);
}
