/* eslint-disable react-hooks/rules-of-hooks */
import { Flex, Heading, Spinner, Text, useColorMode } from '@chakra-ui/react';
import type { DataPeriod } from '@web/dto/rpc/dataPeriod';
import type { ApexOptions } from 'apexcharts';
import { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useGetBlockchainEndpointMethodUsage } from '../../../domain/api/ReportApi';
import { useInstance } from '../../../domain/hook/useInstance';
import { OrganizationStore } from '../../../domain/store/OrganizationStore';
import { dataPeriodToString } from '../../../utils/dataPeriod';

interface IProps {
  readonly period: DataPeriod;
  readonly blockchainEndpointId?: string;
}

export function BlockchainEndpointMethodUsageChart(props: IProps) {
  const { colorMode } = useColorMode();
  const organizationStore = useInstance(OrganizationStore);

  const { data, isValidating } = useGetBlockchainEndpointMethodUsage(organizationStore.currentOrganization?.id ?? '', {
    period: props.period,
    blockchainEndpointId: props.blockchainEndpointId,
  });

  const chartData = useMemo(() => {
    return data?.map((d) => d.count) ?? [];
  }, [data]);

  const options = useMemo((): ApexOptions => {
    return {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: data?.map((d) => d.method) ?? [],
      theme: {
        mode: colorMode,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }, [data, colorMode]);

  return (
    <Flex borderWidth={1} boxShadow="lg" flexDir="column" pb={2} pos="relative" pt={6} px={4} rounded="lg">
      <Flex justify="space-between" mb={2} mx={4}>
        <Heading size="sm">RPC Methods ({dataPeriodToString(props.period)})</Heading>
      </Flex>
      {!data?.length ? (
        <Flex align="center" height={400} justify="center" mx={6}>
          <Text textAlign="center">
            {isValidating ? (
              <Spinner as="span" />
            ) : (
              'After you interact with blockchain endpoint, stats will show up here'
            )}
          </Text>
        </Flex>
      ) : (
        <Chart height={400} options={options} series={chartData} type="donut" />
      )}
    </Flex>
  );
}
