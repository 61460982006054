import { Container, Flex, Heading, HStack, Link, VStack } from '@chakra-ui/layout';
import { Spinner, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import { useGetSmartContracts } from '../../../../domain/api/SmartContractApi';
import { useCurrentSlug } from '../../../../domain/hook/useCurrentSlug';
import { useInstance } from '../../../../domain/hook/useInstance';
import { OrganizationStore } from '../../../../domain/store/OrganizationStore';
import { AppRoutes } from '../../../../router/Routes';
import { CtaButton } from '../../../components/CtaButton';
import { SmartContractListItem } from './components/SmartContractListItem';

export const SmartContractListRoute = observer(function SmartContractListRoute() {
  useTitle('Smart Contracts | kriptonio');
  const slug = useCurrentSlug();
  const organizationStore = useInstance(OrganizationStore);
  const { data: smartContracts, isValidating } = useGetSmartContracts(organizationStore.currentOrganization?.id ?? '');
  const navigate = useNavigate();

  const toNewSmartContract = useCallback(() => {
    navigate(
      generatePath(AppRoutes.smartContract.new, {
        slug,
      })
    );
  }, [navigate, slug]);

  return (
    <Container maxW="container.xl" pb={6}>
      <HStack justify="space-between" marginY={6}>
        <Heading>Smart Contracts</Heading>
        <CtaButton
          onClick={toNewSmartContract}
          text="New Smart Contract"
          isLoading={isValidating}
          upgradeText="Upgrade for more smart contracts"
          upgrade={
            !isValidating &&
            (organizationStore.currentOrganization?.subscription?.subscriptionLimit?.smartContracts ?? 0) <=
              (smartContracts?.length ?? 0)
          }
        />
      </HStack>
      <VStack spacing={4}>
        {isValidating ? (
          <Spinner />
        ) : !smartContracts?.length ? (
          <Flex
            alignItems="center"
            borderRadius="lg"
            borderWidth={1}
            h={20}
            justifyContent="center"
            p={5}
            shadow="md"
            w="100%"
          >
            <Text textAlign="center">
              Currently you don't have any smart contracts. Click <Link onClick={toNewSmartContract}>here</Link> to
              create first one.
            </Text>
          </Flex>
        ) : (
          smartContracts.map((sc) => <SmartContractListItem key={`smart-contract-item-${sc.id}`} smartContract={sc} />)
        )}
      </VStack>
    </Container>
  );
});
