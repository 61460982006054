import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Badge, Code, Collapse, Td, Text, Tr, VStack, useDisclosure } from '@chakra-ui/react';
import type { BlockchainEndpointCallResponse } from '@web/dto/rpc/blockchainEndpointCallResponse';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { Colorize } from '../../../Colorize';

export interface EndpointLogListItemProps {
  call: BlockchainEndpointCallResponse;
}

export const EndpointLogListItem = observer(function EndpointLogListItem(props: EndpointLogListItemProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const formattedJson = useMemo(() => {
    if (!props.call.requestBody) {
      return null;
    }

    try {
      return JSON.stringify(JSON.parse(props.call.requestBody), null, 2);
    } catch (e) {
      console.error(`error while parsing request body: ${props.call.requestBody}`, e);
      return null;
    }
  }, [props.call.requestBody]);

  const errorText = useMemo(() => {
    return [props.call.errorCode ? `Code: ${props.call.errorCode}.` : null, props.call.errorMessage]
      .filter((i) => !!i)
      .join(' ');
  }, [props.call.errorCode, props.call.errorMessage]);

  return (
    <>
      <Tr cursor="pointer" onClick={() => (isOpen ? onClose() : onOpen())}>
        <Td py={3}>
          {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
          <Code>{props.call.method}</Code>
        </Td>
        <Td py={3}>
          <Badge colorScheme={props.call.httpCode === 200 ? 'green' : 'red'}>{props.call.httpCode}</Badge>
        </Td>
        <Td py={3}>
          <Badge colorScheme={props.call.status === 'Error' ? 'red' : undefined}>{props.call.status}</Badge>
        </Td>
        <Td py={3} whiteSpace="nowrap">
          {format(new Date(props.call.createdAt), 'dd MMM, HH:mm:ss')}
        </Td>
      </Tr>
      <Tr>
        <Td colSpan={5} px={0} py={0} borderBottom={0}>
          <Collapse in={isOpen}>
            <VStack w="100%" px={4} py={4} bgColor="gray.100" align="flex-start">
              {errorText ? (
                <VStack align="flex-start" w="100%" flex={1} borderRadius="lg" maxH={400} overflow="auto">
                  <Text fontWeight="semibold" fontSize="md">
                    Error
                  </Text>
                  <Text>{errorText}</Text>
                </VStack>
              ) : null}
              <VStack align="flex-start" w="100%" flex={1} borderRadius="lg" maxH={400} overflow="auto">
                <Text fontWeight="semibold" fontSize="md">
                  Raw Request:
                </Text>
                {formattedJson ? (
                  <Colorize text={formattedJson} language="json" wrapLines />
                ) : (
                  <Text>Request body not available</Text>
                )}
              </VStack>
            </VStack>
          </Collapse>
        </Td>
      </Tr>
    </>
  );
});
