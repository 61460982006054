import { Flex, Text } from '@chakra-ui/react';
import type React from 'react';

interface IProps {
  readonly text: string;
  readonly icon: React.ReactNode;
  readonly selected: boolean;
  readonly onClick: () => void;
}

export function SmartContractCreationOption(props: IProps) {
  return (
    <Flex
      align="center"
      borderColor={props.selected ? 'blue.500' : 'gray.200'}
      borderWidth={2}
      cursor="pointer"
      flex={1}
      flexDir="column"
      justify="center"
      minH="100px"
      onClick={props.onClick}
      px={6}
      py={4}
      borderRadius="lg"
    >
      <Flex alignItems="center" flex={1}>
        {props.icon}
      </Flex>
      <Flex flex={1} mt={4}>
        <Text fontSize="sm" textAlign="center" fontWeight={500}>
          {props.text}
        </Text>
      </Flex>
    </Flex>
  );
}
