import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useVm } from '../../../../../domain/hook/useVm';
import { ISetupTwoFactorModalProps, SetupTwoFactorModalVm } from './SetupTwoFactorModalVm';

export const SetupTwoFactorModal = observer(function CreateTokenModal(props: ISetupTwoFactorModalProps) {
  const vm = useVm(SetupTwoFactorModalVm, props);

  useEffect(() => {
    if (props.isOpen) {
      vm.setupTwoFactor.run().catch(console.error);
    }
  }, [props.isOpen, vm]);

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Two Factor Authentication Setup</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {vm.setupTwoFactor.isBusy ? (
            <Flex justify="center" w="100%">
              <Spinner />
            </Flex>
          ) : (
            <VStack>
              <Box>
                <Text fontWeight="bold" textAlign="center">
                  Scan QR code from your 2FA app
                </Text>
                <Text fontSize="xs" fontStyle="italic" textAlign="center">
                  Any TOTP compatible application is supported, for example Google Authenticator, Microsoft
                  Authenticator, 1Password, etc.
                </Text>
              </Box>
              <Image src={vm.twoFactorSetup?.qrCodeImage} w="200px" />
              <Text fontSize="sm" textAlign="center" wordBreak="break-word">
                Having problems with scanning code?{' '}
                {!vm.manualCodeShown && (
                  <Text as="span" color="blue.500" cursor="pointer" onClick={() => vm.setManualCodeShown(true)}>
                    Show setup key
                  </Text>
                )}
                {vm.manualCodeShown ? <b>{vm.twoFactorSetup?.manualSetupKey}</b> : null}
              </Text>

              <Text fontWeight="bold" paddingTop={4} textAlign="center">
                Enter generated 2FA code
              </Text>
              <HStack>
                {vm.submitTwoFactor.isBusy ? (
                  <Flex justify="center" w="100%">
                    <Spinner />
                  </Flex>
                ) : (
                  <PinInput onComplete={vm.submitTwoFactor.run} otp type="number">
                    <PinInputField autoFocus />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                )}
              </HStack>
            </VStack>
          )}
        </ModalBody>

        <ModalFooter>
          <HStack spacing={2}>
            <Button onClick={props.onClose}>Cancel</Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
