import {
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { EndpointCallStatus } from '@web/dto/rpc/endpointCallStatus';
import { Select } from 'chakra-react-select';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useVm } from '../../../../domain/hook/useVm';
import { PeriodSelector } from '../../../dashboard/components/PeriodSelector';
import { Pagination } from '../Pagination';
import { SectionDivider } from '../SectionDivider';
import { EndpointLogListItem } from './components/endpoint-log-list-item/EndpointLogListItem';
import { EndpointLogsProps, EndpointLogsVm } from './EndpointLogsVm';

export const EndpointLogs = observer(function EndpointLogs(props: EndpointLogsProps) {
  const vm = useVm(EndpointLogsVm, props);
  const [searchParams] = useSearchParams();

  const defaultCallStatus = useMemo(() => {
    const status = searchParams.get('callStatus') as EndpointCallStatus | undefined;
    if (status) {
      return {
        label: status,
        value: status,
      };
    }
  }, [searchParams]);

  useEffect(() => {
    if (defaultCallStatus) {
      vm.setFilterByCallStatus([defaultCallStatus]);
    } else {
      void vm.getBlockchainCalls.run();
    }
  }, [vm, props.blockchainEndpoint?.id, defaultCallStatus]);

  return (
    <Box h="100%" pb={6} w="100%">
      {props.title ? (
        <>
          <HStack justify="space-between">
            <Heading size="lg">{props.title}</Heading>
          </HStack>
          <SectionDivider />
        </>
      ) : null}
      <VStack spacing={4} align="flex-start">
        <VStack align="flex-start">
          <Heading size="md">Filters</Heading>
          <HStack>
            <Box width="350px">
              <Select
                chakraStyles={{
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    bg: 'transparent',
                    px: 2,
                  }),
                }}
                colorScheme="purple"
                isMulti
                onChange={vm.setFilterByMethod}
                options={vm.rpcMethods.map((m) => ({
                  label: m,
                  value: m,
                }))}
                placeholder="Filter By Method"
              />
            </Box>
            <Box width="250px">
              <Select
                chakraStyles={{
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    bg: 'transparent',
                    px: 2,
                  }),
                }}
                colorScheme="purple"
                defaultValue={defaultCallStatus}
                isMulti
                onChange={vm.setFilterByCallStatus}
                options={vm.callStatuses.map((s) => ({
                  label: s,
                  value: s,
                }))}
                placeholder="By Status"
              />
            </Box>
          </HStack>
        </VStack>
        <VStack align="flex-start">
          <Heading size="md">Period</Heading>
          <PeriodSelector onPeriodSelected={vm.setPeriod} period={vm.filters.period} title="" />
        </VStack>
      </VStack>
      <Flex align="flex-end" justify="space-between" py={4} w="100%">
        <Text fontWeight={700}>
          {vm.data ? `Showing ${Math.min(vm.data.pageSize, vm.data.items.length)} of ${vm.data.count} items` : '...'}
        </Text>
        <Tooltip label="Refresh" shouldWrapChildren>
          <FontAwesomeIcon cursor="pointer" icon={faRefresh} onClick={vm.getBlockchainCalls.run} size="lg" width={20} />
        </Tooltip>
      </Flex>
      {vm.getBlockchainCalls.isBusy ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <VStack align="flex-start" borderRadius="lg" borderWidth={1}>
          <TableContainer width="100%">
            <Table variant="simple" whiteSpace="normal">
              <Thead>
                <Tr>
                  <Th>Method</Th>
                  <Th>HTTP Code</Th>
                  <Th>Status</Th>
                  <Th>Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {vm.data?.items?.map((call) => (
                  <EndpointLogListItem call={call} key={`blockchain-activity-${call.createdAt}`} />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          {vm.data ? (
            <Box px={6} py={2} w="100%">
              <Pagination
                onCurrentPageChange={vm.handleCurrentPageChange}
                onPageSizeChange={vm.handleCurrentPageSizeChange}
                page={vm.currentPage}
                pageCount={vm.pageCount}
                pageSize={vm.currentPageSize}
              />
            </Box>
          ) : null}
        </VStack>
      )}
    </Box>
  );
});
