export interface BlockchainRawError {
  reason?: string;
  code?: string; // https://docs.ethers.io/v5/troubleshooting/errors/
  error?: {
    reason?: string;
    code?: string;
  };
  body?: string;
}

export interface BlockchainParsedError {
  code: string;
  message: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseEthersError(e: any): BlockchainParsedError | undefined {
  const error = e as BlockchainRawError;

  try {
    if (!error.code) {
      return;
    }

    const errorBase = `(${error.code}) ${error.reason}`;

    if (error.body) {
      // body is sometimes json sometimes just a string
      if (!error.body.startsWith('{')) {
        return {
          code: error.code,
          message: `${errorBase}. ${error.body}`,
        };
      }

      const errorBody = JSON.parse(error.body);
      if (!errorBody.error.message) {
        return {
          code: error.code,
          message: errorBase,
        };
      }

      return {
        code: error.code,
        message: `${errorBase}. ${errorBody.error.message}`,
      };
    }

    if (error.error) {
      return {
        code: error.code,
        message: `${errorBase}. ${error.error.reason}`,
      };
    }

    return {
      code: error.code,
      message: errorBase,
    };
  } catch (e) {
    return;
  }
}
