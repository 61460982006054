import {
  Button,
  Center,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { faRocket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { OrganizationDetailResponse } from '@web/dto/api/organizationDetailResponse';
import { SubscriptionPlanType } from '@web/dto/api/subscriptionPlanType';
import { ValidatedForm, ValidatedFormControl } from '@web/toolkit';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import * as yup from 'yup';
import { useGetSubscriptionPlans } from '../../../../../domain/api/SubscriptionApi';
import { useVm } from '../../../../../domain/hook/useVm';
import { SubscriptionListItem } from '../SubscriptionListItem';
import { SubscriptionListVm } from './SubscriptionListVm';

interface IProps {
  organization: OrganizationDetailResponse;
  activePlanId?: string;
  welcome: boolean;
}

export const SubscriptionList = observer(function SubscriptionList(props: IProps) {
  const { data, isLoading } = useGetSubscriptionPlans();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const vm = useVm(SubscriptionListVm);

  const contact = useCallback(() => {
    void vm.sendContact.run().then((success) => {
      if (success) {
        onClose();
      }
    });
  }, [vm, onClose]);

  const freePlan = data?.find((x) => x.planType === SubscriptionPlanType.Free);
  const personalPlan = data?.find((x) => x.planType === SubscriptionPlanType.Personal);
  const starterPlan = data?.find((x) => x.planType === SubscriptionPlanType.Starter);
  const growthPlan = data?.find((x) => x.planType === SubscriptionPlanType.Growth);

  return (
    <>
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <VStack spacing={6}>
          <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing={8} justifyItems="center">
            {freePlan ? (
              <SubscriptionListItem
                title="Free"
                plan={freePlan}
                activePlanTypeId={props.activePlanId}
                price={0}
                billingRequired={false}
                features={[
                  'Testnet Only',
                  '200k API credits per month',
                  '2 Managed Smart Contracts',
                  '1 Paymaster',
                  '1 RPC Endpoint',
                ]}
                organizationId={props.organization.id}
                welcome={props.welcome}
              />
            ) : null}
            {personalPlan ? (
              <SubscriptionListItem
                title="Personal"
                plan={personalPlan}
                activePlanTypeId={props.activePlanId}
                price={0}
                billingRequired
                features={[
                  'Mainnet & Testnet',
                  '1K Monthly Sponsored Transactions',
                  '2M API credits per month',
                  '10 Managed Smart Contracts',
                  '2 Paymasters',
                  '2 RPC Endpoints',
                  '10% gas sponsorship premium',
                  'Basic Support',
                ]}
                organizationId={props.organization.id}
                welcome={props.welcome}
              />
            ) : null}
            {starterPlan ? (
              <SubscriptionListItem
                title="Starter"
                plan={starterPlan}
                activePlanTypeId={props.activePlanId}
                price={59}
                billingRequired
                features={[
                  'Mainnet & Testnet',
                  '10K Monthly Sponsored Transactions',
                  '100M API credits per month',
                  '50 Managed Smart Contracts',
                  '50 Paymasters',
                  '50 RPC Endpoints',
                  '8% gas sponsorship premium',
                  'Enhanced Support',
                ]}
                organizationId={props.organization.id}
                welcome={props.welcome}
              />
            ) : null}
            {growthPlan ? (
              <SubscriptionListItem
                title="Growth"
                plan={growthPlan}
                activePlanTypeId={props.activePlanId}
                price={259}
                billingRequired
                features={[
                  'Mainnet & Testnet',
                  '100K Monthly Sponsored Transactions',
                  '500M API credits per month',
                  '100 Managed Smart Contracts',
                  '100 Paymasters',
                  '100 RPC Endpoints',
                  '8% gas sponsorship premium',
                  'VIP Support',
                ]}
                organizationId={props.organization.id}
                welcome={props.welcome}
              />
            ) : null}
          </SimpleGrid>
          <VStack>
            <HStack
              w="100%"
              justify="space-between"
              bgColor="white"
              px={6}
              py={3}
              borderRadius="lg"
              spacing={8}
              borderWidth={1}
            >
              <HStack>
                <FontAwesomeIcon icon={faRocket} size="lg" width={20} />
                <Text>
                  Interested in custom offer with no limits, enterprise support, SLA, volume discount and more?
                </Text>
              </HStack>
              <Button variant="outline" onClick={onOpen}>
                Contact us
              </Button>
            </HStack>
          </VStack>
        </VStack>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ValidatedForm onSubmit={contact}>
            <ModalHeader>Contact</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ValidatedFormControl
                schema={yup.string().required('Please provide message')}
                value={vm.contactForm.message}
              >
                <FormLabel>Message</FormLabel>
                <Textarea rows={5} onChange={(e) => vm.setMessage(e.target.value)} value={vm.contactForm.message} />
              </ValidatedFormControl>
            </ModalBody>

            <ModalFooter>
              <HStack>
                <Button onClick={onClose}>Close</Button>
                <Button isLoading={vm.sendContact.isBusy} type="submit" colorScheme="blue">
                  Send
                </Button>
              </HStack>
            </ModalFooter>
          </ValidatedForm>
        </ModalContent>
      </Modal>
    </>
  );
});
