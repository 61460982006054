import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { IndexRoute } from '../modules/IndexRoute';
import { ForgotPasswordRoute } from '../modules/auth/forgot-password/ForgotPasswordRoute';
import { InvitationRoute } from '../modules/auth/invitation/InvitationRoute';
import { LoginRoute } from '../modules/auth/login/LoginRoute';
import { RegisterRoute } from '../modules/auth/register/RegisterRoute';
import { ResetPasswordRoute } from '../modules/auth/reset-password/ResetPasswordRoute';
import { BlockchainEndpointActivityRoute } from '../modules/blockchain/endpoint/activity/BlockchainEndpointActivityRoute';
import { BlockchainEndpointDetailRoute } from '../modules/blockchain/endpoint/detail/BlockchainEndpointDetailRoute';
import { BlockchainEndpointGeneralRoute } from '../modules/blockchain/endpoint/general/BlockchainEndpointGeneralRoute';
import { BlockchainEndpointListRoute } from '../modules/blockchain/endpoint/list/BlockchainEndpointListRoute';
import { BlockchainEndpointLogsRoute } from '../modules/blockchain/endpoint/logs/BlockchainEndpointLogsRoute';
import { NewBlockchainEndpointRoute } from '../modules/blockchain/endpoint/new/NewBlockchainEndpointRoute';
import { SmartContractDetailRoute } from '../modules/blockchain/smart-contract/detail/SmartContractDetailRoute';
import { SmartContractEventsRoute } from '../modules/blockchain/smart-contract/events/SmartContractEventsRoute';
import { SmartContractGeneralRoute } from '../modules/blockchain/smart-contract/general/SmartContractGeneralRoute';
import { SmartContractListRoute } from '../modules/blockchain/smart-contract/list/SmartContractListRoute';
import { NewSmartContractRoute } from '../modules/blockchain/smart-contract/new/NewSmartContractRoute';
import { SmartContractSourceCodeRoute } from '../modules/blockchain/smart-contract/source-code/SmartContractSourceCodeRoute';
import { SmartContractUiRoute } from '../modules/blockchain/smart-contract/ui/SmartContractUiRoute';
import { DashboardRoute } from '../modules/dashboard/DashboardRoute';
import { WalletConnectRoute } from '../modules/mobile/WalletConnectRoute';
import { PaymasterEndpointDetailRoute } from '../modules/paymaster/detail/PaymasterEndpointDetailRoute';
import { PaymasterEndpointGeneralRoute } from '../modules/paymaster/general/PaymasterEndpointGeneralRoute';
import { PaymasterEndpointListRoute } from '../modules/paymaster/list/PaymasterEndpointListRoute';
import { NewPaymasterEndpointRoute } from '../modules/paymaster/new/NewPaymasterEndpointRoute';
import { PaymasterGlobalPolicyRoute } from '../modules/paymaster/policy/PaymasterGlobalPolicyRoute';
import { SettingsRoute } from '../modules/settings/SettingsRoute';
import { AccessTokensRoute } from '../modules/settings/access-tokens/AccessTokensRoute';
import { AccountSettingsRoute } from '../modules/settings/account/AccountSettingsRoute';
import { BillingSettingsRoute } from '../modules/settings/billing/BillingSettingsRoute';
import { OrganizationSettingsRoute } from '../modules/settings/organization/OrganizationSettingsRoute';
import { SecuritySettingsRoute } from '../modules/settings/security/SecuritySettingsRoute';
import { OrganizationUsersRoute } from '../modules/settings/users/OrganizationUsersRoute';
import { SubscriptionChangeRoute } from '../modules/subscription/list/SubscriptionChangeRoute';
import { SubscriptionWelcomeRoute } from '../modules/subscription/list/SubscriptionWelcomeRoute';
import { SubscriptionChangeSuccessRoute } from '../modules/subscription/success/SubscriptionChangeSuccessRoute';
import { SubscriptionPaymentSuccessRoute } from '../modules/subscription/success/SubscriptionPaymentSuccessRoute';
import { AnonymousRoute } from './AnonymousRoute';
import { AppRoutes } from './Routes';
import { AuthenticatedRoute } from './authenticated/AuthenticatedRoute';

export function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <AuthenticatedRoute>
              <IndexRoute />
            </AuthenticatedRoute>
          }
          path={AppRoutes.index}
        />
        <Route
          element={
            <AuthenticatedRoute>
              <IndexRoute />
            </AuthenticatedRoute>
          }
          path={AppRoutes.orgIndex}
        />
        <Route element={<WalletConnectRoute />} path={AppRoutes.walletConnect} />
        {/* Auth */}
        <Route
          element={
            <AnonymousRoute>
              <LoginRoute />
            </AnonymousRoute>
          }
          path={AppRoutes.auth.login}
        />
        <Route
          element={
            <AnonymousRoute>
              <InvitationRoute />
            </AnonymousRoute>
          }
          path={AppRoutes.auth.invitation}
        />
        <Route
          element={
            <AnonymousRoute>
              <ForgotPasswordRoute />
            </AnonymousRoute>
          }
          path={AppRoutes.auth.forgotPassword}
        />
        <Route
          element={
            <AnonymousRoute>
              <ResetPasswordRoute />
            </AnonymousRoute>
          }
          path={AppRoutes.auth.resetPassword}
        />
        <Route
          element={
            <AnonymousRoute>
              <RegisterRoute />
            </AnonymousRoute>
          }
          path={AppRoutes.auth.register}
        />
        {/* Settings */}
        <Route
          element={
            <AuthenticatedRoute>
              <SettingsRoute />
            </AuthenticatedRoute>
          }
          path={AppRoutes.settings.index}
        >
          <Route element={<AccountSettingsRoute />} path={AppRoutes.settings.account} />
          <Route element={<OrganizationSettingsRoute />} path={AppRoutes.settings.organization} />
          <Route element={<SecuritySettingsRoute />} path={AppRoutes.settings.security} />
          <Route element={<AccessTokensRoute />} path={AppRoutes.settings.accessTokens} />
          <Route element={<OrganizationUsersRoute />} path={AppRoutes.settings.users} />
          <Route element={<BillingSettingsRoute />} path={AppRoutes.settings.billing} />
        </Route>
        <Route
          element={
            <AuthenticatedRoute>
              <DashboardRoute />
            </AuthenticatedRoute>
          }
          path={AppRoutes.dashboard}
        />
        {/* Paymasters */}
        <Route
          element={
            <AuthenticatedRoute>
              <PaymasterEndpointListRoute />
            </AuthenticatedRoute>
          }
          path={AppRoutes.paymaster.list}
        />
        <Route
          element={
            <AuthenticatedRoute>
              <NewPaymasterEndpointRoute />
            </AuthenticatedRoute>
          }
          path={AppRoutes.paymaster.new}
        />
        <Route
          element={
            <AuthenticatedRoute>
              <PaymasterGlobalPolicyRoute />
            </AuthenticatedRoute>
          }
          path={AppRoutes.paymaster.policy}
        />
        <Route
          element={
            <AuthenticatedRoute>
              <PaymasterEndpointDetailRoute />
            </AuthenticatedRoute>
          }
          path={AppRoutes.paymaster.detail}
        >
          <Route element={<PaymasterEndpointGeneralRoute />} path={AppRoutes.paymaster.general} />
        </Route>
        {/* Blockchain Endpoint */}
        <Route
          element={
            <AuthenticatedRoute>
              <BlockchainEndpointListRoute />
            </AuthenticatedRoute>
          }
          path={AppRoutes.blockchainEndpoint.list}
        />
        <Route
          element={
            <AuthenticatedRoute>
              <NewBlockchainEndpointRoute />
            </AuthenticatedRoute>
          }
          path={AppRoutes.blockchainEndpoint.new}
        />
        <Route
          element={
            <AuthenticatedRoute>
              <BlockchainEndpointDetailRoute />
            </AuthenticatedRoute>
          }
          path={AppRoutes.blockchainEndpoint.detail}
        >
          <Route element={<BlockchainEndpointGeneralRoute />} path={AppRoutes.blockchainEndpoint.general} />
          <Route element={<BlockchainEndpointActivityRoute />} path={AppRoutes.blockchainEndpoint.activity} />
          <Route element={<BlockchainEndpointLogsRoute />} path={AppRoutes.blockchainEndpoint.logs} />
        </Route>
        {/* Smart Contract */}
        <Route
          element={
            <AuthenticatedRoute>
              <SmartContractListRoute />
            </AuthenticatedRoute>
          }
          path={AppRoutes.smartContract.list}
        />
        <Route
          element={
            <AuthenticatedRoute>
              <NewSmartContractRoute />
            </AuthenticatedRoute>
          }
          path={AppRoutes.smartContract.new}
        />
        <Route
          element={
            <AuthenticatedRoute>
              <SmartContractDetailRoute />
            </AuthenticatedRoute>
          }
          path={AppRoutes.smartContract.detail}
        >
          <Route element={<SmartContractGeneralRoute />} path={AppRoutes.smartContract.general} />
          <Route element={<SmartContractUiRoute />} path={AppRoutes.smartContract.ui} />
          <Route element={<SmartContractEventsRoute />} path={AppRoutes.smartContract.events} />
          <Route element={<SmartContractSourceCodeRoute />} path={AppRoutes.smartContract.sourceCode} />
        </Route>
        {/* Subscription */}
        <Route
          element={
            <AuthenticatedRoute basic>
              <SubscriptionWelcomeRoute />
            </AuthenticatedRoute>
          }
          path={AppRoutes.subscription.welcome}
        />
        <Route
          element={
            <AuthenticatedRoute>
              <SubscriptionChangeRoute />
            </AuthenticatedRoute>
          }
          path={AppRoutes.subscription.change}
        />
        <Route
          element={
            <AuthenticatedRoute>
              <SubscriptionPaymentSuccessRoute />
            </AuthenticatedRoute>
          }
          path={AppRoutes.subscription.successPayment}
        />
        <Route
          element={
            <AuthenticatedRoute>
              <SubscriptionChangeSuccessRoute />
            </AuthenticatedRoute>
          }
          path={AppRoutes.subscription.successUpdate}
        />
      </Routes>
    </BrowserRouter>
  );
}
