import type { BlockchainEndpointCallResponsePaged } from '@web/dto/rpc/blockchainEndpointCallResponsePaged';
import type { BlockchainEndpointResponse } from '@web/dto/rpc/blockchainEndpointResponse';
import type { CreateBlockchainEndpointBody } from '@web/dto/rpc/createBlockchainEndpointBody';
import type { GetBlockchainCallsBody } from '@web/dto/rpc/getBlockchainCallsBody';
import { env } from '../../env';
import { singleton } from '../../inversify/decorator';
import { useSwr } from '../hook/useSwr';
import { AjaxService } from '../service/AjaxService';
import { Api } from './Api';

@singleton()
export class BlockchainEndpointApi extends Api {
  constructor(private readonly ajax: AjaxService) {
    super();
  }

  public createBlockchainEndpoint = (organizationId: string, data: CreateBlockchainEndpointBody) => {
    return this.ajax.post<BlockchainEndpointResponse>(`v1/organizations/${organizationId}/endpoints`, {
      data,
      baseURL: env.rpc,
    });
  };

  public getOrCreate = (orgranizationId: string, chainId: number, wallet?: string) => {
    const options: { [key: string]: unknown } = { wallet };

    const populatedOptions = Object.keys(options).reduce((acc, key) => {
      if (options[key] != null) {
        acc.push(`${key}=${options[key]}`);
      }

      return acc;
    }, [] as string[]);

    return this.ajax.get<BlockchainEndpointResponse>(
      `/v1/organizations/${orgranizationId}/endpoints/chains/${chainId}?${populatedOptions.join('&')}`,
      { baseURL: env.rpc }
    );
  };

  public getBlockchainEndpoint = (id: string) => {
    return this.ajax.get<BlockchainEndpointResponse>(`v1/endpoints/${id}`, {
      baseURL: env.rpc,
    });
  };

  public deleteBlockchainEndpoint = (id: string) => {
    return this.ajax.delete(`v1/endpoints/${id}`, {
      baseURL: env.rpc,
    });
  };

  public getBlockchainCalls = (id: string, data: GetBlockchainCallsBody) => {
    return this.ajax.post<BlockchainEndpointCallResponsePaged>(`v1/endpoints/${id}/calls`, {
      data,
      baseURL: env.rpc,
    });
  };
}

export function useGetBlockchainEndpoints(organizationId: string) {
  return useSwr<BlockchainEndpointResponse[]>(`v1/organizations/${organizationId}/endpoints`, {
    baseURL: env.rpc,
  });
}
