/* eslint-disable @typescript-eslint/no-namespace */
import { QuestionIcon } from '@chakra-ui/icons';
import { Box, Center, HStack, Text, VStack } from '@chakra-ui/layout';
import { Heading, Spinner, Tooltip } from '@chakra-ui/react';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { useTitle } from 'react-use';
import { CopyableText } from '../../components/CopyableText';
import { HexAddress } from '../../components/HexAddress';
import { SectionDivider } from '../../components/SectionDivider';
import { SmartContractDeployment } from '../../components/smart-contract-deployment/SmartContractDeployment';
import { SmartContractRouteVmContext } from '../detail/SmartContractDetailRoute';

export const SmartContractGeneralRoute = observer(function SmartContractGeneralRoute() {
  useTitle('Smart Contract General | kriptonio');
  const context = useContext(SmartContractRouteVmContext);

  return (
    <Box maxW={700} pb={10} w="100%">
      <HStack justify="space-between">
        <Heading size="lg">Basic Information</Heading>
      </HStack>
      {!context.smartContract ? (
        <Center marginTop={5} w="100%">
          <Spinner />
        </Center>
      ) : (
        <>
          <SectionDivider />
          {context.smartContract ? (
            <VStack align="flex-start">
              <Box>
                <Text fontSize="md" fontWeight="semibold">
                  Smart Contract ID
                </Text>
                <CopyableText text={context.smartContract.id} />
              </Box>
              <Box>
                <Text fontSize="md" fontWeight="semibold">
                  Title
                </Text>
                <Text>{context.smartContract.title}</Text>
              </Box>
              <Box>
                <Text fontSize="md" fontWeight="semibold">
                  Blockchain
                </Text>
                <Text>{context.blockchain?.fqdn}</Text>
              </Box>
              <Box>
                <Text fontSize="md" fontWeight="semibold">
                  Created At
                </Text>
                <Text>{format(new Date(context.smartContract.createdAt), 'MMM dd yyy, HH:mm:ss')}</Text>
              </Box>
              <Box>
                <Text fontSize="md" fontWeight="semibold">
                  Address
                </Text>
                {context.smartContract?.deployment ? (
                  <HexAddress address={context.smartContract.deployment.address} fontSize="sm" />
                ) : (
                  <Text color="red.500">Smart contract not yet deployed</Text>
                )}
              </Box>
            </VStack>
          ) : null}
          <HStack>
            <Heading marginTop={6} size="lg">
              Deployment
            </Heading>
            <Tooltip label="To be able to interact with the smart contract, you need to first deploy your smart contract to blockchain">
              <QuestionIcon />
            </Tooltip>
          </HStack>
          <SectionDivider />
          {context.blockchain && context.sdkSmartContract ? (
            <SmartContractDeployment
              onReloadSmartContract={context.loadSmartContract.run}
              smartContract={context.sdkSmartContract}
              blockchain={context.blockchain}
            />
          ) : null}
        </>
      )}
    </Box>
  );
});
