import { Progress, ThemeTypings } from '@chakra-ui/react';
import { useMemo } from 'react';

interface IProps {
  readonly current: number;
  readonly max: number;
}

export function UsagePercentage(props: IProps) {
  const progressValue = useMemo(() => {
    return Math.ceil((props.current / props.max) * 100);
  }, [props]);

  const progressColor = useMemo((): ThemeTypings['colorSchemes'] => {
    if (progressValue < 30) {
      return 'green';
    }

    if (progressValue < 70) {
      return 'blue';
    }

    if (progressValue < 90) {
      return 'yellow';
    }

    return 'red';
  }, [progressValue]);

  return (
    <Progress w="100%" size="lg" colorScheme={progressColor} borderRadius="lg" value={progressValue} color="red.200" />
  );
}
