import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Text, useColorModeValue } from '@chakra-ui/react';
import { CLink } from '@web/toolkit';
import { generatePath, useMatch, useParams } from 'react-router-dom';

export interface MenuItemProps {
  readonly title: string;
  readonly url?: string;
  readonly externalUrl?: string;
  readonly isLast?: boolean;
}

export function LeftMenuItem(props: MenuItemProps) {
  const { id } = useParams();
  const textColor = useColorModeValue('black', 'white');
  const matchColor = useMatch(props.url ?? '') ? 'blue.500' : 'transparent';

  return (
    <CLink
      alignItems="center"
      borderBottomWidth={props.isLast ? 1 : 0}
      borderLeftWidth={2}
      borderRightWidth={2}
      display="flex"
      flexDirection="row"
      href={props.externalUrl}
      isExternal={!!props.externalUrl}
      minW={240}
      px={8}
      py={3}
      textDecoration="none !important"
      to={props.url ? generatePath(props.url, { id }) : undefined}
      w="100%"
      borderRightColor="transparent"
      // eslint-disable-next-line react-hooks/rules-of-hooks
      justifyContent="flex-start"
      borderLeftColor={props.url ? matchColor : 'transparent'}
    >
      <Text color={textColor} fontWeight="semibold" whiteSpace="nowrap">
        {props.title}
      </Text>
      {props.externalUrl ? <ExternalLinkIcon color={textColor} ml={1} /> : null}
    </CLink>
  );
}
