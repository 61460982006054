import type { ApiErrorResponse } from '@web/dto/api/apiErrorResponse';
import axios, { AxiosError, AxiosProgressEvent, Method } from 'axios';
import { env } from '../../env';
import { singleton } from '../../inversify/decorator';
import { ApiErrorModel } from '../model/ApiErrorModel';

export type IHttpOkResponse<R> = {
  ok: true;
  data: R;
  httpStatusCode: number;
};

export type IHttpNotOkResponse = {
  ok: false;
  httpStatusCode: number;
  error: ApiErrorModel;
};

export type IHttpResponse<R> = IHttpOkResponse<R> | IHttpNotOkResponse;

export interface AjaxOptions {
  host?: string;
  method?: Method;
}

export interface IAjaxCallOptions {
  baseURL?: string;
  data?: unknown;
  accessToken?: string;
  timeout?: number;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
}

@singleton()
export class AjaxService {
  private sessionToken: string | null = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly call = async <T = any>(
    method: Method,
    url: string,
    options: IAjaxCallOptions = {}
  ): Promise<IHttpResponse<T>> => {
    try {
      const requestHeaders: { [key: string]: string } = {};

      if (this.sessionToken) {
        requestHeaders['X-Session-Token'] = this.sessionToken;
      }

      if (options.accessToken) {
        requestHeaders['X-Access-Token'] = options.accessToken;
      }

      const response = await axios.request<T>({
        method,
        url,
        data: options.data,
        headers: requestHeaders,
        baseURL: options.baseURL ?? env.api,
        timeout: options.timeout ?? 20000,
        onUploadProgress: options.onUploadProgress,
      });

      return {
        ok: true,
        data: response.data,
        httpStatusCode: response.status,
      };
    } catch (error) {
      const axiosError = error as AxiosError<ApiErrorResponse>;
      if (axiosError.response?.status) {
        return {
          ok: false,
          httpStatusCode: axiosError.response.status,
          error: ApiErrorModel.from(axiosError.response.data),
        };
      }

      throw error;
    }
  };

  public get = <T>(url: string, opts: IAjaxCallOptions = {}): Promise<IHttpResponse<T>> => {
    return this.call<T>('GET', url, opts);
  };

  public post = <T>(url: string, opts: IAjaxCallOptions = {}): Promise<IHttpResponse<T>> => {
    return this.call<T>('POST', url, opts);
  };

  public put = <T>(url: string, opts: IAjaxCallOptions = {}): Promise<IHttpResponse<T>> => {
    return this.call<T>('PUT', url, opts);
  };

  public delete = <T>(url: string, opts: IAjaxCallOptions = {}): Promise<IHttpResponse<T>> => {
    return this.call<T>('DELETE', url, opts);
  };

  public setSessionToken = (token: string | null) => {
    this.sessionToken = token;
  };
}
