import { Center, Container, Flex } from '@chakra-ui/layout';
import { Grid, GridItem, Spinner, VStack } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router';
import { useParams } from 'react-router-dom';
import { useVm } from '../../../../domain/hook/useVm';
import { LeftMenu } from '../../../components/LeftMenu';
import { SmartContractDetailRouteVm } from './SmartContractDetailRouteVm';
import { SmartContractOptionsMenu } from './components/SmartContractOptionsMenu';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const SmartContractRouteVmContext = React.createContext<SmartContractDetailRouteVm>(null!);

export const SmartContractDetailRoute = observer(function SmartContractDetailRoute() {
  const vm = useVm(SmartContractDetailRouteVm);
  const params = useParams<{ id: string }>();

  useEffect(() => {
    void vm.loadData();
  }, [vm, params.id]);

  return (
    <Container maxW="container.xl">
      <Grid
        columnGap={5}
        rowGap={5}
        h="100%"
        mt={5}
        templateColumns={{
          base: 'repeat(auto-fit, minmax(min-content, 1fr))',
          lg: 'min-content 1fr',
        }}
      >
        <GridItem>
          <VStack spacing={4} position="sticky" top={4} left={0}>
            <LeftMenu items={vm.menuItems} />
            {vm.smartContract ? <SmartContractOptionsMenu smartContract={vm.smartContract} /> : null}
          </VStack>
        </GridItem>
        <GridItem>
          <Flex h="100%" minW="xs" w="100%">
            <SmartContractRouteVmContext.Provider value={vm}>
              {vm.smartContract ? (
                <Outlet />
              ) : (
                <Center w="100%">
                  <Spinner />
                </Center>
              )}
            </SmartContractRouteVmContext.Provider>
          </Flex>
        </GridItem>
      </Grid>
    </Container>
  );
});
