import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { StepsTheme as Steps } from 'chakra-ui-steps';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

export const extendedTheme = extendTheme({
  config,
  components: {
    Steps,
    Alert: {
      parts: ['container'],
      baseStyle: {
        container: {
          borderRadius: 'lg',
        },
      },
    },
    Link: {
      baseStyle: {
        color: 'blue.500',
      },
    },
  },
});
