import { Box } from '@chakra-ui/react';
import { highlight, languages } from 'prismjs';
import Editor from 'react-simple-code-editor';

import 'prism-themes/themes/prism-atom-dark.css';
import 'prismjs/components/prism-solidity';

interface IProps {
  readonly code: string;
  readonly onCode: (value: string) => void;
}

export function CodeEditor(props: IProps) {
  return (
    <Box w="100%" position="relative" maxH="500px" overflow="scroll" borderRadius="md">
      <Editor
        value={props.code}
        onValueChange={props.onCode}
        highlight={(code) => highlight(code, languages.solidity, 'solidity')}
        padding={10}
        placeholder="Type Solidity code here..."
        style={{
          backgroundColor: '#282c34',
          color: 'white',
          minHeight: '200px',
        }}
      />
    </Box>
  );
}
