import { AddIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  Divider,
  FormLabel,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { CInput, ValidatedForm, ValidatedFormControl } from '@web/toolkit';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTitle } from 'react-use';
import * as yup from 'yup';
import { useVm } from '../../../domain/hook/useVm';
import { OrganizationUserItem } from './components/organization-user-item/OrganizationUserItem';
import { OrganizationUsersRouteVm } from './OrganizationUsersRouteVm';

export const OrganizationUsersRoute = observer(function OrganizationUsersRoute() {
  useTitle('Organization Users | kriptonio');
  const vm = useVm(OrganizationUsersRouteVm);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const invite = useCallback(() => {
    void vm.invite.run().then(onClose);
  }, [vm.invite, onClose]);

  return (
    <>
      <Box pb={10} w="100%">
        <HStack justify="space-between">
          <Heading size="lg">Organization Users</Heading>
        </HStack>
        <Divider mb={4} mt={2} />
        <VStack w="100%" align="flex-start" spacing={4}>
          <TableContainer w="100%" borderWidth={1} borderRadius="lg">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Email</Th>
                  <Th>Firstname</Th>
                  <Th>Lastname</Th>
                  <Th>Role</Th>
                  <Th>Status</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {vm.currentOrganization?.members.map((member) => (
                  <OrganizationUserItem
                    key={`org-member-${member.id}`}
                    member={member}
                    currentUserRole={vm.currentUserRole}
                  />
                ))}
                {vm.currentOrganization?.invitations.map((invitation) => (
                  <Tr key={`invited-member-${invitation.email}`}>
                    <Td>{invitation.email}</Td>
                    <Td />
                    <Td />
                    <Td />
                    <Td>
                      <Badge colorScheme="orange">Invited</Badge>
                    </Td>
                    <Td />
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <HStack>
            <Button onClick={onOpen} colorScheme="blue" leftIcon={<AddIcon />}>
              Invite User
            </Button>
          </HStack>
        </VStack>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
        <ModalOverlay />
        <ModalContent>
          <ValidatedForm onSubmit={invite}>
            <ModalHeader>Invite User</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ValidatedFormControl schema={yup.string().required('Please provide email')} value={vm.form.email}>
                <FormLabel>Email</FormLabel>
                <CInput autoFocus onValueChange={vm.setEmail} value={vm.form.email} />
              </ValidatedFormControl>
            </ModalBody>

            <ModalFooter>
              <HStack>
                <Button onClick={onClose}>Cancel</Button>
                <Button isLoading={vm.invite.isBusy} type="submit" colorScheme="blue">
                  Invite
                </Button>
              </HStack>
            </ModalFooter>
          </ValidatedForm>
        </ModalContent>
      </Modal>
    </>
  );
});
