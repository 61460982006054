import { HStack, Text, VStack } from '@chakra-ui/react';
import { CLink } from '@web/toolkit';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import { useCallback, useEffect, useState } from 'react';
import { useInstance } from '../../../domain/hook/useInstance';
import { WalletConnectService } from '../../../domain/service/WalletConnectService';

interface IProps {
  readonly chainId: number;
}

export function WalletButton(props: IProps) {
  const { isConnected, chainId } = useWeb3ModalAccount();
  const walletConnectService = useInstance(WalletConnectService);
  const [web3Modal, setWeb3Modal] = useState<Awaited<ReturnType<typeof walletConnectService.connect>> | null>(null);

  const connect = useCallback(async () => {
    const modal = await walletConnectService.connect(props.chainId);
    setWeb3Modal(modal);
  }, [walletConnectService, props.chainId]);

  useEffect(() => {
    void connect();
  }, [connect]);

  useEffect(() => {
    if (isConnected && chainId !== props.chainId) {
      void web3Modal?.disconnect();
    }
  }, [isConnected, chainId, props.chainId, web3Modal]);

  return isConnected ? (
    <HStack w="100%" justify="space-between">
      <w3m-button size="md" />
      {chainId === props.chainId ? <w3m-network-button /> : null}
    </HStack>
  ) : (
    <VStack w="100%" align="flex-start">
      <Text fontWeight={600}>To connect your wallet, follow these steps:</Text>
      <VStack align="flex-start">
        <Text>
          1. Download Kriptonio{' '}
          <CLink isExternal href="https://apps.apple.com/hr/app/kriptonio/id6444807361">
            iOS
          </CLink>{' '}
          or{' '}
          <CLink isExternal href="https://play.google.com/store/apps/details?id=com.kriptonio.mobile.android">
            Android
          </CLink>{' '}
          mobile application
        </Text>
        <Text>2. Login to the mobile app with your Kriptonio account</Text>
        <Text>3. Go to the Wallets tab and create a wallet</Text>
        <Text>4. Click Connect Wallet button bellow, and select Kriptonio app</Text>
        <w3m-connect-button />
      </VStack>
    </VStack>
  );
}
