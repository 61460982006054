import type { SmartContractDetailResponse } from '@web/dto/api/smartContractDetailResponse';
import { action, computed, makeObservable, observable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { ViewModel } from '../../../../../../domain/ViewModel';
import { SmartContractApi } from '../../../../../../domain/api/SmartContractApi';
import { AsyncAction } from '../../../../../../domain/async/AsyncAction';
import { NotificationService } from '../../../../../../domain/service/NotificationService';
import { AnalyticsEvent } from '../../../../../../domain/service/analytics/AnalyticsEvent';
import { AnalyticsService } from '../../../../../../domain/service/analytics/AnalyticsService';
import { BlockchainStore } from '../../../../../../domain/store/BlockchainStore';
import { OrganizationStore } from '../../../../../../domain/store/OrganizationStore';
import { transient } from '../../../../../../inversify/decorator';
import { AppRoutes } from '../../../../../../router/Routes';

export interface SmartContractCloneMenuItemProps {
  smartContract: SmartContractDetailResponse;
}

@transient()
export class SmartContractCloneMenuItemVm extends ViewModel<SmartContractCloneMenuItemProps> {
  @observable
  public title = '';

  @observable
  public blockchainId: string | null = null;

  constructor(
    private readonly smartContractApi: SmartContractApi,
    private readonly analytics: AnalyticsService,
    private readonly notification: NotificationService,
    private readonly blockchainStore: BlockchainStore,
    private readonly organizationStore: OrganizationStore
  ) {
    super();
    makeObservable(this);
  }

  @action
  public setTitle = (title: string) => {
    this.title = title;
  };

  @action
  public setBlockchainId = (blockchainId: string | null) => {
    this.blockchainId = blockchainId;
  };

  @computed
  public get blockchains() {
    return this.blockchainStore.blockchains.filter(
      (b) => b.features.includes('SmartContract') && b.evmVersion === this.props.smartContract.evmVersion
    );
  }

  @computed
  private get selectedBlockchain() {
    return this.blockchains.find((b) => b.id === this.blockchainId);
  }

  public clone = new AsyncAction(async () => {
    try {
      if (!this.selectedBlockchain) {
        this.notification.error('Blockchain not selected', 'Please select blockchain to clone smart contract');
        return;
      }

      const result = await this.smartContractApi.cloneSmartContract(this.props.smartContract.id, {
        chainId: this.selectedBlockchain.chainId,
        title: this.title,
      });

      if (result.ok) {
        this.analytics.track(AnalyticsEvent.SmartContractCloned);
        return this.navigate(
          generatePath(AppRoutes.smartContract.general, {
            id: result.data.id,
            slug: this.organizationStore.currentSlug,
          })
        );
      }

      this.notification.error('Clone error', result.error.stringify());
    } catch (e) {
      console.error(e);
      this.notification.error('Unexpected exception', 'Unexpected error while cloning smart contract');
    }
  });
}
