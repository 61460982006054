import { Box } from '@chakra-ui/react';
import { Colorize } from '../../../components/Colorize';

interface IProps {
  readonly abi: unknown[];
}

export function SmartContractAbi(props: IProps) {
  return (
    <Box maxH={500} overflow="auto">
      <Colorize language="json" text={JSON.stringify(props.abi, null, 2)} />
    </Box>
  );
}
