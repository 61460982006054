import { SmartContract } from '@kriptonio/sdk';
import { BlockchainResponse } from '@web/dto/api/blockchainResponse';
import type { ValidatedFormRef } from '@web/toolkit';
import { AbiConstructor } from 'abitype';
import { Eip1193Provider } from 'ethers';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import React from 'react';
import type { Abi, Hex } from 'viem';
import {
  BaseError,
  createPublicClient,
  createWalletClient,
  custom,
  getContractAddress,
  http,
  parseAbi,
  parseEventLogs,
} from 'viem';
import { ViewModel } from '../../../../domain/ViewModel';
import { BlockchainEndpointApi } from '../../../../domain/api/BlockchainEndpointApi';
import { SmartContractApi } from '../../../../domain/api/SmartContractApi';
import { AsyncAction } from '../../../../domain/async/AsyncAction';
import type { ApiErrorModel } from '../../../../domain/model/ApiErrorModel';
import { NotificationService } from '../../../../domain/service/NotificationService';
import { AnalyticsEvent } from '../../../../domain/service/analytics/AnalyticsEvent';
import { AnalyticsService } from '../../../../domain/service/analytics/AnalyticsService';
import { OrganizationStore } from '../../../../domain/store/OrganizationStore';
import { transient } from '../../../../inversify/decorator';
import { getChain } from '../../../../utils/chain';
import { CallParam } from '../../smart-contract/components/CallParams';

export interface SmartContractDeploymentProps {
  smartContract: SmartContract;
  blockchain: BlockchainResponse;
  onReloadSmartContract: () => void;
}

const createCallAbi = parseAbi(['event ContractCreation(address indexed newContract)']);

@transient()
export class SmartContractDeploymentVm extends ViewModel<SmartContractDeploymentProps> {
  public form: React.MutableRefObject<ValidatedFormRef | null> = React.createRef();

  @observable
  public constructorParams: CallParam[] = [];

  @observable
  public transactionHash: Hex | null = null;

  @observable
  public loadingText = '';

  @observable
  public rpcUrl: string | null = null;

  constructor(
    private readonly analytics: AnalyticsService,
    private readonly notification: NotificationService,
    private readonly smartContractApi: SmartContractApi,
    private readonly organizationStore: OrganizationStore,
    private readonly blockchainEndpointApi: BlockchainEndpointApi
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = async () => {
    try {
      this.parseConstructorParams();

      if (this.organizationStore.currentOrganization?.id) {
        const result = await this.blockchainEndpointApi.getOrCreate(
          this.organizationStore.currentOrganization.id,
          this.props.blockchain.chainId
        );

        if (result.ok) {
          runInAction(() => {
            this.rpcUrl = result.data.url;
          });
        }
      }

      await this.waitDeployment();
    } catch (e) {
      console.error('exception while initializing smart contract deployment', e);
    }
  };

  @computed
  public get needsUpgrade() {
    if (this.props.blockchain.testnet) {
      return false;
    }

    return !this.organizationStore.currentOrganization?.subscription.subscriptionLimit.canRunMainnetTransactions;
  }

  public deploySmartContract = new AsyncAction(
    async (walletProvider: Eip1193Provider | undefined): Promise<ApiErrorModel | undefined> => {
      try {
        if (!walletProvider) {
          this.notification.warn('Cannot deploy', 'Please connect wallet to deploy smart contract');
          return;
        }

        if (!this.props.smartContract) {
          this.notification.warn('Cannot deploy smart contract', 'Missing smart contract info');
          return;
        }

        if (!this.rpcUrl) {
          this.notification.warn('Cannot deploy smart contract', 'Missing rpc url');
          return;
        }

        const chain = getChain(this.props.blockchain.chainId);
        const wallet = createWalletClient({
          chain,
          transport: custom(walletProvider),
        });

        const addresses = await wallet.getAddresses();
        const hash = await wallet.deployContract({
          account: addresses[0],
          abi: this.props.smartContract.abi,
          args: this.constructorParams.map((p) => p.value),
          bytecode: this.props.smartContract.bin as Hex,
        });

        runInAction(() => {
          this.transactionHash = hash;
          this.loadingText = 'Waiting for transaction to be mined';
        });

        const publicClient = createPublicClient({
          chain,
          transport: http(this.rpcUrl),
        });

        const receipt = await publicClient.waitForTransactionReceipt({ hash });
        runInAction(() => {
          this.loadingText = 'Finding contract address';
        });

        const logs = parseEventLogs({
          abi: createCallAbi,
          eventName: 'ContractCreation',
          logs: receipt.logs,
        });

        let address = '';

        // if contract is not deployed by contract factory, eg: deployed by EOA, we won't have any logs
        if (logs.length > 0) {
          address = logs[0].args.newContract;
        } else {
          const transaction = await publicClient.getTransaction({
            hash: receipt.transactionHash,
          });

          address = getContractAddress({
            from: addresses[0],
            nonce: BigInt(transaction.nonce),
          });
        }

        runInAction(() => {
          this.loadingText = 'Saving deployment';
        });
        const result = await this.smartContractApi.createDeployment(this.props.smartContract.id, {
          deployer: addresses[0],
          address,
          transactionHash: receipt.transactionHash,
        });

        if (result.ok) {
          this.analytics.track(AnalyticsEvent.SmartContractDeployed);
          runInAction(() => {
            this.props.smartContract.deployment = {
              id: result.data.id,
              address: result.data.address as Hex,
              deployer: result.data.deployer as Hex,
              transaction: result.data.transaction,
            };
          });

          await this.waitDeployment();
          return;
        }

        this.notification.error('Error', result.error.stringify());
      } catch (e) {
        console.error('exception while deploying contract', e);

        if (e instanceof BaseError) {
          this.notification.error('Error while deploying smart contract', e.shortMessage);
          return;
        }

        this.notification.error('Unexpected exception', 'Unexpected error while deploying smart contract');
      }
    }
  );

  private waitDeployment = async () => {
    const smartContract = await this.organizationStore.sdk.smartContract.get({
      id: this.props.smartContract.id,
    });

    if (smartContract.deployment) {
      const success = await smartContract.deployed();
      this.processDeploymentResult(success);
    }
  };

  public deleteDeployment = new AsyncAction(async (): Promise<ApiErrorModel | undefined> => {
    try {
      if (!this.props.smartContract) {
        this.notification.warn('Cannot delete smart contract deployment', 'Missing smart contract info');
        return;
      }

      if (!this.props.smartContract.deployment) {
        this.notification.warn('Error', 'Smart contract not deployed yet');
        return;
      }

      const result = await this.smartContractApi.deleteDeployment(this.props.smartContract.id);

      if (result.ok) {
        this.analytics.track(AnalyticsEvent.SmartContractDeploymentDeleted);

        this.props.onReloadSmartContract();
        return;
      }

      this.notification.error('Error', result.error.stringify());
      return result.error;
    } catch (e) {
      console.error('exception while deleting smart contract', e);
      this.notification.error('Unexpected exception', 'Unexpected error while deleting smart contract deployment');
    }
  });

  @action
  public parseConstructorParams() {
    const abi = (this.props.smartContract?.abi ?? []) as Abi;
    const constructor = abi.find((a: { type: string }) => a.type === 'constructor') as AbiConstructor;

    this.constructorParams = constructor?.inputs.map((i) => new CallParam(i.name ?? '', i.type)) ?? [];
  }

  @action
  public processDeploymentResult = (successful: boolean) => {
    if (successful) {
      return this.props.onReloadSmartContract();
    }

    this.notification.error('Transaction failed!', 'Please contact us if you have questions');
  };
}
