import { Box, Button, FormControl, FormLabel, Heading, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { CInput, CSelect, ValidatedForm, ValidatedFormControl } from '@web/toolkit';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { NumericFormat } from 'react-number-format';
import * as yup from 'yup';
import { useVm } from '../../../../domain/hook/useVm';
import { stringifyObject } from '../../../../utils/json';
import { CtaButton } from '../../../components/CtaButton';
import { CallParams } from '../../smart-contract/components/CallParams';
import { SectionDivider } from '../SectionDivider';
import { TransactionAwait } from '../transaction-await/TransactionAwait';
import { WalletButton } from '../WalletButton';
import { SmartContractUiProps, SmartContractUiVm } from './SmartContractUiVm';

export const SmartContractUi = observer(function SmartContractUi(props: SmartContractUiProps) {
  const vm = useVm(SmartContractUiVm, props);
  const { walletProvider } = useWeb3ModalProvider();
  const { isConnected } = useWeb3ModalAccount();

  const submit = useCallback(() => {
    if (vm.isReadFunction) {
      return vm.readFunction.run();
    }

    return vm.writeFunction.run(walletProvider);
  }, [vm, walletProvider]);

  return (
    <Box maxW={700} pb={10} w="100%">
      <HStack justify="space-between" w="100%">
        <Heading size="lg">UI Interaction</Heading>
      </HStack>
      <SectionDivider />
      <ValidatedForm onSubmit={submit} ref={vm.formRef}>
        <VStack align="flex-start" spacing={3}>
          <ValidatedFormControl
            schema={yup.string().required('Please select smart contract function')}
            value={vm.selectedFunction?.name}
            isRequired
          >
            <FormLabel>Smart Contract Function</FormLabel>
            <CSelect onValueChange={vm.setFunction} value={vm.selectedFunction?.name}>
              <option key="smart-contract-empty" value={undefined}>
                -
              </option>
              {vm.functions.map((s, index) => (
                <option key={`sc-${s.name}-${index}`} value={s.name}>
                  {s.name}
                </option>
              ))}
            </CSelect>
          </ValidatedFormControl>
          {vm.functionParams && vm.functionParams.length > 0 ? (
            <Box w="100%" my={2}>
              <CallParams params={vm.functionParams} />
            </Box>
          ) : null}

          {vm.canTransferValue ? (
            <VStack w="100%" align="flex-start">
              <Text fontSize="lg" fontWeight="semibold">
                Transfer {props.blockchain.coin} Value
              </Text>
              <FormControl width="100%">
                <FormLabel display="flex">
                  <Text>{props.blockchain.coin} amount</Text>
                </FormLabel>
                <NumericFormat
                  customInput={CInput}
                  decimalScale={props.blockchain.coinDecimals}
                  placeholder="0"
                  onValueChange={(value) => vm.setWeiFromCoinValue(value.floatValue)}
                  thousandSeparator
                />
              </FormControl>
            </VStack>
          ) : null}
          <Tooltip
            label={
              !props.smartContract.deployment
                ? "Before you can run functions of a smart contract, it's necessary to deploy the smart contract"
                : !isConnected && !vm.isReadFunction
                  ? 'Please connect your wallet run functions of a smart contract'
                  : undefined
            }
          >
            <Box w="100%">
              {vm.isReadFunction ? (
                <Button
                  colorScheme="blue"
                  isDisabled={!props.smartContract.deployment || vm.readFunction.isBusy}
                  isLoading={vm.readFunction.isBusy}
                  type="submit"
                  w="100%"
                >
                  Read
                </Button>
              ) : (
                <CtaButton
                  w="100%"
                  type="submit"
                  isDisabled={!vm.selectedFunction || !isConnected}
                  isLoading={vm.writeFunction.isBusy}
                  text="Write"
                />
              )}
            </Box>
          </Tooltip>
          {vm.transactionHash != null && props.blockchain && vm.rpcUrl ? (
            <TransactionAwait blockchain={props.blockchain} rpcUrl={vm.rpcUrl} hash={vm.transactionHash} />
          ) : null}
          {vm.rawResult != null ? (
            <>
              <Heading size="md">Result</Heading>
              <Box bgColor="gray.800" borderRadius="lg" p={2} w="100%">
                <Text color="white">{stringifyObject(vm.rawResult, 2)}</Text>
              </Box>
            </>
          ) : null}
          <WalletButton chainId={props.blockchain.chainId} />
        </VStack>
      </ValidatedForm>
    </Box>
  );
});
