import type { BlockchainResponse } from '@web/dto/api/blockchainResponse';
import { singleton } from '../../inversify/decorator';
import { AjaxService, IHttpResponse } from '../service/AjaxService';
import { Api } from './Api';

@singleton()
export class BlockchainApi extends Api {
  constructor(private readonly ajax: AjaxService) {
    super();
  }

  public getBlockchains = (): Promise<IHttpResponse<BlockchainResponse[]>> => {
    return this.ajax.get('v1/blockchains');
  };
}
