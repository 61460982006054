import { Alert, AlertIcon, Box, HStack, Heading, SimpleGrid, Spinner, Text } from '@chakra-ui/react';
import { faCode, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { BlockchainResponse } from '@web/dto/api/blockchainResponse';
import { formatEther } from 'viem';
import { useGetPaymasterEndpoint } from '../../../../domain/api/PaymasterEndpointApi';
import { SectionDivider } from '../../../blockchain/components/SectionDivider';
import { Stat } from '../../../dashboard/components/Stat';
import { LastSponsoredTransactions } from './last-sponsored-transactions/LastSponsoredTransactions';

interface IProps {
  readonly paymasterEndpointId: string;
  readonly smartContractId?: string;
  readonly blockchain: BlockchainResponse;
}

export function PaymasterStats(props: IProps) {
  const {
    data: paymasterEndpoint,
    isLoading,
    error,
  } = useGetPaymasterEndpoint(props?.paymasterEndpointId ?? '', props.smartContractId);

  return (
    <>
      {isLoading ? <Spinner /> : null}
      {error ? (
        <Alert status="error">
          <AlertIcon />
          <Text>{error?.response?.data?.code ?? 'An error occurred while fetching the paymaster stats.'}</Text>
        </Alert>
      ) : null}
      {paymasterEndpoint ? (
        <Box>
          <Heading marginTop={6} size="md">
            Stats
          </Heading>
          <SectionDivider />
          <SimpleGrid w="100%" columns={2} spacing={2}>
            <Stat
              icon={faCode}
              number={paymasterEndpoint?.sponsorshipCount}
              title="Sponsored Transactions this Month"
            />
            <Stat
              icon={faMoneyBill}
              number={formatEther(BigInt(paymasterEndpoint?.sponsorshipWei) ?? 0)}
              title={`Sponsored ${props.blockchain?.coin} this Month`}
            />
          </SimpleGrid>
          <HStack marginTop={5}>
            <Heading marginTop={6} size="md">
              Last Sponsored Transactions
            </Heading>
          </HStack>
          <SectionDivider />
          <LastSponsoredTransactions
            blockchain={props.blockchain}
            transactions={paymasterEndpoint?.lastSponsoredTransactions ?? []}
          />
        </Box>
      ) : null}
    </>
  );
}
