import { Button, Text, VStack } from '@chakra-ui/react';

import { CheckIcon } from '@chakra-ui/icons';
import { Divider, Flex, HStack } from '@chakra-ui/react';
import { ApiErrorCode } from '@web/dto/api/apiErrorCode';
import { SubscriptionPlanResponse } from '@web/dto/api/subscriptionPlanResponse';
import { generatePath, useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { SubscriptionApi } from '../../../../domain/api/SubscriptionApi';
import { useInstance } from '../../../../domain/hook/useInstance';
import { NotificationService } from '../../../../domain/service/NotificationService';
import { AnalyticsEvent } from '../../../../domain/service/analytics/AnalyticsEvent';
import { AnalyticsService } from '../../../../domain/service/analytics/AnalyticsService';
import { OrganizationStore } from '../../../../domain/store/OrganizationStore';
import { AppRoutes } from '../../../../router/Routes';

interface IProps {
  readonly title: string;
  readonly plan: SubscriptionPlanResponse;
  readonly activePlanTypeId: string | undefined;
  readonly price?: number;
  readonly features: string[];
  readonly billingRequired: boolean;
  readonly organizationId: string;
  readonly welcome: boolean;
}

export function SubscriptionListItem(props: IProps) {
  const analytics = useInstance(AnalyticsService);
  const subscriptionApi = useInstance(SubscriptionApi);
  const notification = useInstance(NotificationService);
  const organizationStore = useInstance(OrganizationStore);
  const navigate = useNavigate();
  const currentPlan = !props.welcome && props.plan.id === props.activePlanTypeId;

  const [urlState, selectSubscription] = useAsyncFn(async () => {
    if (!props.plan.isFree) {
      analytics.track(AnalyticsEvent.PurchaseStarted);
    }

    if (props.welcome && props.plan.isFree) {
      return navigate(AppRoutes.dashboard);
    }

    const result = await subscriptionApi.changeOrganizationSubscription(props.organizationId, props.plan.id);
    if (result.ok) {
      // if url is returned, we have to navigate to stripe checkout
      if (result.data) {
        window.location.href = result.data;
      } else {
        void organizationStore.load();
        return navigate({
          pathname: generatePath(AppRoutes.subscription.successUpdate, {
            slug: organizationStore.currentOrganization?.slug,
          }),
          search: `?free_downgraded=${props.plan.isFree}`,
        });
      }

      return;
    }

    if (result.error.code === ApiErrorCode.Forbidden) {
      return notification.error('Error', "Looks like you don't have a rights to change subscription.");
    }

    return notification.error(
      'Error',
      'Error while preparing subscription. Please try different subscription or try again later.'
    );
  }, [subscriptionApi, props.plan, props.organizationId, props.welcome, navigate, analytics, notification]);

  return (
    <VStack
      w="100%"
      boxShadow="17.3316px 20.798px 81.4587px rgba(0, 0, 0, 0.05), inset 0px 5.19949px 0px #9EDCFF"
      background="#fff"
      bgColor="#fff"
      borderWidth={1}
      borderRadius="lg"
      px={6}
      py={6}
      spacing={4}
      align="flex-start"
    >
      <VStack align="flex-start" w="100%">
        <HStack justify="space-between" align="flex-end" w="100%">
          <Text color="gray.900" fontSize="2xl" fontWeight={700}>
            {props.title}
          </Text>
          {props.plan.mostPopular ? (
            <HStack bgColor="blue.400" px={3} py={1} borderRadius="md">
              <Text color="white" fontWeight="bold">
                Most Popular
              </Text>
            </HStack>
          ) : null}
          {props.plan.bestValue ? (
            <HStack bgColor="orange.400" px={3} py={1} borderRadius="md">
              <Text color="white" fontWeight="bold">
                Best Value
              </Text>
            </HStack>
          ) : null}
        </HStack>
        <HStack justify="space-between" align="center" w="100%">
          {props.price === 0 && (
            <Text color="gray.900" fontSize="3xl" fontWeight={700}>
              0$
            </Text>
          )}
          {props.price != null && props.price > 0 && (
            <Text color="gray.900" fontSize="3xl" fontWeight={700}>
              <Text as="span">${props.price}</Text>
              <Text fontSize="2xl" as="span">
                /mo
              </Text>
            </Text>
          )}
          {currentPlan ? (
            <Text
              color="blue.500"
              textAlign="center"
              fontWeight={500}
              borderRadius="md"
              borderWidth={1}
              borderColor="blue.500"
              px={2}
            >
              Current Plan
            </Text>
          ) : null}
        </HStack>
        <Text as="span" fontSize="small" color={props.billingRequired ? 'green.500' : 'gray.900'}>
          {props.billingRequired ? 'Billing required' : 'No billing required'}
        </Text>
      </VStack>
      <Divider />
      <VStack h="100%" align="flex-start">
        {props.features.map((feature) => (
          <HStack key={`feature-${feature}`}>
            <Flex
              flexShrink={0}
              bg="rgba(82, 67, 194, 0.103693)"
              borderRadius="50%"
              justify="center"
              align="center"
              w={6}
              h={6}
            >
              <CheckIcon w={3} h={3} color="#5243C2" />
            </Flex>
            <Text color="gray.600">{feature}</Text>
          </HStack>
        ))}
      </VStack>
      <Divider />
      <Button
        onClick={selectSubscription}
        alignSelf="center"
        isDisabled={props.plan.id === props.activePlanTypeId}
        isLoading={urlState.loading}
        px={12}
        py={4}
        _hover={{}}
        _active={{}}
        background="linear-gradient(0deg, #4A94FF, #4A94FF), #F496D1"
        color="white"
        fontWeight={700}
      >
        Select
      </Button>
    </VStack>
  );
}
