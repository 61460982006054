import { Button, Link as ChakraLink, Container, Flex, HStack, Heading, Spinner, Text, VStack } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import { useGetPaymasterEndpoints } from '../../../domain/api/PaymasterEndpointApi';
import { useCurrentSlug } from '../../../domain/hook/useCurrentSlug';
import { useInstance } from '../../../domain/hook/useInstance';
import { OrganizationStore } from '../../../domain/store/OrganizationStore';
import { AppRoutes } from '../../../router/Routes';
import { CtaButton } from '../../components/CtaButton';
import { SegmentedControl } from '../../components/SegmentedControl';
import { PaymasterEndpointListItem } from './components/PaymasterEndpointListItem';

export function PaymasterEndpointListRoute() {
  useTitle('Paymasters | kriptonio');
  const slug = useCurrentSlug();
  const organizationStore = useInstance(OrganizationStore);
  const { isValidating, data: allData } = useGetPaymasterEndpoints(organizationStore.currentOrganization?.id ?? '');
  const navigate = useNavigate();
  const [showing, setShowing] = useState<'custom' | 'managed'>('custom');

  const toSponsorshipPolicy = useCallback(() => {
    navigate(
      generatePath(AppRoutes.paymaster.policy, {
        slug,
      })
    );
  }, [navigate, slug]);

  const toNewPaymasterEndpoint = useCallback(() => {
    navigate(
      generatePath(AppRoutes.paymaster.new, {
        slug,
      })
    );
  }, [navigate, slug]);

  const filteredData = useMemo(() => {
    return allData?.filter((endpoint) => endpoint.managed === (showing === 'managed'));
  }, [showing, allData]);

  return (
    <Container maxW="container.xl" pb={6}>
      <HStack justify="space-between" align="center" marginY={6}>
        <VStack w="100%" align="flex-start">
          <Heading>Paymasters</Heading>
          <SegmentedControl
            buttons={[
              {
                text: 'My Paymasters',
                active: showing === 'custom',
                onPress: () => setShowing('custom'),
              },
              {
                text: 'Wallet Paymasters',
                active: showing === 'managed',
                onPress: () => setShowing('managed'),
              },
            ]}
          />
        </VStack>
        <VStack>
          {showing === 'custom' && (
            <CtaButton
              onClick={toNewPaymasterEndpoint}
              text="New Paymaster"
              isLoading={isValidating}
              upgradeText="Upgrade for more paymasters"
              upgrade={
                !isValidating &&
                (organizationStore.currentOrganization?.subscription?.subscriptionLimit?.paymasters ?? 0) <=
                  (filteredData?.length ?? 0)
              }
            />
          )}
          <Button onClick={toSponsorshipPolicy} flexShrink={0}>
            Sponsorship Policy
          </Button>
        </VStack>
      </HStack>
      <VStack spacing={4}>
        {isValidating ? (
          <Spinner />
        ) : !filteredData?.length ? (
          <Flex
            alignItems="center"
            borderRadius="lg"
            borderWidth={1}
            h={20}
            justifyContent="center"
            p={5}
            shadow="md"
            w="100%"
          >
            {showing === 'custom' ? (
              <Text textAlign="center">
                Currently you don't have any paymasters. Click{' '}
                <ChakraLink
                  as={Link}
                  to={generatePath(AppRoutes.paymaster.new, {
                    slug,
                  })}
                >
                  here
                </ChakraLink>{' '}
                to create first one.
              </Text>
            ) : (
              <Text textAlign="center">
                Currently you don't have any paymasters. Wallet paymasters will be shown here once you create and
                interact with the wallets.
              </Text>
            )}
          </Flex>
        ) : (
          filteredData.map((paymaster) => (
            <PaymasterEndpointListItem key={`paymaster-endpoint-item-${paymaster.id}`} paymasterEndpoint={paymaster} />
          ))
        )}
      </VStack>
    </Container>
  );
}
