import type { UpdateUserBody } from '@web/dto/api/updateUserBody';
import type { UserResponse } from '@web/dto/api/userResponse';
import { singleton } from '../../inversify/decorator';
import { AjaxService, IHttpResponse } from '../service/AjaxService';
import { Api } from './Api';

@singleton()
export class UserApi extends Api {
  constructor(private readonly ajax: AjaxService) {
    super();
  }

  public updateUser = (data: UpdateUserBody): Promise<IHttpResponse<UserResponse>> => {
    return this.ajax.put('v1/users', { data });
  };
}
