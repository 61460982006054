import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { faFile, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useEffectOnce } from 'react-use';
import { useInstance } from '../../../../../../../domain/hook/useInstance';
import { NotificationService } from '../../../../../../../domain/service/NotificationService';
import { CodeEditor } from './CodeEditor';

interface IProps {
  readonly file: File | null;
  readonly onFile: (file: File | null) => void;
}

export function SoliditySourceCode(props: IProps) {
  const notification = useInstance(NotificationService);
  const [code, setCode] = useState(`// SPDX-License-Identifier: MIT
contract ExampleContract {
  function sayHello() public pure returns (string memory) {
    return "Hello There!";
  }
}`);

  const onDrop = useCallback(
    (files: File[]) => {
      if (files?.length) {
        const file = files[0];
        if (!file.name.toLowerCase().endsWith('.sol')) {
          return notification.warn('Invalid file detected', 'Currently we support only files with .sol extension');
        }

        const maxFileSizeMb = 20;
        if (file.size / 1024 / 1024 > maxFileSizeMb) {
          return notification.warn('File too big', `Max file size is ${maxFileSizeMb}mb`);
        }

        props.onFile(file);
      }
    },
    [notification, props]
  );

  const onBlur = useCallback(() => {
    const blob = new Blob([code], { type: 'text/plain' });
    const file = new File([blob], 'Contract.sol', { type: 'text/plain' });
    props.onFile(file);
  }, [code, props]);

  useEffectOnce(() => {
    onBlur();
  });

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxFiles: 1,
    onDrop,
  });

  const reset = useCallback(() => {
    props.onFile(null);
    setCode('');
  }, [props]);

  return (
    <Accordion defaultIndex={0} onChange={reset}>
      <AccordionItem borderRadius="lg" borderWidth="1px" mb={2}>
        <AccordionButton>
          <Box as="span" flex="1" textAlign="left">
            Type Solidity Source Code
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4} onBlur={onBlur}>
          <CodeEditor code={code} onCode={setCode} />
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem borderRadius="lg" borderWidth="1px" mb={2}>
        <AccordionButton>
          <Box as="span" flex="1" textAlign="left">
            Upload Solidity Source Code
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <VStack
            align="center"
            borderRadius="lg"
            borderStyle="dotted"
            borderWidth={2}
            cursor="pointer"
            flexDir="column"
            justify="center"
            py={10}
            {...getRootProps()}
          >
            {props.file ? (
              <HStack>
                <FontAwesomeIcon color="#A0AEC0" icon={faFile} size="lg" />
                <Text>{props.file.name}</Text>
              </HStack>
            ) : (
              <>
                <FontAwesomeIcon color="#A0AEC0" icon={faUpload} size="3x" />
                <Text fontWeight="semibold">Drop your solidity source code here, or browse</Text>
                <Text color="gray.400" fontSize="sm">
                  Supports: .sol files
                </Text>
              </>
            )}
            <input {...getInputProps()} />
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
