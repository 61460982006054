import { makeObservable } from 'mobx';
import { OrganizationApi } from '../../../../../domain/api/OrganizationApi';
import { AsyncAction } from '../../../../../domain/async/AsyncAction';
import { NotificationService } from '../../../../../domain/service/NotificationService';
import { OrganizationStore } from '../../../../../domain/store/OrganizationStore';
import { ViewModel } from '../../../../../domain/ViewModel';
import { transient } from '../../../../../inversify/decorator';

@transient()
export class OrganizationUserItemVm extends ViewModel {
  constructor(
    private readonly organizationStore: OrganizationStore,
    private readonly organizationApi: OrganizationApi,
    private readonly notification: NotificationService
  ) {
    super();
    makeObservable(this);
  }

  public removeUser = new AsyncAction(async (userId: string) => {
    try {
      const result = await this.organizationApi.removeUser(
        this.organizationStore.currentOrganization?.id ?? '',
        userId
      );
      if (result.ok) {
        void this.organizationStore.load();
        return this.notification.success('Success', 'User removed from organization');
      }

      this.notification.error('Error', `Error while removing user. ${result.error.code}`);
    } catch (e) {
      console.error(e);
      this.notification.error('Exception', 'Exception while removing user.');
    }
  });
}
