import { ApiErrorCode } from '@web/dto/api/apiErrorCode';
import type { ApiErrorResponse } from '@web/dto/api/apiErrorResponse';
import type { ExternalApiServiceError } from '@web/dto/api/externalApiServiceError';

export class ApiErrorModel {
  public message: string | null = null;

  public code: ApiErrorCode | null = null;

  private cause: ExternalApiServiceError | null = null;

  public stringify = (): string => {
    const parts: string[] = [];

    if (this.code) {
      parts.push(`[${this.code}]`);
    }

    if (this.message) {
      parts.push(this.message);
    }

    const rootCause = this.getRootCause(this.cause);
    if (rootCause) {
      parts.push('caused by');

      if (rootCause.code) {
        parts.push(`[${rootCause.code}]`);
      }

      if (rootCause.message) {
        parts.push(rootCause.message);
      }
    }

    return parts.join(' ');
  };

  public get rootCause(): ExternalApiServiceError | null {
    return this.getRootCause(this.cause);
  }

  private getRootCause = (error: ExternalApiServiceError | null): ExternalApiServiceError | null => {
    if (!error) {
      return null;
    }

    if (error.cause?.code != null || error.cause?.message != null) {
      return this.getRootCause(error.cause);
    }

    return error;
  };

  public static from = (dto: ApiErrorResponse) => {
    const model = new ApiErrorModel();

    model.message = dto.message ?? null;
    model.code = dto.code;
    model.cause = dto.cause ?? null;

    return model;
  };
}
