export enum AnalyticsEvent {
  PurchaseStarted = 'purchase_started',
  PurcaseCompleted = 'purchase_completed',
  LoginStarted = 'login_started',
  LoginCompleted = 'login_completed',
  RegisterStarted = 'register_started',
  RegisterCompleted = 'register_completed',
  TokenCreated = 'token_created',
  BlockchainEndpointCreated = 'blockchain_endpoint_created',
  PaymasterEndpointCreated = 'paymaster_endpoint_created',
  WalletCreated = 'wallet_created',
  SmartContractCreated = 'smart_contract_created',
  NftCollectionCreated = 'nft_collection_created',
  TokenDeployed = 'token_deployed',
  NftCollectionDeployed = 'nft_collection_deployed',
  SmartContractDeployed = 'smart_contract_deployed',
  SmartContractDeploymentDeleted = 'smart_contract_deployment_deleted',
  LiquidityAdded = 'liquidity_added',
  TokenDeleted = 'token_deleted',
  SmartContractDeleted = 'smart_contract_deleted',
  SmartContractCloned = 'smart_contract_cloned',
  WalletDeleted = 'wallet_deleted',
}
