import { Feature } from '@web/dto/api/feature';
import type { CreatePaymasterEndpointRequest } from '@web/dto/paymaster/createPaymasterEndpointRequest';
import { PaymasterEndpointResponse } from '@web/dto/paymaster/paymasterEndpointResponse';
import { action, computed, makeObservable, observable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { ViewModel } from '../../../domain/ViewModel';
import { PaymasterEndpointApi } from '../../../domain/api/PaymasterEndpointApi';
import { AsyncAction } from '../../../domain/async/AsyncAction';
import { NotificationService } from '../../../domain/service/NotificationService';
import { AnalyticsEvent } from '../../../domain/service/analytics/AnalyticsEvent';
import { AnalyticsService } from '../../../domain/service/analytics/AnalyticsService';
import { BlockchainStore } from '../../../domain/store/BlockchainStore';
import { OrganizationStore } from '../../../domain/store/OrganizationStore';
import { transient } from '../../../inversify/decorator';
import { AppRoutes } from '../../../router/Routes';

@transient()
export class NewPaymasterEndpointRouteVm extends ViewModel {
  @observable
  public form: CreatePaymasterEndpointRequest = {
    title: '',
    chainId: null!,
  };

  constructor(
    private readonly notificationService: NotificationService,
    private readonly organizationStore: OrganizationStore,
    private readonly analytics: AnalyticsService,
    private readonly paymasterEndpointApi: PaymasterEndpointApi,
    private readonly blockchainStore: BlockchainStore
  ) {
    super();
    makeObservable(this);
  }

  @action
  public setTitle = (value: string) => {
    this.form.title = value;
  };

  @computed
  public get blockchains() {
    return this.blockchainStore.blockchains.filter((b) => {
      return b.features.includes(Feature.VerifyingPaymasterFiat);
    });
  }

  @action
  public setBlockchain = (blockchainId: string | null) => {
    const blockchain = this.blockchains.find((b) => b.id === blockchainId);
    if (blockchain) {
      this.form.chainId = blockchain.chainId;
    }
  };

  public createPaymasterEndpoint = new AsyncAction(async () => {
    try {
      const result = await this.paymasterEndpointApi.createPaymasterEndpoint(
        this.organizationStore.currentOrganization?.id ?? '',
        this.form
      );

      if (result.ok) {
        this.analytics.track(AnalyticsEvent.PaymasterEndpointCreated);
        return this.showDetails(result.data);
      }

      this.notificationService.warn('Error while creating paymaster endpoint', result.error.stringify());
    } catch (e) {
      console.error(e);
      this.notificationService.error(
        'Unexpected Error',
        'Unexpected error while creating paymaster endpoint. Please check provided data.'
      );
    }
  });

  public showDetails = (paymasterEndpoint: PaymasterEndpointResponse) => {
    this.navigate(
      generatePath(AppRoutes.paymaster.general, { id: paymasterEndpoint.id, slug: this.organizationStore.currentSlug })
    );
  };
}
