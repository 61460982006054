import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Spinner,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import type React from 'react';
import { Navigate, generatePath, useParams } from 'react-router-dom';
import { useInstance } from '../../domain/hook/useInstance';
import { useVm } from '../../domain/hook/useVm';
import { OrganizationStore } from '../../domain/store/OrganizationStore';
import { UiStore } from '../../domain/store/UiStore';
import { Sidebar } from '../../layout/Sidebar';
import { Navbar } from '../Navbar';
import { AppRoutes } from '../Routes';
import { AuthenticatedRouteVm } from './AuthenticatedRouteVm';

export const AuthenticatedRoute = observer(function AuthenticatedRoute(
  props: React.PropsWithChildren<{ basic?: boolean }>
) {
  const vm = useVm(AuthenticatedRouteVm);
  const sidebar = useDisclosure();
  const sidebarWidth = 72;
  const bgcolor = useColorModeValue('white', 'gray.700');
  const params = useParams<{ slug: string }>();
  const organizationStore = useInstance(OrganizationStore);
  const uiStore = useInstance(UiStore);

  if (vm.loading) {
    return (
      <Flex align="center" h="100vh" justify="center" w="100%">
        <Spinner height={33} width={33} />
      </Flex>
    );
  }

  if (!vm.authenticated) {
    uiStore.setInterruptedUrl(window.location.href);
    return <Navigate replace to="/auth/login" />;
  } else {
    uiStore.setInterruptedUrl(null);
  }

  if (!organizationStore.currentOrganization) {
    const slug = params.slug?.toLowerCase();
    organizationStore.selectDefault(slug);

    if (slug && slug !== organizationStore.currentSlug) {
      return (
        <Navigate
          to={generatePath(AppRoutes.dashboard, {
            slug: organizationStore.currentSlug,
          })}
        />
      );
    }

    if (!organizationStore.currentOrganization) {
      console.error(
        `organization cannot be resolved. slug: ${slug}. user: ${vm.session.session?.user.email}. org count: ${organizationStore.organizations.length}`
      );
    }
  }

  if (props.basic) {
    return (
      <Box bg={bgcolor} display="flex" minH="100vh">
        {props.children}
      </Box>
    );
  }

  return (
    <Box bg={bgcolor} display="flex" minH="100vh">
      <Sidebar sidebarWidth={`${sidebarWidth}`} />
      <Drawer isOpen={sidebar.isOpen} onClose={sidebar.onClose} placement="left">
        <DrawerOverlay />
        <DrawerContent>
          <Sidebar alwaysShow borderRightWidth={0} onItemSelected={sidebar.onClose} sidebarWidth="100%" />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: sidebarWidth }} w="100%">
        <Navbar onToggle={sidebar.onToggle} />
        {props.children}
      </Box>
    </Box>
  );
});
