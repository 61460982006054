/**
 * Kriptonio Private API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: private
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TransactionStatus = 'Pending' | 'Success' | 'Error';

export const TransactionStatus = {
    Pending: 'Pending' as TransactionStatus,
    Success: 'Success' as TransactionStatus,
    Error: 'Error' as TransactionStatus
};

