import { Checkbox, FormLabel, Input, Text, VStack } from '@chakra-ui/react';
import { ValidatedFormControl } from '@web/toolkit';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';

export class CallParam {
  @observable
  public name: string;

  @observable
  public type: string;

  @observable
  public value: string | number | boolean | undefined = undefined;

  @computed
  public get serializedValue(): string | number | boolean | undefined {
    if (this.type === 'string' || this.type === 'address') {
      return `'${this.value}'`;
    }

    return this.value;
  }

  constructor(name: string, type: string) {
    makeObservable(this);

    this.name = name;
    this.type = type;
  }

  @action
  public onChange = (value: number | string | boolean) => {
    this.value = value;
  };
}

interface IProps {
  params: CallParam[];
}

function isCheckable(value: unknown): value is boolean | undefined {
  return value === undefined || typeof value === 'boolean';
}

export const CallParams = observer(function CallParams(props: IProps) {
  return (
    <VStack w="100%" align="flex-start">
      <Text fontSize="lg" fontWeight="semibold">
        Function Params
      </Text>
      {props.params.map((param) => {
        return (
          <ValidatedFormControl
            key={`value-${param.name}`}
            schema={yup.string().required(`Please enter ${param.name}`)}
            value={param.value?.toString() ?? undefined}
            isRequired
            width="100%"
          >
            <FormLabel display="flex">
              <Text>
                {param.name} ({param.type})
              </Text>
            </FormLabel>
            {/* boolean parameter */}
            {param.type === 'bool' && isCheckable(param.value) && (
              <Checkbox isChecked={param.value ?? false} onChange={(e) => param.onChange(e.target.checked)} />
            )}
            {/* other param types */}
            {param.type.endsWith('[]') ? ( // arrays unsupported
              <Text color="red.500">
                Currently array parameters are not supported in smart contract UI interaction. You can send them via
                REST API.
              </Text>
            ) : param.type === 'tuple' ? ( // struct unsupported
              <Text color="red.500">
                Currently array parameters are not supported in smart contract UI interaction. You can send them via
                REST API.
              </Text>
            ) : (
              param.type !== 'bool' && (
                <Input
                  onChange={(e) => param.onChange(e.target.value)}
                  type="text"
                  value={(param.value as string | number) ?? ''}
                />
              )
            )}
          </ValidatedFormControl>
        );
      })}
    </VStack>
  );
});
