import { Center, SimpleGrid, Spinner, Text, VStack } from '@chakra-ui/react';
import { faCode, faCoins, faServer, faUsd } from '@fortawesome/free-solid-svg-icons';
import { generatePath, useNavigate } from 'react-router-dom';
import { useGetPaymasterUsage } from '../../../domain/api/PaymasterEndpointApi';
import {
  useGetEntityStats,
  useGetOrganizationEndpointsConsumption,
  useGetRpcStats,
} from '../../../domain/api/ReportApi';
import { useCurrentSlug } from '../../../domain/hook/useCurrentSlug';
import { useInstance } from '../../../domain/hook/useInstance';
import { OrganizationStore } from '../../../domain/store/OrganizationStore';
import { AppRoutes } from '../../../router/Routes';
import { formatNumber } from '../../../utils/number';
import { Stat } from './Stat';
import { UsagePercentage } from './UsagePercentage';

export function EntityStats() {
  const navigate = useNavigate();
  const organizationStore = useInstance(OrganizationStore);
  const slug = useCurrentSlug();

  const { data: rpcStats, isValidating: loadingRpcStats } = useGetRpcStats(
    organizationStore.currentOrganization?.id ?? ''
  );

  const { data: entityStats, isValidating: loadingEntityStats } = useGetEntityStats(
    organizationStore.currentOrganization?.id ?? ''
  );
  const { data: endpointConsumption, isValidating: loadingEndpointConsumption } =
    useGetOrganizationEndpointsConsumption(organizationStore.currentOrganization?.id ?? '');

  const { data: paymasterUsage, isValidating: loadingPaymasterUsage } = useGetPaymasterUsage(
    organizationStore.currentOrganization?.id ?? ''
  );

  const subscriptionLimit = organizationStore.currentOrganization?.subscription.subscriptionLimit;

  return (
    <VStack spacing={6}>
      <VStack w="100%" align="flex-start" spacing={3}>
        <SimpleGrid w="100%" columns={{ base: 1, lg: 2 }} spacing={4}>
          <VStack alignItems="flex-start" w="100%" minH={14}>
            {loadingEndpointConsumption ? (
              <Center minH={7}>
                <Spinner size="md" />
              </Center>
            ) : (
              <Text fontWeight="bold" fontSize="xl">
                Api Credits Usage: {formatNumber(endpointConsumption ?? 0, 2)} /{' '}
                {formatNumber(subscriptionLimit?.monthlyApiCredits ?? 0, 2)}
              </Text>
            )}
            <UsagePercentage current={endpointConsumption ?? 0} max={subscriptionLimit?.monthlyApiCredits ?? 0} />
          </VStack>
          <VStack alignItems="flex-start" w="100%" minH={14}>
            {loadingPaymasterUsage ? (
              <Center minH={7}>
                <Spinner size="md" />
              </Center>
            ) : (
              <Text fontWeight="bold" fontSize="xl">
                Sponsored Transactions: {paymasterUsage?.sponsoredTransactions} /{' '}
                {subscriptionLimit?.monthlySponsoredTransactions === -1
                  ? '∞'
                  : formatNumber(subscriptionLimit?.monthlySponsoredTransactions ?? 0, 2)}
              </Text>
            )}
            <UsagePercentage
              current={
                subscriptionLimit?.monthlySponsoredTransactions === -1 ? 0 : paymasterUsage?.sponsoredTransactions ?? 0
              }
              max={
                subscriptionLimit?.monthlyApiCredits === -1
                  ? Number.MAX_SAFE_INTEGER
                  : organizationStore.currentOrganization?.subscription.subscriptionLimit
                      .monthlySponsoredTransactions ?? 0
              }
            />
          </VStack>
        </SimpleGrid>
      </VStack>
      <SimpleGrid w="100%" columns={{ sm: 1, md: 2, lg: 4 }} spacing={2}>
        <Stat
          icon={faUsd}
          loading={loadingPaymasterUsage}
          number={paymasterUsage?.sponsoredUsd.toFixed(7)}
          title="USD Sponsored"
        />
        <Stat
          icon={faCode}
          loading={loadingEntityStats}
          number={entityStats?.totalSmartContracts}
          subNumber={subscriptionLimit?.smartContracts}
          onClick={() => navigate(generatePath(AppRoutes.smartContract.list, { slug }))}
          title="Smart Contracts"
        />
        <Stat
          icon={faServer}
          loading={loadingRpcStats}
          number={rpcStats?.totalRpcEndpoints}
          subNumber={subscriptionLimit?.endpoints}
          onClick={() => navigate(generatePath(AppRoutes.blockchainEndpoint.list, { slug }))}
          title="RPC Endpoints"
        />
        <Stat
          icon={faCoins}
          loading={loadingPaymasterUsage}
          number={paymasterUsage?.paymasters}
          subNumber={subscriptionLimit?.paymasters}
          onClick={() => navigate(generatePath(AppRoutes.paymaster.list, { slug }))}
          title="Paymasters"
        />
      </SimpleGrid>
    </VStack>
  );
}
