import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  HStack,
  PinInput,
  PinInputField,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { faApple, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGoogleLogin } from '@react-oauth/google';
import { CInput, CLink, ValidatedForm, ValidatedFormControl } from '@web/toolkit';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { appleAuthHelpers, useScript } from 'react-apple-signin-auth';
import { useTitle } from 'react-use';
import * as yup from 'yup';
import authBackground from '../../../assets/auth-background-gradient-blur.jpeg';
import { useInstance } from '../../../domain/hook/useInstance';
import { useVm } from '../../../domain/hook/useVm';
import { NotificationService } from '../../../domain/service/NotificationService';
import { env } from '../../../env';
import { AppRoutes } from '../../../router/Routes';
import { LoginRouteVm } from './LoginRouteVm';

export const LoginRoute = observer(function LoginContainer() {
  useTitle('Login | kriptonio');

  const vm = useVm(LoginRouteVm);
  const notification = useInstance(NotificationService);

  const login = useGoogleLogin({
    onSuccess: (result) => {
      runInAction(() => {
        vm.googleLoginData.accessToken = result.access_token;
        void vm.googleLogin.run();
      });
    },
    onError: (err) => {
      console.error(err);
      notification.error('Google login error', err.error ?? '');
    },
    flow: 'implicit',
  });

  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);

  return (
    <Flex align="center" bgImage={`url(${authBackground})`} bgSize="cover" height="100vh" w="100%">
      <Box maxW="lg" minW={{ md: '450px' }} mx="auto" px={6}>
        <Box bg={useColorModeValue('white', 'gray.700')} p={8} rounded="lg">
          {vm.twoFactorStep ? (
            <>
              <Heading fontSize="3xl" mb={3} textAlign="center">
                Two Factor Authentication
              </Heading>
              <Text textAlign="center">Please enter 6 digits code from you Two Factor Authentication app</Text>
              <HStack justifyContent="center" marginTop={5}>
                {!vm.login.isBusy ? (
                  <PinInput onComplete={vm.loginWithCode} otp type="number">
                    <PinInputField autoFocus />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                ) : (
                  <Spinner />
                )}
              </HStack>
            </>
          ) : (
            <>
              <Heading fontSize="3xl" mb={3} textAlign="center">
                Sign in to your account
              </Heading>
              <ValidatedForm onSubmit={vm.login.run}>
                <Stack spacing={4}>
                  <ValidatedFormControl
                    schema={yup.string().email('Please provide valid email').required('Please provide email')}
                    value={vm.loginData.email}
                  >
                    <FormLabel>Email address</FormLabel>
                    <CInput autoFocus onValueChange={vm.onEmailChange} type="email" value={vm.loginData.email} />
                  </ValidatedFormControl>
                  <ValidatedFormControl
                    schema={yup.string().required('Please provide password')}
                    value={vm.loginData.password}
                  >
                    <Flex justify="space-between">
                      <FormLabel>Password</FormLabel>
                      <CLink to={AppRoutes.auth.forgotPassword}>Forgot password?</CLink>
                    </Flex>
                    <CInput onValueChange={vm.onPasswordChange} type="password" value={vm.loginData.password} />
                  </ValidatedFormControl>
                  <Button colorScheme="blue" isLoading={vm.login.isBusy} type="submit">
                    Sign in
                  </Button>
                  <Button
                    colorScheme="red"
                    isDisabled={vm.googleLogin.isBusy || !vm.uiStore.googleScriptLoaded}
                    isLoading={vm.googleLogin.isBusy}
                    leftIcon={<FontAwesomeIcon cursor="pointer" icon={faGoogle} size="lg" width={20} />}
                    onClick={() => login()}
                  >
                    Sign in with Google
                  </Button>
                  <Button
                    colorScheme="gray"
                    isDisabled={vm.appleLogin.isBusy}
                    isLoading={vm.appleLogin.isBusy}
                    leftIcon={<FontAwesomeIcon cursor="pointer" icon={faApple} size="lg" width={20} />}
                    onClick={vm.appleLogin.run}
                  >
                    Sign in with Apple
                  </Button>
                  {env.isDev ? (
                    <Button colorScheme="green" isLoading={vm.login.isBusy} onClick={vm.devLogin}>
                      Dev Sign in
                    </Button>
                  ) : null}
                </Stack>
              </ValidatedForm>
              <VStack marginTop={5}>
                <Text textAlign="left">
                  You don't have account yet? <CLink to={AppRoutes.auth.register}>Register Now</CLink>
                </Text>
              </VStack>
            </>
          )}
        </Box>
      </Box>
    </Flex>
  );
});
