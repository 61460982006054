import { action, makeObservable, observable, runInAction } from 'mobx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncFunc = (...args: any) => Promise<unknown>;

export class AsyncAction<Func extends AsyncFunc> {
  @observable
  public isBusy = false;

  @observable
  public error: Error | null = null;

  public run: Func;

  constructor(work: Func) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.run = action((async (...args: any) => {
      try {
        runInAction(() => {
          this.isBusy = true;
          this.error = null;
        });

        return await work(...args);
      } catch (e) {
        runInAction(() => {
          this.error = e as Error;
        });

        throw e;
      } finally {
        runInAction(() => {
          this.isBusy = false;
        });
      }
    }) as Func);
    makeObservable(this);
  }
}
