import { KriptonioError } from '@kriptonio/sdk';
import { BaseError } from 'viem';

export function getRootCause(error: KriptonioError | BaseError): {
  code?: number;
  message: string;
} {
  if (error instanceof KriptonioError) {
    if (error.cause) {
      return getRootCause(error.cause);
    }

    return {
      code: error.code,
      message: error.message,
    };
  }

  return { message: error.details };
}

export function formatKriptonioError(error: KriptonioError) {
  const parts: string[] = [];

  if (error.code) {
    console.log('error.code', error.code);
    parts.push(error.code.toString());
  }

  if (error.message) {
    console.log('error.message', error.message);
    parts.push(error.message);
  }

  if (error.cause) {
    const rootCause = getRootCause(error.cause);
    if (rootCause.code || rootCause.message) {
      parts.push('caused by');

      if (rootCause.code) {
        parts.push(rootCause.code.toString());
      }

      if (rootCause.message) {
        parts.push(rootCause.message);
      }
    }
  }

  if (parts.length) {
    return parts.join(': ');
  }

  return 'RPC Error';
}
