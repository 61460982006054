import { PaymasterEndpointDetailResponse } from '@web/dto/paymaster/paymasterEndpointDetailResponse';
import { PaymasterEndpointResponse } from '@web/dto/paymaster/paymasterEndpointResponse';
import { useMemo } from 'react';
import { useInstance } from '../../../domain/hook/useInstance';
import { BlockchainStore } from '../../../domain/store/BlockchainStore';

export function usePaymasterTitle(paymaster: PaymasterEndpointResponse | PaymasterEndpointDetailResponse | null) {
  const blockchainStore = useInstance(BlockchainStore);

  const blockchain = useMemo(() => {
    if (!paymaster) {
      return null;
    }

    return blockchainStore.blockchains.find((b) => b.chainId === paymaster.paymaster.chainId);
  }, [paymaster, blockchainStore.blockchains]);

  return useMemo(() => {
    if (!paymaster) {
      return '';
    }

    if (!paymaster.managed) {
      return paymaster.title;
    }

    if (paymaster.wallet) {
      return `Paymaster of Wallet ${paymaster.wallet} for ${blockchain?.fqdn ?? paymaster.paymaster.chainId}`;
    }

    return paymaster.title;
  }, [paymaster, blockchain]);
}
