import type { UpdateOrganizationBody } from '@web/dto/api/updateOrganizationBody';
import { action, makeObservable, observable } from 'mobx';
import { OrganizationApi } from '../../../domain/api/OrganizationApi';
import { AsyncAction } from '../../../domain/async/AsyncAction';
import { NotificationService } from '../../../domain/service/NotificationService';
import { OrganizationStore } from '../../../domain/store/OrganizationStore';
import { ViewModel } from '../../../domain/ViewModel';
import { transient } from '../../../inversify/decorator';

@transient()
export class OrganizationSettingsRouteVm extends ViewModel {
  @observable
  public form: UpdateOrganizationBody = {
    name: '',
  };

  constructor(
    private readonly organizationApi: OrganizationApi,
    private readonly notification: NotificationService,
    private readonly organizationStore: OrganizationStore
  ) {
    super();
    makeObservable(this);
    this.form.name = organizationStore.currentOrganization?.name ?? '';
  }

  @action
  public onNameChange = (value: string) => {
    this.form.name = value;
  };

  public update = new AsyncAction(async () => {
    try {
      const result = await this.organizationApi.updateOrganization(
        this.organizationStore.currentOrganization?.id ?? '',
        this.form
      );

      if (result.ok) {
        void this.organizationStore.load();
        return this.notification.success('Success', 'Organization data updated');
      }

      this.notification.error('Error', `Error while updating organization. ${result.error.code}`);
    } catch (e) {
      console.error('exception while updating organization', e);
      this.notification.error('Exception', 'Exception while updating organization');
    }
  });
}
