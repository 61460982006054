import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type React from 'react';
import { Link, NavLink } from 'react-router-dom';

interface IProps {
  readonly route?: string;
  readonly href?: string;
  readonly icon: IconProp;
  readonly text: string;
  readonly onItemSelected?: () => void;
}

export function SidebarItem(props: React.PropsWithChildren<IProps>) {
  return (
    <Flex
      _activeLink={{
        bg: useColorModeValue('gray.700', 'gray.900'),
        color: useColorModeValue('white', 'gray.200'),
      }}
      _hover={{
        bg: useColorModeValue('gray.700', 'gray.900'),
        color: useColorModeValue('white', 'gray.200'),
      }}
      align="center"
      as={props.route ? NavLink : props.href ? Link : undefined}
      color={useColorModeValue('gray.500', 'gray.400')}
      cursor="pointer"
      display="flex"
      fontWeight="bold"
      onClick={props.onItemSelected}
      px={6}
      py={4}
      to={props.route ?? ''}
    >
      <FontAwesomeIcon icon={props.icon} size="lg" width={20} />
      <Text pl={2}>{props.text}</Text>
    </Flex>
  );
}
