import { action, makeObservable, observable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { OrganizationApi } from '../../../domain/api/OrganizationApi';
import { AsyncAction } from '../../../domain/async/AsyncAction';
import { NotificationService } from '../../../domain/service/NotificationService';
import { OrganizationStore } from '../../../domain/store/OrganizationStore';
import { ViewModel } from '../../../domain/ViewModel';
import { transient } from '../../../inversify/decorator';
import { AppRoutes } from '../../../router/Routes';

@transient()
export class OrganizationSelectorVm extends ViewModel {
  @observable
  public name = '';

  constructor(
    private readonly organizationStore: OrganizationStore,
    private readonly organizationApi: OrganizationApi,
    private readonly notification: NotificationService
  ) {
    super();
    makeObservable(this);
  }

  @action
  public setName = (value: string) => {
    this.name = value;
  };

  public createOrganization = new AsyncAction(async () => {
    try {
      const result = await this.organizationApi.createOrganization({
        name: this.name,
      });

      if (result.ok) {
        this.notification.success('Success', 'Organization created');
        this.organizationStore.pushOrganization(result.data);
        return this.navigate(
          generatePath(AppRoutes.dashboard, {
            slug: this.organizationStore.currentSlug,
          })
        );
      }

      this.notification.error('Error', `Error while creating organization. ${result.error.stringify()}`);
    } catch (e) {
      console.error(e);
      this.notification.error('Exception', 'Exception while creating organization');
    }
  });
}
