import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Link as ChakraLink,
  Divider,
  HStack,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import { OrganizationRole } from '@web/dto/api/organizationRole';
import { SubscriptionStatus } from '@web/dto/api/subscriptionStatus';
import { observer } from 'mobx-react-lite';
import { Link, generatePath } from 'react-router-dom';
import { useTitle } from 'react-use';
import { useGetCustomerPortalUrl } from '../../../domain/api/OrganizationApi';
import { useInstance } from '../../../domain/hook/useInstance';
import { OrganizationStore } from '../../../domain/store/OrganizationStore';
import { AppRoutes } from '../../../router/Routes';
import { SubscriptionCancelButton } from './components/SubscriptionCancelButton';

export const BillingSettingsRoute = observer(function BillingSettingsRoute() {
  useTitle('Billing | kriptonio');
  const organizationStore = useInstance(OrganizationStore);
  const { data } = useGetCustomerPortalUrl(organizationStore.currentOrganization?.id ?? '');

  return (
    <Box maxW={700} pb={10} w="100%">
      <HStack justify="space-between">
        <Heading size="lg">Billing</Heading>
      </HStack>
      <Divider mb={4} mt={2} />
      <VStack align="flex-start" spacing={4} maxW={500} w="100%">
        <VStack align="flex-start" px={6} py={4} borderRadius="lg" borderWidth={1} shadow="lg" w="100%">
          <Text fontSize="md">Active Subscription Plan</Text>
          <Text fontSize="xl" fontWeight="semibold">
            {organizationStore.currentOrganization?.subscription?.planType}
          </Text>

          {organizationStore.currentOrganization?.subscription.status === SubscriptionStatus.Cancelled && (
            <Alert status="warning">
              <AlertIcon />
              <Text>Plan is scheduled for cancellation at the end of the month.</Text>
            </Alert>
          )}
          <HStack w="100%" justify="space-between" pt="2">
            <Button
              as={Link}
              to={generatePath(AppRoutes.subscription.change, {
                slug: organizationStore.currentSlug,
              })}
              colorScheme="blue"
            >
              Change
            </Button>
            {!organizationStore.currentOrganization?.subscription.isFree &&
              organizationStore.currentOrganization?.subscription.status === SubscriptionStatus.Active && (
                <SubscriptionCancelButton />
              )}
          </HStack>
        </VStack>
        {!organizationStore.currentOrganization?.subscription.isFree &&
          organizationStore.currentRole === OrganizationRole.Owner && (
            <VStack align="flex-start" px={6} py={4} borderRadius="lg" borderWidth={1} shadow="lg" w="100%">
              <Text>Payment Methods & Invoices</Text>
              <ChakraLink target="_blank" href={data}>
                Open
              </ChakraLink>
            </VStack>
          )}
      </VStack>
    </Box>
  );
});
