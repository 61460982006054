import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormLabel,
  MenuItem,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import type { FocusableElement } from '@chakra-ui/utils';
import { CInput, ValidatedForm, ValidatedFormControl } from '@web/toolkit';
import { observer } from 'mobx-react-lite';
import { useCallback, useRef } from 'react';
import * as yup from 'yup';
import { useVm } from '../../../../../../domain/hook/useVm';
import { BlockchainNetworkSelect } from '../../../../endpoint/new/components/BlockchainNetworkSelect';
import { SmartContractCloneMenuItemProps, SmartContractCloneMenuItemVm } from './SmartContractCloneMenuItemVm';

export const SmartContractCloneMenuItem = observer(function SmartContractCloneMenuItem(
  props: SmartContractCloneMenuItemProps
) {
  const inputRef = useRef<FocusableElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const vm = useVm(SmartContractCloneMenuItemVm, props);

  const cloneSmartContract = useCallback(() => {
    void vm.clone.run().then(onClose);
  }, [vm, onClose]);

  return (
    <>
      <MenuItem onClick={onOpen}>Clone</MenuItem>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={inputRef} onClose={onClose} preserveScrollBarGap>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <ValidatedForm onSubmit={cloneSmartContract}>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Clone Smart Contract
              </AlertDialogHeader>

              <AlertDialogBody>
                <VStack spacing={4} width="100%">
                  <ValidatedFormControl
                    isRequired
                    schema={yup.string().required('Please provide smart contract title')}
                    value={vm.title}
                  >
                    <FormLabel>Title</FormLabel>
                    <CInput autoFocus onValueChange={vm.setTitle} type="text" value={vm.title} />
                  </ValidatedFormControl>
                  <BlockchainNetworkSelect blockchains={vm.blockchains} onBlockchainChange={vm.setBlockchainId} />
                </VStack>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button onClick={onClose}>Cancel</Button>
                <Button colorScheme="blue" isLoading={vm.clone.isBusy} ml={3} type="submit">
                  Clone
                </Button>
              </AlertDialogFooter>
            </ValidatedForm>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
});
