import { Flex, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import type { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { commify } from '../../../utils/strings';

interface IProps {
  readonly title: string;
  readonly number: number | undefined | string;
  readonly loading?: boolean;
  readonly subNumber?: number;
  readonly icon: IconDefinition;
  readonly onClick?: () => void;
}

export function Stat(props: IProps) {
  return (
    <HStack
      borderRadius="lg"
      borderWidth={1}
      boxShadow="lg"
      cursor={props.onClick ? 'pointer' : 'initial'}
      justify="space-between"
      onClick={props.onClick}
      px={6}
      py={3}
      spacing={4}
    >
      <VStack align="flex-start" spacing={0.5}>
        <Text color="gray.500" fontSize="md" fontWeight="500" whiteSpace="pre-line">
          {props.title}
        </Text>
        {props.loading ? (
          <Flex align="center" height="36px" justify="center">
            <Spinner />
          </Flex>
        ) : (
          <Text fontSize="2xl" fontWeight="bold">
            {commify(props.number ?? '')}
            {props.subNumber != null && (
              <Text as="span" color="gray.400" fontSize="md" fontWeight="semibold">
                {' '}
                / {props.subNumber}
              </Text>
            )}
          </Text>
        )}
      </VStack>
      <Flex align="center" bgColor="blue.400" borderRadius="xl" height="45px" justify="center" p={6} width="45px">
        <FontAwesomeIcon color="white" icon={props.icon} size="lg" width={40} />
      </Flex>
    </HStack>
  );
}
