import type { ForgotPasswordBody } from '@web/dto/api/forgotPasswordBody';
import { action, makeObservable, observable } from 'mobx';
import { AuthApi } from '../../../domain/api/AuthApi';
import { AsyncAction } from '../../../domain/async/AsyncAction';
import { NotificationService } from '../../../domain/service/NotificationService';
import { ViewModel } from '../../../domain/ViewModel';
import { transient } from '../../../inversify/decorator';
import { AppRoutes } from '../../../router/Routes';

@transient()
export class ForgotPasswordRouteVm extends ViewModel {
  @observable
  public form: ForgotPasswordBody = {
    email: '',
  };

  constructor(
    private readonly authApi: AuthApi,
    private readonly notification: NotificationService
  ) {
    super();
    makeObservable(this);
  }

  @action
  public onEmailChange = (email: string) => {
    this.form.email = email;
  };

  public forgotPassword = new AsyncAction(async () => {
    try {
      const result = await this.authApi.forgotPassword(this.form);
      if (result.ok) {
        this.notification.success('Success', 'Instructions sent to email');
        return this.navigate(AppRoutes.auth.login);
      }

      this.notification.error('Error', `Error while processing request. ${result.error.code}`);
    } catch (e) {
      console.error('exception while calling forgetPassword endpoint', e);
      this.notification.error('Exception', 'Exception while processing request. Please check provided data');
    }
  });
}
