import { singleton } from '../../inversify/decorator';

@singleton()
export class PasswordService {
  /**
   * Function to return validation error for password if any
   * @param password password to validate
   */
  public validatePassword = (password: string, minLen = 8) => {
    if (password.length < minLen) {
      return `Password should have at least ${minLen} characters`;
    }

    if (!password.match(/[a-z]+/)) {
      return 'Password should contain lowercase letters';
    }

    if (!password.match(/[A-Z]+/)) {
      return 'Password should contain uppercase letters';
    }

    if (!password.match(/[0-9]+/)) {
      return 'Password should contain numbers';
    }

    return null;
  };
}
