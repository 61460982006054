import {
  Alert,
  AlertIcon,
  Box,
  Center,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from 'chakra-react-select';
import { observer } from 'mobx-react-lite';
import { useVm } from '../../../../domain/hook/useVm';
import { parseEthersError } from '../../../../utils/ethers';
import { SectionDivider } from '../SectionDivider';
import { SmartContractEventListItem } from './components/SmartContractEventListItem';
import { SmartContractEventsProps, SmartContractEventsVm } from './SmartContractEventsVm';

export const SmartContractEvents = observer(function SmartContractEvents(props: SmartContractEventsProps) {
  const vm = useVm(SmartContractEventsVm, props);

  return (
    <Box pb={6}>
      <HStack justify="space-between">
        <Heading size="lg">Recent Smart Contract Events</Heading>
      </HStack>
      <SectionDivider />
      <>
        <VStack align="flex-start" mb={6} w="100%">
          <Heading size="md">Filters</Heading>
          <HStack justify="space-between" mb={2} width="100%">
            <Box maxW="250px" width="100%">
              <Select
                chakraStyles={{
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    bg: 'transparent',
                    px: 2,
                  }),
                }}
                isClearable
                onChange={(e) => vm.setFilterByEvent(e?.value ?? null)}
                options={vm.abiEvents.map((e) => ({ label: e.name, value: e.signature }))}
                placeholder="Show All Events"
              />
            </Box>
            <InputGroup>
              <InputLeftAddon>From Block</InputLeftAddon>
              <Input
                onBlur={vm.fetchEvents.run}
                onChange={(e) => vm.setFromBlock(e.target.value)}
                placeholder="From Block"
                value={vm.fromBlock}
              />
            </InputGroup>

            <InputGroup>
              <InputLeftAddon>To Block</InputLeftAddon>
              <Input
                onBlur={vm.fetchEvents.run}
                onChange={(e) => vm.setToBlock(e.target.value)}
                placeholder="To Block"
                value={vm.toBlock}
              />
            </InputGroup>

            <Tooltip label="Refresh" shouldWrapChildren>
              <FontAwesomeIcon cursor="pointer" icon={faRefresh} onClick={vm.fetchEvents.run} size="lg" width={20} />
            </Tooltip>
          </HStack>
        </VStack>

        <Box mt={6}>
          {vm.fetchEvents.isBusy || vm.fetchLatestBlock.isBusy ? (
            <Center>
              <Spinner />
            </Center>
          ) : null}
          {!vm.fetchEvents.isBusy && vm.fetchEvents.error ? (
            <Alert mb={4} status="error" whiteSpace="break-spaces" wordBreak="break-word">
              <AlertIcon />
              {parseEthersError(vm.fetchEvents.error)?.message ?? vm.fetchEvents.error.message}
            </Alert>
          ) : null}
          {!vm.fetchEvents.isBusy && !vm.fetchLatestBlock.isBusy && (
            <VStack align="flex-start" borderRadius="lg" borderWidth={1}>
              <TableContainer width="100%">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Event</Th>
                      <Th>Block</Th>
                      <Th>Transaction</Th>
                      <Th />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {!vm.events.length && !vm.fetchEvents.error && (
                      <Tr>
                        <Td rowSpan={4}>
                          {props.smartContract.deployment
                            ? 'Emit events from your smart contract to see data here'
                            : 'Deploy your smart contract to see data here'}
                        </Td>
                      </Tr>
                    )}
                    {vm.events.map((event, index) => (
                      <SmartContractEventListItem
                        key={`smart-contract-event-${index}`}
                        abi={props.smartContract.abi}
                        blockchain={props.blockchain}
                        event={event}
                      />
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </VStack>
          )}
        </Box>
      </>
    </Box>
  );
});
