/**
 * Kriptonio Private API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: private
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SubscriptionStatus = 'Cancelled' | 'Inactive' | 'Active';

export const SubscriptionStatus = {
    Cancelled: 'Cancelled' as SubscriptionStatus,
    Inactive: 'Inactive' as SubscriptionStatus,
    Active: 'Active' as SubscriptionStatus
};

