import {
  Button,
  Center,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { CInput, ValidatedForm } from '@web/toolkit';
import { observer } from 'mobx-react-lite';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { useVm } from '../../../domain/hook/useVm';
import { PaymasterGlobalPolicyRouteVm } from './PaymasterGlobalPolicyRouteVm';

export const PaymasterGlobalPolicyRoute = observer(function PaymasterGlobalPolicyRoute() {
  const navigate = useNavigate();
  const vm = useVm(PaymasterGlobalPolicyRouteVm);

  return (
    <Container maxW="container.sm">
      <HStack justify="space-between" marginY={6}>
        <Heading>Sponsorship Policy</Heading>
      </HStack>
      {vm.getSponsorshipPolic.isBusy ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <ValidatedForm onSubmit={vm.setPolicy.run}>
          <VStack spacing={4} width="100%">
            <FormControl>
              <HStack align="center" justify="space-between">
                <FormLabel>Max Sponsored USD Per Month</FormLabel>
                <Checkbox
                  onChange={(e) => vm.changeNoLimit(e.target.checked)}
                  isChecked={vm.form.maxUsdPerMonth == null}
                >
                  No Limit
                </Checkbox>
              </HStack>
              <NumericFormat
                key={`limit-${vm.form.maxUsdPerMonth ? 'set' : 'not-set'}`}
                value={vm.form.maxUsdPerMonth}
                isDisabled={vm.form.maxUsdPerMonth == null}
                customInput={CInput}
                allowNegative={false}
                decimalScale={2}
                onValueChange={(value) => vm.setMaxUsdPerMonth(value.floatValue)}
                thousandSeparator
              />
            </FormControl>
          </VStack>
          <HStack mt={6} spacing={2} w="100%">
            <Button onClick={() => navigate(-1)} variant="outline" w="100%">
              Cancel
            </Button>
            <Button colorScheme="blue" isLoading={vm.setPolicy.isBusy} type="submit" w="100%">
              Save
            </Button>
          </HStack>
        </ValidatedForm>
      )}
    </Container>
  );
});
