import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Badge, Collapse, HStack, Link, Td, Text, Tr, VStack, useDisclosure } from '@chakra-ui/react';
import { BlockchainResponse } from '@web/dto/api/blockchainResponse';
import { SponsoredTransactionResponse } from '@web/dto/paymaster/sponsoredTransactionResponse';
import { format, isAfter } from 'date-fns';
import { formatUnits } from 'viem';

interface IProps {
  readonly userOp: SponsoredTransactionResponse;
  readonly blockchain: BlockchainResponse;
}

export function SponsoredTransactionListItem(props: IProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const url = props.userOp.transactionHash
    ? `${props.blockchain.transactionUrlPrefix}/${props.userOp.transactionHash}`
    : undefined;

  const expired =
    !props.userOp.transactionSuccess && !props.userOp.gasFee && isAfter(new Date(), new Date(props.userOp.expiresAt));

  return (
    <>
      <Tr cursor="pointer" onClick={() => (isOpen ? onClose() : onOpen())}>
        <Td py={3} whiteSpace="nowrap">
          <HStack>
            {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
            <Text>{props.userOp.userOperationHash.substring(0, 20)}...</Text>
          </HStack>
        </Td>
        <Td py={3}>
          <Text isTruncated>
            {props.userOp.gasFee ? formatUnits(BigInt(props.userOp.gasFee), props.blockchain.coinDecimals) : '0'}
          </Text>
        </Td>
        <Td py={3} whiteSpace="nowrap">
          <Text>{format(new Date(props.userOp.createdAt), 'dd MMM, HH:mm:ss')}</Text>
        </Td>
        <Td>
          {expired ? (
            <Badge colorScheme="orange">expired</Badge>
          ) : props.userOp.transactionSuccess == null ? (
            <Badge>pending</Badge>
          ) : props.userOp.transactionSuccess && props.userOp.userOpSuccess ? (
            <Badge colorScheme="green">success</Badge>
          ) : (
            <Badge colorScheme="red">failed</Badge>
          )}
        </Td>
      </Tr>
      <Tr>
        <Td colSpan={5} px={0} py={0} borderBottom={0}>
          <Collapse in={isOpen}>
            <VStack w="100%" px={4} py={4} bgColor="gray.100" align="flex-start">
              <VStack align="flex-start" w="100%" flex={1} borderRadius="lg" maxH={400} overflow="auto">
                <Text fontWeight="semibold" fontSize="md">
                  User Op Hash
                </Text>
                <Text>{props.userOp.userOperationHash}</Text>
              </VStack>
              <VStack align="flex-start" w="100%" flex={1} borderRadius="lg" maxH={400} overflow="auto">
                <Text fontWeight="semibold" fontSize="md">
                  Transaction
                </Text>
                {url ? (
                  <Link whiteSpace="normal" wordBreak="break-word" href={url} isExternal target="_blank">
                    {url}
                  </Link>
                ) : (
                  <Text>n/a</Text>
                )}
              </VStack>
              <VStack align="flex-start" w="100%" flex={1} borderRadius="lg" maxH={400} overflow="auto">
                <Text fontWeight="semibold" fontSize="md">
                  User Op Success
                </Text>
                <Text>{props.userOp.userOpSuccess ? 'Yes' : 'No'}</Text>
              </VStack>
              <VStack align="flex-start" w="100%" flex={1} borderRadius="lg" maxH={400} overflow="auto">
                <Text fontWeight="semibold" fontSize="md">
                  Transaction Success
                </Text>
                <Text>{props.userOp.transactionSuccess ? 'Yes' : 'No'}</Text>
              </VStack>
            </VStack>
          </Collapse>
        </Td>
      </Tr>
    </>
  );
}
