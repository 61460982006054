import { Link, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';

interface IState {
  error: Error | null;
}

export class ErrorBoundary extends React.Component<React.PropsWithChildren, IState> {
  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  constructor(props: React.PropsWithChildren) {
    super(props);

    this.state = {
      error: null,
    };
  }

  public override componentDidCatch(error: unknown, errorInfo: unknown) {
    console.error(error, errorInfo);
  }

  public override render() {
    return this.state.error ? (
      <VStack w="100%">
        <Text>Something went wrong. Please try again</Text>
        <Link href="https://kriptonio.com">kriptonio</Link>
      </VStack>
    ) : (
      this.props.children
    );
  }
}
