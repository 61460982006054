/**
 * Kriptonio.Rpc.Rest, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DataPeriod = 'OneHour' | 'TwentyFourHours' | 'OneWeek' | 'ThisMonth';

export const DataPeriod = {
    OneHour: 'OneHour' as DataPeriod,
    TwentyFourHours: 'TwentyFourHours' as DataPeriod,
    OneWeek: 'OneWeek' as DataPeriod,
    ThisMonth: 'ThisMonth' as DataPeriod
};

