import type { EnableTwoFactor } from '@web/dto/api/enableTwoFactor';
import type { TwoFactorSetupResponse } from '@web/dto/api/twoFactorSetupResponse';
import { singleton } from '../../inversify/decorator';
import { AjaxService } from '../service/AjaxService';
import { Api } from './Api';

@singleton()
export class TwoFactorApi extends Api {
  constructor(private readonly ajax: AjaxService) {
    super();
  }

  public setupTwoFactor = () => {
    return this.ajax.get<TwoFactorSetupResponse>('v1/2fa');
  };

  public enableTwoFactor = (data: EnableTwoFactor) => {
    return this.ajax.post<TwoFactorSetupResponse>('v1/2fa', { data });
  };

  public disableTwoFactor = (data: EnableTwoFactor) => {
    return this.ajax.delete<TwoFactorSetupResponse>('v1/2fa', { data });
  };
}
