/* eslint-disable @typescript-eslint/no-explicit-any */

import { Result } from 'ethers';

export function createParamsObject(params: Result | undefined) {
  if (!params) {
    return [];
  }

  const keys = Object.keys(params).slice(params.length);
  if (keys.length === params.length) {
    const result: { [key: string]: string } = {};

    keys.forEach((key) => {
      result[key] = params[key].toString?.();
    });

    return result;
  }

  return params.map((v) => v.toString?.());
}

export function getEvents(abi: unknown[]): { name: string; signature: string }[] {
  return abi
    .filter((item: any) => {
      return item.type === 'event';
    })
    .map((event: any) => ({
      name: event.name,
      signature: `${event.name}(${event.inputs.map((i: any) => i.type).join(',')})`,
    }));
}
