import { Box, Heading, HStack, Text } from '@chakra-ui/react';
import type { DataPeriod } from '@web/dto/rpc/dataPeriod';
import { useCallback, useMemo, useState } from 'react';
import { dataPeriodToString } from '../../../utils/dataPeriod';

interface DataPeriodOption {
  period: DataPeriod;
}

interface IProps {
  readonly period: DataPeriod;
  readonly title: string;
  readonly onPeriodSelected: (period: DataPeriod) => void;
}

export function PeriodSelector(props: IProps) {
  const periods = useMemo((): DataPeriodOption[] => {
    return [
      {
        period: 'OneHour',
      },
      {
        period: 'TwentyFourHours',
      },
      {
        period: 'OneWeek',
      },
      {
        period: 'ThisMonth',
      },
    ];
  }, []);

  const [selected, setPeriod] = useState(periods.find((p) => p.period === props.period));

  const selectPeriod = useCallback(
    (period: DataPeriodOption) => {
      setPeriod(period);
      props.onPeriodSelected(period.period);
    },
    [setPeriod, props]
  );

  return (
    <HStack justify="space-between">
      {props.title ? <Heading size="md">{props.title}</Heading> : null}
      <HStack>
        {periods.map((period) => (
          <Box
            borderBottomColor={selected?.period === period.period ? 'blue.500' : 'transparent'}
            borderBottomWidth={3}
            cursor="pointer"
            key={`activity-period-${period.period}`}
            onClick={() => selectPeriod(period)}
            pb={1}
          >
            <Text>{dataPeriodToString(period.period)}</Text>
          </Box>
        ))}
      </HStack>
    </HStack>
  );
}
