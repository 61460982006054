import { Input, InputProps } from '@chakra-ui/react';
import { ChangeEvent, useCallback } from 'react';

interface CInputProps extends InputProps {
  onValueChange?: (value: string) => void;
}

export function CInput(props: CInputProps) {
  const { onValueChange, onChange, ...inputProps } = props;

  const internalOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      onValueChange?.(e.target.value);
    },
    [onChange, onValueChange]
  );

  return <Input onChange={internalOnChange} {...inputProps} />;
}
