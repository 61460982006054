/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, injectable } from 'inversify';
import { action, observable } from 'mobx';
import type { NavigateFunction } from 'react-router-dom';
import { Types } from '../inversify/types';

@injectable()
export abstract class ViewModel<Props = unknown, Params = any> {
  @inject(Types.Props)
  @observable.ref
  protected props: Props = {} as Props;

  @inject(Types.Params)
  @observable
  protected params: Params = {} as Params;

  @inject(Types.SearchParams)
  @observable
  protected searchParams: URLSearchParams = {} as URLSearchParams;

  @inject(Types.Navigate)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  protected readonly navigate: NavigateFunction = null!;

  private areShallowEqual = (obj1: any, obj2: any) => {
    return (
      Object.keys(obj1).length === Object.keys(obj2).length &&
      Object.keys(obj1).every((key) => {
        return obj2.hasOwnProperty(key) && obj1[key] === obj2[key];
      })
    );
  };

  @action
  public setProps = (props: Props) => {
    if (!this.areShallowEqual(this.props, props)) {
      this.props = props;
    }
  };

  @action
  public setParams = (params: Params) => {
    if (!this.areShallowEqual(this.params, params)) {
      this.params = params;
    }
  };

  public onInit = () => {
    /** should be inherited by children classes */
  };

  public onRender = () => {
    /** should be inherited by children classes */
  };

  public onDestroy = () => {
    /** should be inherited by children classes */
  };
}
