import { Container, Heading, HStack, SimpleGrid, Text, useToken, VStack } from '@chakra-ui/react';
import { faClone, faCode, faPuzzlePiece } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CLink } from '@web/toolkit';
import { observer } from 'mobx-react-lite';
import { useTitle } from 'react-use';
import { useVm } from '../../../../domain/hook/useVm';
import { SmartContractCreationOption } from './components/SmartContractCreationOption';
import { SmartContractViaTemplate } from './components/template/SmartContractViaTemplate';
import { SmartContractViaUpload } from './components/upload/SmartContractViaUpload';
import { NewSmartContractRouteVm } from './NewSmartContractRouteVm';

export const NewSmartContractRoute = observer(function NewSmartContractRoute() {
  useTitle('New Smart Contract | kriptonio');
  const vm = useVm(NewSmartContractRouteVm);
  const iconColor = useToken('colors', 'gray.600');

  return (
    <Container maxW="container.xl" pb={6}>
      <VStack mx="auto" align="flex-start" maxW="container.md" spacing={4}>
        <HStack justify="space-between" my={6}>
          <Heading>New Smart Contract</Heading>
        </HStack>
        <Heading size="md">How would you like to create a Smart Contract?</Heading>
        <SimpleGrid columns={3} spacing={2} pb={6}>
          <SmartContractCreationOption
            icon={<FontAwesomeIcon color={iconColor} icon={faCode} fontSize={26} />}
            onClick={() => vm.setCreationMethod('upload')}
            selected={vm.creationMethod === 'upload'}
            text="By Providing Solidity Source Code"
          />
          <SmartContractCreationOption
            icon={<FontAwesomeIcon color={iconColor} icon={faClone} fontSize={26} />}
            onClick={() => vm.setCreationMethod('template')}
            selected={vm.creationMethod === 'template'}
            text="Use Pre-Built Contract (ERC-20, ERC-721)"
          />
          <SmartContractCreationOption
            icon={<FontAwesomeIcon color={iconColor} icon={faPuzzlePiece} fontSize={26} />}
            onClick={() => vm.setCreationMethod('plugin')}
            selected={vm.creationMethod === 'plugin'}
            text="Use Hardhat or Truffle Plugin"
          />
        </SimpleGrid>
      </VStack>

      {vm.creationMethod === 'upload' && <SmartContractViaUpload />}
      {vm.creationMethod === 'template' && <SmartContractViaTemplate />}
      {vm.creationMethod === 'plugin' && (
        <VStack mx="auto" align="flex-start" maxW="container.md">
          <Text>
            We have created <CLink href="https://github.com/kriptonio/hardhat-kriptonio">hardhat-kriptonio</CLink> and{' '}
            <CLink href="https://github.com/kriptonio/truffle-kriptonio">truffle-kriptonio</CLink> plugins for easy
            integration between kriptonio and <CLink href="https://hardhat.org/">hardhat</CLink> or{' '}
            <CLink href="https://trufflesuite.com/">truffle</CLink>.
          </Text>
          <Text>For details on how to use these plugins, please check their README pages.</Text>
          <Text mt={2}>
            Once you finish integration and upload compiled assets to kriptonio, your smart contract will appear on a
            list of smart contracts on kriptonio.
          </Text>
        </VStack>
      )}
    </Container>
  );
});
