import { Button, HStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

interface IProps {
  readonly onNext?: () => void;
  readonly onBack?: () => void;
  readonly isLoading?: boolean;
  readonly nextDisabled?: boolean;
  readonly final?: boolean;
}

export function StepControls(props: IProps) {
  const navigate = useNavigate();

  return (
    <HStack mt={6} spacing={2} w="100%">
      {props.onBack ? (
        <Button onClick={props.onBack} variant="outline" w="100%">
          Back
        </Button>
      ) : (
        <Button onClick={() => navigate(-1)} variant="outline" w="100%">
          Cancel
        </Button>
      )}
      <Button
        onClick={props.onNext}
        colorScheme="blue"
        isLoading={props.isLoading}
        type="submit"
        w="100%"
        isDisabled={props.nextDisabled}
      >
        {props.final ? 'Save' : 'Next'}
      </Button>
    </HStack>
  );
}
