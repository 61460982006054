import { Container, Text, VStack } from '@chakra-ui/react';
import { useInstance } from '../../../domain/hook/useInstance';
import { OrganizationStore } from '../../../domain/store/OrganizationStore';
import { SubscriptionList } from './components/subscription-list/SubscriptionList';

export function SubscriptionChangeRoute() {
  const organizationStore = useInstance(OrganizationStore);

  return (
    <Container maxW="container.xl" py={6}>
      <VStack w="100%" spacing={6} align="center">
        <Text fontSize="3xl" fontWeight="semibold">
          Select new subscription plan
        </Text>
        {organizationStore.currentOrganization ? (
          <SubscriptionList
            organization={organizationStore.currentOrganization}
            activePlanId={organizationStore.currentOrganization.subscription.subscriptionPlanId}
            welcome={false}
          />
        ) : null}
      </VStack>
    </Container>
  );
}
