import { Center, Container, Flex } from '@chakra-ui/layout';
import { Grid, GridItem, Spinner, VStack } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Outlet } from 'react-router';
import { useVm } from '../../../../domain/hook/useVm';
import { LeftMenu } from '../../../components/LeftMenu';
import { BlockchainEndpointDetailRouteVm } from './BlockchainEndpointDetailRouteVm';
import { BlockchainEndpointOptionsMenu } from './components/BlockchainEndpointOptionsMenu';

export const BlockchainEndpointDetailRouteVmContext =
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  React.createContext<BlockchainEndpointDetailRouteVm>(null!);

export const BlockchainEndpointDetailRoute = observer(function BlockchainEndpointDetailRoute() {
  const vm = useVm(BlockchainEndpointDetailRouteVm);

  return (
    <Container maxW="container.xl">
      <Grid
        columnGap={5}
        rowGap={5}
        mt={5}
        templateColumns={{
          base: 'repeat(auto-fit, minmax(min-content, 1fr))',
          lg: 'min-content 1fr',
        }}
      >
        <GridItem>
          <VStack spacing={4} position="sticky" top={4} left={0}>
            <LeftMenu items={vm.menuItems} />
            {vm.blockchainEndpoint && !vm.blockchainEndpoint.managed ? (
              <BlockchainEndpointOptionsMenu blockchainEndpoint={vm.blockchainEndpoint} />
            ) : null}
          </VStack>
        </GridItem>
        <GridItem>
          <Flex h="100%" minW="xs" w="100%">
            <BlockchainEndpointDetailRouteVmContext.Provider value={vm}>
              {vm.blockchainEndpoint ? (
                <Outlet />
              ) : (
                <Center w="100%">
                  <Spinner />
                </Center>
              )}
            </BlockchainEndpointDetailRouteVmContext.Provider>
          </Flex>
        </GridItem>
      </Grid>
    </Container>
  );
});
