import { FormControl, FormControlProps, FormErrorMessage } from '@chakra-ui/react';
import React, { useCallback, useContext, useEffect, useId, useState } from 'react';
import type * as yup from 'yup';
import { ValidatedFormContext } from './ValidatedForm';

interface IProps extends FormControlProps {
  schema: yup.Schema;
  value: string | boolean | undefined;
  id?: string;
}

export function ValidatedFormControl(props: React.PropsWithChildren<IProps>) {
  const id = useId();
  const [validationError, setValidationError] = useState<string | null>(null);
  const { registerField, deregisterField, setValid, submitMode, validationMode, revalidateOnChange, submit } =
    useContext(ValidatedFormContext);

  const { schema, ...formControlProps } = props;
  const value = props.schema ? props.value : null;
  const componentId = props.id ?? id;

  const validate = useCallback((): Promise<boolean> => {
    if (!schema) {
      setValidationError('');
      setValid(componentId, true);

      return Promise.resolve(true);
    }

    return schema
      .validate(value)
      .then(() => {
        setValidationError('');
        setValid(componentId, true);

        return true;
      })
      .catch((e) => {
        setValidationError(e.message);
        setValid(componentId, false);

        return false;
      });
  }, [schema, componentId, setValid, value]);

  const onBlur = useCallback(
    (e: React.FormEvent) => {
      e.stopPropagation();

      if (submitMode === 'onBlur') {
        return submit();
      }

      if (validationMode === 'onBlur') {
        validate();
      }

      return;
    },
    [validationMode, submitMode, submit, validate]
  );

  useEffect(() => {
    // validations not yet run, skip revalidation
    if (validationError === null) {
      return;
    }

    if (revalidateOnChange) {
      validate();
    }
  }, [validationError, validate, revalidateOnChange]);

  useEffect(() => {
    if (props.schema) {
      registerField(
        componentId,
        () => {
          return validate();
        },
        () => {
          setValidationError(null); // this will prevent re-validation after submit
        }
      );
    }
  }, [registerField, deregisterField, componentId, validate, props.schema]);

  useEffect(() => {
    return () => {
      deregisterField(componentId);
    };
  }, [deregisterField, componentId]);

  return (
    <FormControl id={id} {...formControlProps} onBlur={onBlur} isInvalid={!!validationError}>
      {props.children}
      <FormErrorMessage>{validationError}</FormErrorMessage>
    </FormControl>
  );
}
