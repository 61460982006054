import { generatePath, Navigate } from 'react-router-dom';
import { useCurrentSlug } from '../domain/hook/useCurrentSlug';
import { AppRoutes } from '../router/Routes';

export function IndexRoute() {
  const slug = useCurrentSlug();

  return (
    <Navigate
      to={generatePath(AppRoutes.dashboard, {
        slug,
      })}
    />
  );
}
