import { Text, VStack } from '@chakra-ui/react';

interface IProps {
  readonly text: string;
  readonly description: string;
  readonly selected: boolean;
  readonly onClick: () => void;
}

export function TemplateType(props: IProps) {
  return (
    <VStack
      px={8}
      py={4}
      borderColor={props.selected ? 'blue.500' : 'gray.200'}
      borderWidth={2}
      onClick={props.onClick}
      cursor="pointer"
      borderRadius="lg"
    >
      <Text fontWeight={500}>{props.text}</Text>
      <Text fontSize="small" textAlign="center">
        {props.description}
      </Text>
    </VStack>
  );
}
