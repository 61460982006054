import type { UpdateUserBody } from '@web/dto/api/updateUserBody';
import { action, computed, makeObservable, observable } from 'mobx';
import { UserApi } from '../../../domain/api/UserApi';
import { AsyncAction } from '../../../domain/async/AsyncAction';
import { NotificationService } from '../../../domain/service/NotificationService';
import { OrganizationStore } from '../../../domain/store/OrganizationStore';
import { SessionStore } from '../../../domain/store/SessionStore';
import { ViewModel } from '../../../domain/ViewModel';
import { transient } from '../../../inversify/decorator';

@transient()
export class AccountSettingsRouteVm extends ViewModel {
  @observable
  public form: UpdateUserBody;

  constructor(
    private readonly userApi: UserApi,
    private readonly notification: NotificationService,
    private readonly session: SessionStore,
    private readonly organizationStore: OrganizationStore
  ) {
    super();

    this.form = {
      firstName: session.session?.user.firstName ?? '',
      lastName: session.session?.user.lastName ?? '',
    };

    makeObservable(this);
  }

  @computed
  public get email() {
    return this.session.session?.user.email ?? '';
  }

  @computed
  public get authMethod() {
    return this.session.session?.user.authMethod ?? '';
  }

  @action
  public onFirstNameChange = (firstName: string) => {
    this.form.firstName = firstName;
  };

  @action
  public onLastNameChange = (lastName: string) => {
    this.form.lastName = lastName;
  };

  public update = new AsyncAction(async () => {
    try {
      const result = await this.userApi.updateUser(this.form);
      if (result.ok) {
        this.notification.success('Success', 'Saved');
        this.session.setUser(result.data);
        void this.organizationStore.load();
        return;
      }

      this.notification.error('Error', result.error.stringify());
    } catch (e) {
      console.error(e);
      this.notification.error('Exception', 'Exception while updating user');
    }
  });
}
