import { ApiErrorCode } from '@web/dto/api/apiErrorCode';
import { makeObservable } from 'mobx';
import { ViewModel } from '../../../../../domain/ViewModel';
import { TwoFactorApi } from '../../../../../domain/api/TwoFactorApi';
import { AsyncAction } from '../../../../../domain/async/AsyncAction';
import { NotificationService } from '../../../../../domain/service/NotificationService';
import { SessionStore } from '../../../../../domain/store/SessionStore';
import { transient } from '../../../../../inversify/decorator';

export interface IDisableTwoFactorModalProps {
  isOpen: boolean;
  onClose: () => void;
}

@transient()
export class DisableTwoFactorModalVm extends ViewModel<IDisableTwoFactorModalProps> {
  constructor(
    private readonly twoFactorApi: TwoFactorApi,
    private readonly notification: NotificationService,
    private readonly session: SessionStore
  ) {
    super();
    makeObservable(this);
  }

  public disableTwoFactor = new AsyncAction(async (value: string) => {
    const code = parseInt(value);
    if (isNaN(code)) {
      return this.notification.warn('Invalid code', 'Please enter valid numeric code');
    }

    try {
      const result = await this.twoFactorApi.disableTwoFactor({ code });
      if (result.ok) {
        this.session.disableTwoFactor();
        this.notification.success('Success', 'Two factor disabled');
        return this.props.onClose();
      }

      if (result.error.code === ApiErrorCode.TwoFactorCodeInvalid) {
        return this.notification.error('Code invalid', 'Please enter valid two-factor code');
      }

      if (result.error.code === ApiErrorCode.TwoFactorTooManyFailedAttempts) {
        return this.notification.error(
          '2fa locked',
          'Too many failed attempts to disable two factor authentication. Please contact our customer support.'
        );
      }

      this.notification.error('Error', `Error while disabling two factor. ${result.error.code}`);
    } catch (e) {
      console.error(`exception while disabling 2fa`, e);
      this.notification.error('Exception', 'Error while disabling 2fa. Please contact support');
    }
  });
}
