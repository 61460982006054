import { HStack } from '@chakra-ui/layout';
import type { AccessTokenResponse } from '@web/dto/api/accessTokenResponse';
import { observer } from 'mobx-react-lite';
import { CopyableText } from '../../../../blockchain/components/CopyableText';

interface IProps {
  accessToken: AccessTokenResponse;
}

export const AccessToken = observer(function AccessToken(props: IProps) {
  return (
    <HStack position="relative" w="100%">
      <CopyableText text={props.accessToken.accessToken ?? ''} />
    </HStack>
  );
});
