import { Badge } from '@chakra-ui/layout';
import { Tooltip, TypographyProps, useClipboard } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useInstance } from '../../../domain/hook/useInstance';
import { NotificationService } from '../../../domain/service/NotificationService';

interface IProps extends TypographyProps {
  readonly address: string | null | undefined;
}

export function HexAddress(props: IProps) {
  const { address, ...rest } = props;
  const { onCopy, setValue } = useClipboard(address ?? 'empty');
  const notification = useInstance(NotificationService);

  useEffect(() => {
    setValue(address ?? '');
  }, [address, setValue]);

  const copy = useCallback(() => {
    onCopy();
    notification.success('Success', 'Address copied to clipboard');
  }, [onCopy, notification]);

  return (
    <Tooltip label="Click to copy">
      <Badge
        cursor="pointer"
        onClick={copy}
        textTransform="none"
        whiteSpace="pre-wrap"
        wordBreak="break-word"
        {...rest}
      >
        {props.address}
      </Badge>
    </Tooltip>
  );
}
