import type { DataPeriod } from '@web/dto/rpc/dataPeriod';

export function dataPeriodToString(period: DataPeriod) {
  switch (period) {
    case 'OneHour':
      return 'One Hour';
    case 'TwentyFourHours':
      return '24 Hours';
    case 'OneWeek':
      return 'One Week';
    case 'ThisMonth':
      return 'This Month';
  }
}
