import type { ContactBody } from '@web/dto/api/contactBody';
import { singleton } from '../../inversify/decorator';
import { AjaxService } from '../service/AjaxService';
import { Api } from './Api';

@singleton()
export class PublicApi extends Api {
  constructor(private readonly ajax: AjaxService) {
    super();
  }

  public contact = (data: ContactBody) => {
    return this.ajax.post('v1/contact', { data });
  };
}
