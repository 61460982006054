import type { PaymasterEndpointDetailResponse } from '@web/dto/paymaster/paymasterEndpointDetailResponse';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { generatePath } from 'react-router-dom';
import { ViewModel } from '../../../domain/ViewModel';
import { PaymasterEndpointApi } from '../../../domain/api/PaymasterEndpointApi';
import { AsyncAction } from '../../../domain/async/AsyncAction';
import { NotificationService } from '../../../domain/service/NotificationService';
import { OrganizationStore } from '../../../domain/store/OrganizationStore';
import { transient } from '../../../inversify/decorator';
import { AppRoutes } from '../../../router/Routes';
import { MenuItemProps } from '../../components/LeftMenuItem';

@transient()
export class PaymasterEndpointDetailRouteVm extends ViewModel<unknown, Readonly<{ id: string }>> {
  @observable
  public paymasterEndpoint: PaymasterEndpointDetailResponse | null = null;

  constructor(
    private readonly paymasterEndpointApi: PaymasterEndpointApi,
    private readonly notificationService: NotificationService,
    private readonly organizationStore: OrganizationStore,
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = () => {
    void this.loadPaymasterEndpoint.run();
  };

  @computed
  public get menuItems(): MenuItemProps[] {
    return [
      {
        title: 'General',
        url: generatePath(AppRoutes.paymaster.general, {
          id: this.params.id,
          slug: this.organizationStore.currentSlug,
        }),
      },
      {
        title: 'Docs',
        externalUrl: 'https://docs.kriptonio.com/sdk/paymasters/introduction',
      },
    ];
  }

  public loadPaymasterEndpoint = new AsyncAction(async () => {
    try {
      const result = await this.paymasterEndpointApi.get(this.params.id);
      if (result.ok) {
        return runInAction(() => {
          this.paymasterEndpoint = result.data;
        });
      }

      this.notificationService.warn('Loading data error', result.error.stringify());
    } catch (e) {
      console.error(e);
      this.notificationService.error('Exception', 'Unexpected error while loading paymaster');
    }
  });
}
