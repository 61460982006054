import { Box, Center, HStack, Text, VStack } from '@chakra-ui/layout';
import { Heading, Spinner } from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import { Feature } from '@web/dto/api/feature';
import { CLink } from '@web/toolkit';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useContext, useMemo, useRef } from 'react';
import { generatePath } from 'react-router-dom';
import { useTitle } from 'react-use';
import { useCurrentSlug } from '../../../../domain/hook/useCurrentSlug';
import { useInstance } from '../../../../domain/hook/useInstance';
import { BlockchainStore } from '../../../../domain/store/BlockchainStore';
import { AppRoutes } from '../../../../router/Routes';
import { BlockchainEndpointUrl } from '../../components/BlockchainEndpointUrl';
import { SectionDivider } from '../../components/SectionDivider';
import { BlockchainEndpointDetailRouteVmContext } from '../detail/BlockchainEndpointDetailRoute';
import { useEndpointTitle } from '../hooks/useEndpointTitle';
import { BlockchainEndpointSection } from './components/BlockchainEndpointSection';

export const BlockchainEndpointGeneralRoute = observer(function BlockchainEndpointGeneralRoute() {
  useTitle('Blockchain Endpoint General | kriptonio');
  const context = useContext(BlockchainEndpointDetailRouteVmContext);
  const blockchainStore = useInstance(BlockchainStore);

  const containerRef = useRef<HTMLDivElement>(null);
  const containerDimensions = useSize<HTMLDivElement>(containerRef);

  const blockchain = useMemo(() => {
    return blockchainStore.blockchains.find((b) => b.chainId === context.blockchainEndpoint?.chainId);
  }, [blockchainStore.blockchains, context.blockchainEndpoint?.chainId]);

  const hasBundler = useMemo(() => {
    return blockchain?.features.includes(Feature.Erc4337Bundler) ?? false;
  }, [blockchain]);

  const slug = useCurrentSlug();
  const title = useEndpointTitle(context.blockchainEndpoint);

  return (
    <Box pb={10} w="100%">
      <HStack justify="space-between">
        <Heading size="lg">General</Heading>
      </HStack>
      {!context.blockchainEndpoint ? (
        <Center marginTop={5} w="100%">
          <Spinner />
        </Center>
      ) : (
        <>
          <SectionDivider />
          <VStack align="flex-start" ref={containerRef}>
            <Box>
              <Text fontSize="md" fontWeight="semibold">
                Title
              </Text>
              <Text>{title}</Text>
            </Box>
            {context.blockchainEndpoint.wallet ? (
              <Box>
                <Text fontSize="md" fontWeight="semibold">
                  Wallet
                </Text>
                <CLink href={`${blockchain?.addressUrlPrefix}/${context.blockchainEndpoint.wallet}`} isExternal>
                  Wallet Details
                </CLink>
              </Box>
            ) : null}
            {context.blockchainEndpoint.paymasterId ? (
              <Box>
                <Text fontSize="md" fontWeight="semibold">
                  Paymaster
                </Text>
                {context.blockchainEndpoint.paymasterId ? (
                  <CLink
                    to={generatePath(AppRoutes.paymaster.general, { id: context.blockchainEndpoint.paymasterId, slug })}
                  >
                    Paymaster Details
                  </CLink>
                ) : null}
              </Box>
            ) : null}
            <Box>
              <Text fontSize="md" fontWeight="semibold">
                Blockchain
              </Text>
              <Text>{blockchain?.fqdn}</Text>
            </Box>
            <Box>
              <Text fontSize="md" fontWeight="semibold">
                ERC-4337 Bundler
              </Text>
              <Text>{hasBundler ? 'Yes' : 'No'}</Text>
            </Box>
            <Box>
              <HStack>
                <Text fontSize="md" fontWeight="semibold">
                  RPC Url
                </Text>
              </HStack>
              <BlockchainEndpointUrl blockchainEndpoint={context.blockchainEndpoint} />
            </Box>
            <Box>
              <Text fontSize="md" fontWeight="semibold">
                Created At
              </Text>
              <Text>{format(new Date(context.blockchainEndpoint.createdAt), 'MMM dd yyy, HH:mm:ss')}</Text>
            </Box>
          </VStack>

          <HStack marginTop={5}>
            <Heading marginTop={6} size="md">
              How to use?
            </Heading>
          </HStack>
          <SectionDivider />
          {context.blockchainEndpoint ? (
            <BlockchainEndpointSection
              containerWidth={containerDimensions?.width}
              blockchainEndpoint={context.blockchainEndpoint}
            />
          ) : null}
        </>
      )}
    </Box>
  );
});
