import type { AppleLoginBody } from '@web/dto/api/appleLoginBody';
import type { GoogleLoginBody } from '@web/dto/api/googleLoginBody';
import type { LoginBody } from '@web/dto/api/loginBody';
import type { SessionResponse } from '@web/dto/api/sessionResponse';
import type { UserResponse } from '@web/dto/api/userResponse';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import Cookies from 'universal-cookie';
import { env } from '../../env';
import { singleton } from '../../inversify/decorator';
import { AuthApi } from '../api/AuthApi';
import { AjaxService } from '../service/AjaxService';
import { AnalyticsService } from '../service/analytics/AnalyticsService';

@singleton()
export class SessionStore {
  @observable
  public session: SessionResponse | null = null;

  @observable
  public restoreInProgress = false;

  private authCookie = 'kriptonio-authenticated';

  constructor(
    private readonly authApi: AuthApi,
    private readonly ajax: AjaxService,
    analytics: AnalyticsService
  ) {
    makeObservable(this);
    ajax.setSessionToken(window.localStorage.getItem(env.sessionTokenKey));

    reaction(
      () => this.session?.user.id,
      (userId) => {
        analytics.identify(userId);
      },
      { fireImmediately: true }
    );
  }

  @computed
  public get authenticated() {
    return !!this.session?.user;
  }

  public login = async (data: LoginBody) => {
    const result = await this.authApi.login(data);

    if (result.ok) {
      await this.setSession(result.data);
    }

    return result;
  };

  public googleLogin = async (data: GoogleLoginBody) => {
    const result = await this.authApi.googleLogin(data);

    if (result.ok) {
      await this.setSession(result.data);
    }

    return result;
  };

  public appleLogin = async (data: AppleLoginBody) => {
    const result = await this.authApi.appleLogin(data);

    if (result.ok) {
      await this.setSession(result.data);
    }

    return result;
  };

  @action
  public enableTwoFactor = () => {
    if (this.session?.user) {
      this.session.user.twoFactorEnabled = true;
    }
  };

  @action
  public disableTwoFactor = () => {
    if (this.session?.user) {
      this.session.user.twoFactorEnabled = false;
    }
  };

  @action
  public setUser = (user: UserResponse) => {
    if (this.session) {
      this.session.user = user;
    }
  };

  @action
  public setSession = (session: SessionResponse) => {
    this.session = session;
    window.localStorage.setItem(env.sessionTokenKey, session.token);
    this.ajax.setSessionToken(window.localStorage.getItem(env.sessionTokenKey));

    const cookies = new Cookies();
    cookies.set(this.authCookie, true, { path: '/', domain: '.kriptonio.com' });
  };

  @action
  public restore = async () => {
    try {
      this.session = null;
      this.restoreInProgress = true;

      const result = await this.authApi.restore();
      if (result.ok) {
        await this.setSession(result.data);
      } else {
        this.removeSession();
      }

      return result;
    } finally {
      runInAction(() => {
        this.restoreInProgress = false;
      });
    }
  };

  @action
  private removeSession = () => {
    this.session = null;
    window.localStorage.removeItem(env.sessionTokenKey);
    this.ajax.setSessionToken(null);

    const cookies = new Cookies();
    cookies.remove(this.authCookie, { path: '/', domain: '.kriptonio.com' });
  };

  public logout = async () => {
    const result = await this.authApi.logout();

    if (result.ok) {
      this.removeSession();
    }

    return result;
  };
}
