import { env } from '../../../env';
import { singleton } from '../../../inversify/decorator';
import type { AnalyticsEvent } from './AnalyticsEvent';

@singleton()
export class AnalyticsService {
  constructor() {
    window.gtag?.('config', env.googleTrackingId, { ...env.googleTrackingParams });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public track = (event: AnalyticsEvent, params?: any) => {
    if (env.isDev) {
      console.info(`track ${event}`);
    }

    if (window.gtag) {
      window.gtag?.('event', event, params);
    }
  };

  public identify = (userId: string | undefined) => {
    window.gtag?.('config', env.googleTrackingId, {
      ...env.googleTrackingParams,
      user_id: userId,
    });
  };
}
