import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  HStack,
  Heading,
  Link,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { InputType } from '@web/dto/api/inputType';
import { TemplateInputPropertyResponse } from '@web/dto/api/templateInputPropertyResponse';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useMemo } from 'react';
import { useTitle } from 'react-use';
import { SmartContractApi } from '../../../../domain/api/SmartContractApi';
import { useInstance } from '../../../../domain/hook/useInstance';
import { commify } from '../../../../utils/strings';
import { Colorize } from '../../components/Colorize';
import { SectionDivider } from '../../components/SectionDivider';
import { SmartContractRouteVmContext } from '../detail/SmartContractDetailRoute';
import { SmartContractAbi } from './components/SmartContractAbi';

export const SmartContractSourceCodeRoute = observer(function SmartContractSourceCodeRoute() {
  useTitle('Smart Contract Source Code | kriptonio');
  const context = useContext(SmartContractRouteVmContext);
  const smartContractApi = useInstance(SmartContractApi);

  const sourceCodeUrl = useMemo(() => {
    return smartContractApi.contractCodeUrl(context.smartContract?.id ?? '');
  }, [smartContractApi, context.smartContract]);

  const formatSchemaProperty = useCallback((value: unknown, propertySchema: TemplateInputPropertyResponse) => {
    if (value == null) {
      return '-';
    }

    if (propertySchema.type === InputType.String) {
      return value.toString();
    }

    if (propertySchema.type === InputType.Number) {
      return commify(value.toString());
    }

    if (propertySchema.type === InputType.Boolean) {
      return value ? 'Yes' : 'No';
    }
  }, []);

  const files = useMemo(() => {
    const result: { title: string; content: string }[] = [];
    for (const key in context.smartContract?.standardJsonInput?.sources ?? {}) {
      result.push({ title: key, content: context.smartContract?.standardJsonInput?.sources[key].content ?? '' });
    }

    return result.reverse();
  }, [context.smartContract]);

  console.log(context.smartContract?.standardJsonInput);

  return (
    <Box maxW={700} pb={10} w="100%">
      <HStack justify="space-between" w="100%">
        <Heading size="lg">Source Code</Heading>
      </HStack>
      <SectionDivider />
      <SimpleGrid columns={2} rowGap={2}>
        <Box>
          <Text fontSize="md" fontWeight="semibold">
            Standard JSON
          </Text>
          <Link href={sourceCodeUrl} isExternal>
            Download <ExternalLinkIcon mx="2px" />
          </Link>
        </Box>
        <Box>
          <Text fontSize="md" fontWeight="semibold">
            Compiler Version
          </Text>
          <Text>{context.smartContract?.compilerVersion}</Text>
        </Box>
        <Box>
          <Text fontSize="md" fontWeight="semibold">
            Optimizer Enabled
          </Text>
          <Text>{context.smartContract?.optimizerEnabled ? 'Yes' : 'No'}</Text>
        </Box>
        {context.smartContract?.optimizerEnabled ? (
          <Box>
            <Text fontSize="md" fontWeight="semibold">
              Optimizer Runs
            </Text>
            <Text>{context.smartContract.optimizerRuns}</Text>
          </Box>
        ) : null}
      </SimpleGrid>
      <Box pt={4}>
        <Accordion defaultIndex={[]} allowToggle>
          <AccordionItem borderRadius="lg" borderWidth="1px">
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Text fontSize="md" fontWeight="semibold">
                        ABI
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {isExpanded && context.smartContract?.abi ? (
                    <SmartContractAbi abi={context.smartContract.abi} />
                  ) : null}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </Box>
      <Divider my={4} />
      {context.smartContract?.template ? (
        <SimpleGrid columns={2} rowGap={2}>
          {context.smartContract.template.inputSchema.properties.map((property) => (
            <Box key={property.key}>
              <Text fontSize="md" fontWeight="semibold">
                {property.title}
              </Text>
              <Text>{formatSchemaProperty(context.smartContract?.templateInput?.[property.key], property)}</Text>
            </Box>
          ))}
        </SimpleGrid>
      ) : null}
      <Heading marginTop={6} size="lg">
        Files
      </Heading>
      <SectionDivider />
      <Box w="100%">
        {context.smartContract && context.blockchain ? (
          <Accordion defaultIndex={files.length > 1 ? [] : [0]} allowToggle>
            {files.map((file) => (
              <AccordionItem key={`source-file-${file}`} borderRadius="lg" borderWidth="1px" mb={2}>
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          {file.title}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      {isExpanded ? <Colorize language="solidity" text={file.content} /> : null}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        ) : null}
      </Box>
    </Box>
  );
});
