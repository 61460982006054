import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Button,
  Td,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import type { OrganizationMemberResponse } from '@web/dto/api/organizationMemberResponse';
import { OrganizationRole } from '@web/dto/api/organizationRole';
import { observer } from 'mobx-react-lite';
import { useCallback, useRef } from 'react';
import { useCurrentUser } from '../../../../../domain/hook/useCurrentUser';
import { useVm } from '../../../../../domain/hook/useVm';
import { OrganizationUserItemVm } from './OrganizationUserItemVm';

interface IProps {
  member: OrganizationMemberResponse;
  currentUserRole: OrganizationRole | undefined;
}

export const OrganizationUserItem = observer(function OrganizationUserItem(props: IProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { member } = props;
  const currentUser = useCurrentUser();
  const vm = useVm(OrganizationUserItemVm);

  const removeUser = useCallback(() => {
    void vm.removeUser.run(props.member.user.id).then(onClose);
  }, [vm, props.member, onClose]);

  return (
    <Tr key={`org-member-${member.id}`}>
      <Td>{member.user.email}</Td>
      <Td>{member.user.firstName}</Td>
      <Td>{member.user.lastName}</Td>
      <Td>{member.role}</Td>
      <Td>
        <Badge colorScheme="green" variant="outline">
          Active
        </Badge>
      </Td>
      {props.currentUserRole === OrganizationRole.Owner && member.user.id !== currentUser?.id ? (
        <Td display="flex" justifyContent="flex-end">
          <Button onClick={onOpen}>Remove</Button>
          <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} preserveScrollBarGap>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Remove User
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure you want to remove {member.user.email} from {member.organization.name} organization?
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button isLoading={vm.removeUser.isBusy} colorScheme="red" onClick={removeUser} ml={3}>
                    Remove
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Td>
      ) : (
        <Td />
      )}
    </Tr>
  );
});
