import { Button } from '@chakra-ui/button';
import { Badge, HStack, Text } from '@chakra-ui/layout';
import { VStack } from '@chakra-ui/react';
import type { SmartContractResponse } from '@web/dto/api/smartContractResponse';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router';
import { useCurrentSlug } from '../../../../../domain/hook/useCurrentSlug';
import { useInstance } from '../../../../../domain/hook/useInstance';
import { BlockchainStore } from '../../../../../domain/store/BlockchainStore';
import { AppRoutes } from '../../../../../router/Routes';

interface IProps {
  readonly smartContract: SmartContractResponse;
}

export function SmartContractListItem(props: IProps) {
  const navigate = useNavigate();
  const slug = useCurrentSlug();
  const blockchainStore = useInstance(BlockchainStore);

  const showDetails = useCallback(() => {
    navigate(generatePath(AppRoutes.smartContract.general, { id: props.smartContract.id, slug }));
  }, [navigate, props.smartContract.id, slug]);

  const blockchain = useMemo(() => {
    return blockchainStore.blockchains.find((b) => b.id === props.smartContract.blockchainId);
  }, [blockchainStore.blockchains, props.smartContract.blockchainId]);

  return (
    <HStack
      borderRadius="lg"
      borderWidth={1}
      cursor="pointer"
      justify="space-between"
      minH={50}
      onClick={showDetails}
      p={4}
      shadow="md"
      w="100%"
    >
      <VStack alignItems="flex-start">
        <HStack justify="flex-start" width="100%">
          <Text fontWeight="semibold">{props.smartContract.title}</Text>
        </HStack>
        <Text fontSize="sm">Created on {format(new Date(props.smartContract.createdAt), 'MMM dd yyy, HH:mm:ss')}</Text>
      </VStack>
      <HStack flexDir="row" justify="flex-end" flexWrap="wrap" spacing={2}>
        <HStack py={1}>
          <Badge colorScheme="gray">{props.smartContract.type}</Badge>
          <Badge colorScheme="green">{blockchain?.fqdn}</Badge>
          <Badge variant="outline" colorScheme={props.smartContract.isDeployed ? 'blue' : 'orange'}>
            {props.smartContract.isDeployed ? 'Deployed' : 'Not Deployed'}
          </Badge>
        </HStack>
        <Button onClick={showDetails}>Details</Button>
      </HStack>
    </HStack>
  );
}
