import { SmartContract } from '@kriptonio/sdk';
import type { AccessTokenResponse } from '@web/dto/api/accessTokenResponse';
import type { SmartContractDetailResponse } from '@web/dto/api/smartContractDetailResponse';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { generatePath } from 'react-router-dom';
import type { Abi } from 'viem';
import { ViewModel } from '../../../../domain/ViewModel';
import { OrganizationApi } from '../../../../domain/api/OrganizationApi';
import { SmartContractApi } from '../../../../domain/api/SmartContractApi';
import { AsyncAction } from '../../../../domain/async/AsyncAction';
import { NotificationService } from '../../../../domain/service/NotificationService';
import { UrlService } from '../../../../domain/service/UrlService';
import { BlockchainStore } from '../../../../domain/store/BlockchainStore';
import { OrganizationStore } from '../../../../domain/store/OrganizationStore';
import { SessionStore } from '../../../../domain/store/SessionStore';
import { transient } from '../../../../inversify/decorator';
import { AppRoutes } from '../../../../router/Routes';
import { MenuItemProps } from '../../../components/LeftMenuItem';

@transient()
export class SmartContractDetailRouteVm extends ViewModel<unknown, Readonly<{ id: string }>> {
  @observable
  public smartContract: SmartContractDetailResponse | null = null;

  @observable
  public sdkSmartContract: SmartContract | null = null;

  @observable
  public accessTokens: AccessTokenResponse[] = [];

  constructor(
    private readonly blockchainStore: BlockchainStore,
    private readonly smartContractApi: SmartContractApi,
    private readonly notificationService: NotificationService,
    private readonly session: SessionStore,
    private readonly urlService: UrlService,
    private readonly organizationStore: OrganizationStore,
    private readonly organizationApi: OrganizationApi
  ) {
    super();
    makeObservable(this);
  }

  public loadData = async () => {
    runInAction(() => {
      this.smartContract = null;
    });

    void this.loadSmartContract.run();
    const result = await this.organizationApi.getAccessTokens(this.organizationStore.currentOrganization?.id ?? '');
    if (result.ok) {
      runInAction(() => {
        this.accessTokens = result.data.filter((at) => at.permissions.some((p) => !p.ipfsBucketId));
      });
    }
  };

  @computed
  public get blockchain() {
    return this.blockchainStore.blockchains.find((b) => b.id === this.smartContract?.blockchainId);
  }

  @computed
  public get menuItems(): MenuItemProps[] {
    return [
      {
        title: 'General',
        url: generatePath(AppRoutes.smartContract.general, {
          id: this.params.id,
          slug: this.organizationStore.currentSlug,
        }),
      },
      {
        title: 'Source Code',
        url: generatePath(AppRoutes.smartContract.sourceCode, {
          id: this.params.id,
          slug: this.organizationStore.currentSlug,
        }),
      },
      {
        title: 'UI Interaction',
        url: generatePath(AppRoutes.smartContract.ui, {
          id: this.params.id,
          slug: this.organizationStore.currentSlug,
        }),
      },
      {
        title: 'SDK Interaction',
        externalUrl: 'https://docs.kriptonio.com/sdk/smart-contracts/introduction',
      },
      {
        title: 'Events',
        url: generatePath(AppRoutes.smartContract.events, {
          id: this.params.id,
          slug: this.organizationStore.currentSlug,
        }),
      },
    ].filter((i): i is { title: string; url: string } => !!i);
  }

  @computed
  public get abi(): Abi {
    if (!this.smartContract?.abi) {
      return [];
    }

    return this.smartContract.abi as Abi;
  }

  @computed
  public get twoFactorEnabled() {
    return this.session.session?.user.twoFactorEnabled;
  }

  @action
  public setSmartContract = (smartContract: SmartContractDetailResponse) => {
    this.smartContract = smartContract;
  };

  public loadSmartContract = new AsyncAction(async () => {
    try {
      const result = await this.smartContractApi.getSmartContract(this.params.id);
      if (result.ok) {
        this.setSmartContract(result.data);

        const sdkSmartContract = await this.organizationStore.sdk.smartContract.get({
          id: this.params.id,
        });
        runInAction(() => {
          this.sdkSmartContract = sdkSmartContract;
        });

        return;
      }

      this.notificationService.warn('Loading data error', result.error.stringify());
    } catch (e) {
      console.error(e);
      this.notificationService.error('Exception', 'Unexpected error while loading smart contract');
    }
  });
}
