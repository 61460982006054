import type { BlockchainResponse } from '@web/dto/api/blockchainResponse';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Hex, createPublicClient, http } from 'viem';
import { ViewModel } from '../../../../domain/ViewModel';
import { transient } from '../../../../inversify/decorator';
import { getChain } from '../../../../utils/chain';
import { sleep } from '../../../../utils/sleep';

export interface ITransactionAwaitProps {
  rpcUrl: string;
  hash: Hex;
  blockchain: BlockchainResponse;
  onTransactionStatus?: (successful: boolean) => void;
}

@transient()
export class TransactionAwaitVm extends ViewModel<ITransactionAwaitProps> {
  @observable
  public success: boolean | null = null;

  public unmounted = false;

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  public override onInit = async () => {
    const maxWait = 60_000;
    let currentWait = 0;

    const chain = getChain(this.props.blockchain.chainId);
    const client = createPublicClient({
      transport: http(this.props.rpcUrl),
      chain,
    });

    while (currentWait < maxWait) {
      if (this.unmounted) {
        return;
      }

      try {
        const receipt = await client.getTransactionReceipt({
          hash: this.props.hash,
        });

        return runInAction(() => {
          this.success = receipt.status === 'success';
          this.props.onTransactionStatus?.(this.success);
        });
      } catch (e) {
        console.error('error while checking transaction status', e);
      } finally {
        const sleepMs = 500;
        await sleep(sleepMs);
        currentWait += sleepMs;
      }
    }

    return this.props.onTransactionStatus?.(false);
  };

  @computed
  public get txUrl() {
    return `${this.props.blockchain.transactionUrlPrefix}/${this.props.hash}`;
  }
}
