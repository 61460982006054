import { Center, Container, Text, VStack } from '@chakra-ui/react';
import { Navigate } from 'react-router-dom';
import { useInstance } from '../../../domain/hook/useInstance';
import { OrganizationStore } from '../../../domain/store/OrganizationStore';
import { AppRoutes } from '../../../router/Routes';
import { SubscriptionList } from './components/subscription-list/SubscriptionList';

export function SubscriptionWelcomeRoute() {
  const organizationStore = useInstance(OrganizationStore);

  return (
    <Center w="100%" bgColor="gray.100">
      <Container maxW="container.xl" py={6}>
        <VStack w="100%" spacing={8} align="center">
          <VStack>
            <Text fontWeight={600} fontSize="4xl">
              Welcome to Kriptonio
            </Text>
            <Text fontSize="2xl">To get started, select your subscription plan</Text>
          </VStack>
          {organizationStore.currentOrganization ? (
            <SubscriptionList organization={organizationStore.currentOrganization} welcome />
          ) : (
            <Navigate to={AppRoutes.index} />
          )}
        </VStack>
      </Container>
    </Center>
  );
}
