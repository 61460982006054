import { Flex, useColorModeValue } from '@chakra-ui/react';
import type React from 'react';

export function AnonymousRoute(props: React.PropsWithChildren<unknown>) {
  return (
    <Flex align="center" bg={useColorModeValue('gray.50', 'gray.800')} justify="center" minH="100vh">
      {props.children}
    </Flex>
  );
}
