import { VStack } from '@chakra-ui/layout';
import { LeftMenuItem, MenuItemProps } from './LeftMenuItem';

interface IProps {
  readonly items: MenuItemProps[];
}

export function LeftMenu(props: IProps) {
  return (
    <VStack borderRadius="lg" borderWidth={1} spacing={0} w="100%">
      {props.items.map((i, index) => (
        <LeftMenuItem
          key={`left-menu-item-${i.title}-${index}`}
          title={i.title}
          externalUrl={i.externalUrl}
          isLast={props.items.length > index + 1}
          url={i.url}
        />
      ))}
    </VStack>
  );
}
