import { Center, Container, Flex } from '@chakra-ui/layout';
import { Grid, GridItem, Spinner, VStack } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Outlet } from 'react-router';
import { useVm } from '../../../domain/hook/useVm';
import { LeftMenu } from '../../components/LeftMenu';
import { PaymasterEndpointDetailRouteVm } from './PaymasterEndpointDetailRouteVm';
import { PaymasterOptionsMenu } from './components/PaymasterOptionsMenu';

export const PaymasterEndpointDetailRouteVmContext =
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  React.createContext<PaymasterEndpointDetailRouteVm>(null!);

export const PaymasterEndpointDetailRoute = observer(function PaymasterEndpointDetailRoute() {
  const vm = useVm(PaymasterEndpointDetailRouteVm);

  return (
    <Container maxW="container.xl">
      <Grid
        columnGap={5}
        rowGap={5}
        mt={5}
        templateColumns={{
          base: 'repeat(auto-fit, minmax(min-content, 1fr))',
          lg: 'min-content 1fr',
        }}
      >
        <GridItem>
          <VStack spacing={4} position="sticky" top={4} left={0}>
            <LeftMenu items={vm.menuItems} />
            {vm.paymasterEndpoint ? <PaymasterOptionsMenu paymasterEndpoint={vm.paymasterEndpoint} /> : null}
          </VStack>
        </GridItem>
        <GridItem>
          <Flex h="100%" minW="xs" w="100%">
            <PaymasterEndpointDetailRouteVmContext.Provider value={vm}>
              {vm.paymasterEndpoint ? (
                <Outlet />
              ) : (
                <Center w="100%">
                  <Spinner />
                </Center>
              )}
            </PaymasterEndpointDetailRouteVmContext.Provider>
          </Flex>
        </GridItem>
      </Grid>
    </Container>
  );
});
