import type { ContactBody } from '@web/dto/api/contactBody';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { PublicApi } from '../../../../../domain/api/PublicApi';
import { AsyncAction } from '../../../../../domain/async/AsyncAction';
import { NotificationService } from '../../../../../domain/service/NotificationService';
import { SessionStore } from '../../../../../domain/store/SessionStore';
import { ViewModel } from '../../../../../domain/ViewModel';
import { transient } from '../../../../../inversify/decorator';

@transient()
export class SubscriptionListVm extends ViewModel {
  @observable
  public contactForm: ContactBody;

  constructor(
    session: SessionStore,
    private readonly publicApi: PublicApi,
    private readonly notification: NotificationService
  ) {
    super();
    makeObservable(this);

    this.contactForm = {
      email: session.session?.user?.email || '',
      message: 'I am interested in Kriptonio Custom plan. Please contact me.',
    };
  }

  @action
  public setMessage = (message: string) => {
    this.contactForm.message = message;
  };

  public sendContact = new AsyncAction(async (): Promise<boolean> => {
    try {
      const result = await this.publicApi.contact(this.contactForm);
      if (result.ok) {
        runInAction(() => {
          this.contactForm = {
            email: '',
            message: '',
          };
        });

        this.notification.success('Success', 'We have received your message. We will contract you soon.');
        return true;
      }

      this.notification.error('Error', 'Error while sending message. Please check provided data and try again.');
      return false;
    } catch (e) {
      console.error(`exception while sending contract message`, e);
      this.notification.error('Exception', 'Exception while sending message.');
      return false;
    }
  });
}
