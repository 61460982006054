import { Badge, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { Feature } from '@web/dto/api/feature';
import type { BlockchainEndpointResponse } from '@web/dto/rpc/blockchainEndpointResponse';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useCurrentSlug } from '../../../../domain/hook/useCurrentSlug';
import { useInstance } from '../../../../domain/hook/useInstance';
import { BlockchainStore } from '../../../../domain/store/BlockchainStore';
import { AppRoutes } from '../../../../router/Routes';
import { useEndpointTitle } from '../hooks/useEndpointTitle';

interface IProps {
  readonly blockchainEndpoint: BlockchainEndpointResponse;
}

export function BlockchainEndpointListItem(props: IProps) {
  const navigate = useNavigate();
  const slug = useCurrentSlug();
  const blockchainStore = useInstance(BlockchainStore);

  const blockchain = useMemo(() => {
    return blockchainStore.blockchains.find((b) => b.chainId === props.blockchainEndpoint.chainId);
  }, [blockchainStore.blockchains, props.blockchainEndpoint.chainId]);

  const showDetails = useCallback(() => {
    navigate(
      generatePath(AppRoutes.blockchainEndpoint.general, {
        id: props.blockchainEndpoint.id,
        slug,
      })
    );
  }, [navigate, props.blockchainEndpoint.id, slug]);

  const title = useEndpointTitle(props.blockchainEndpoint);
  const hasBundler = useMemo(() => {
    return blockchain?.features.includes(Feature.Erc4337Bundler) ?? false;
  }, [blockchain]);

  return (
    <HStack
      borderRadius="lg"
      borderWidth={1}
      cursor="pointer"
      justify="space-between"
      flexWrap="wrap"
      minH={50}
      onClick={showDetails}
      p={4}
      shadow="md"
      w="100%"
    >
      <VStack alignItems="flex-start">
        <HStack justify="flex-start" width="100%">
          <Text fontWeight="semibold">{title}</Text>
        </HStack>
        <Text fontSize="sm">
          Created on {format(new Date(props.blockchainEndpoint.createdAt), 'MMM dd yyy, HH:mm:ss')}
        </Text>
      </VStack>
      <HStack>
        {hasBundler ? <Badge>Bundler</Badge> : null}
        <Badge>RPC</Badge>
        <Badge colorScheme="green">{blockchain?.fqdn}</Badge>
        <Button onClick={showDetails}>Details</Button>
      </HStack>
    </HStack>
  );
}
