import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Box, Center, Spinner, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { ApiErrorCode } from '@web/dto/api/apiErrorCode';
import { CLink } from '@web/toolkit';
import { useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import authBackground from '../../../assets/auth-background-gradient-blur.jpeg';
import { OrganizationApi } from '../../../domain/api/OrganizationApi';
import { useInstance } from '../../../domain/hook/useInstance';
import { AppRoutes } from '../../../router/Routes';

export function InvitationRoute() {
  const params = useParams<{ id: string }>();
  const organizationApi = useInstance(OrganizationApi);
  const navigate = useNavigate();
  const bgColor = useColorModeValue('white', 'gray.700');

  const [state, acceptInvitation] = useAsyncFn(async () => {
    try {
      const result = await organizationApi.acceptInvitation(params.id ?? '');
      if (result.ok) {
        return result.data;
      }

      if (result.error.code === ApiErrorCode.UserNotFound) {
        navigate({
          pathname: AppRoutes.auth.register,
          search: `?invitation=${params.id}`,
        });

        return null;
      }

      return null;
    } catch (e) {
      console.error('exception while accepting invitation', e);
      return null;
    }
  }, [params.id]);

  useEffect(() => {
    void acceptInvitation();
  }, [acceptInvitation]);

  return (
    <Center w="100%" height="100vh" bgImage={`url(${authBackground})`} bgSize="cover">
      {state.loading ? (
        <Spinner color={bgColor} />
      ) : (
        <Box bg={bgColor} boxShadow="lg" p={8} rounded="lg">
          {state.value ? (
            <VStack spacing={4}>
              <Center w="80px" h="80px" borderRadius="50%" borderWidth={2} borderColor="green.500">
                <CheckIcon fontSize="4xl" color="green.500" />
              </Center>
              <Text>You are now member of {state.value.name} organization.</Text>
              <Text>
                Visit your organization by clicking{' '}
                <CLink
                  to={generatePath(AppRoutes.dashboard, {
                    slug: state.value.slug,
                  })}
                >
                  here
                </CLink>
                .
              </Text>
            </VStack>
          ) : (
            <VStack spacing={4}>
              <Center w="80px" h="80px" borderRadius="50%" borderWidth={2} borderColor="red.500">
                <CloseIcon fontSize="xl" color="red.500" />
              </Center>
              <Text>Cannot join organization. Invalid invitation link.</Text>
              <Text>
                <CLink to={AppRoutes.index}>Home page</CLink>
              </Text>
            </VStack>
          )}
        </Box>
      )}
    </Center>
  );
}
