import { useParams } from 'react-router-dom';
import { OrganizationStore } from '../store/OrganizationStore';
import { useInstance } from './useInstance';

export function useCurrentSlug() {
  const organizationStore = useInstance(OrganizationStore);
  const params = useParams<{ slug: string }>();

  if (params.slug) {
    return params.slug;
  }

  return organizationStore.currentSlug;
}
