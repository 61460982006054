import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  Input,
  Text,
} from '@chakra-ui/react';
import type { FocusableElement } from '@chakra-ui/utils';
import { ValidatedForm } from '@web/toolkit';
import React, { useRef, useState } from 'react';

interface IProps {
  readonly title: string;
  readonly description: string;
  readonly confirmationText: string;
  readonly isLoading: boolean;
  readonly onConfirm: () => void;
  readonly onCancel: () => void;
  readonly isOpen: boolean;
}

export function DeleteConfirmation(props: IProps) {
  const inputRef = useRef<FocusableElement>(null);
  const [confirmation, setConfirmation] = useState('');

  return (
    <AlertDialog isOpen={props.isOpen} leastDestructiveRef={inputRef} onClose={props.onCancel} preserveScrollBarGap>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <ValidatedForm onSubmit={props.onConfirm}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {props.title}
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text marginBottom={6}>{props.description}</Text>

              <FormControl>
                <Text marginBottom={2}>
                  Confirm your action by typing: <b>{props.confirmationText}</b>
                </Text>
                <Input
                  onChange={(e) => setConfirmation(e.target.value)}
                  ref={inputRef as unknown as React.LegacyRef<HTMLInputElement>}
                  value={confirmation}
                />
              </FormControl>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={props.onCancel}>Cancel</Button>
              <Button
                colorScheme="red"
                isLoading={props.isLoading}
                isDisabled={props.confirmationText !== confirmation}
                ml={3}
                type="submit"
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </ValidatedForm>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
