import { Box } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { useTitle } from 'react-use';
import { SmartContractUi } from '../../components/smart-contract-ui/SmartContractUi';
import { SmartContractRouteVmContext } from '../detail/SmartContractDetailRoute';

export const SmartContractUiRoute = observer(function SmartContractUiRoute() {
  useTitle('Smart Contract UI | kriptonio');
  const context = useContext(SmartContractRouteVmContext);

  return (
    <Box w="100%">
      {context.smartContract && context.blockchain ? (
        <SmartContractUi
          abi={context.abi}
          accessTokens={context.accessTokens}
          smartContract={context.smartContract}
          blockchain={context.blockchain}
        />
      ) : null}
    </Box>
  );
});
