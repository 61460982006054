import React, { CSSProperties, useCallback, useImperativeHandle, useState } from 'react';

type ValidationMode = 'onBlur' | 'onSubmit';
type SubmitMode = 'onBlur' | 'onSubmit';
type ValidationFn = () => Promise<boolean>;
type ResetFn = () => void;

interface IValidatedFormProps {
  onSubmit: () => void;
  onSubmitValidationError?: (invalidId: string) => void;
  validationMode?: ValidationMode;
  submitMode?: SubmitMode;
  revalidateOnChange?: boolean;
  style?: CSSProperties;
}

interface IValidatedFormContext {
  registerField: (field: string, validate: ValidationFn, reset: ResetFn) => void;
  deregisterField: (field: string) => void;
  setValid: (field: string, valid: boolean) => void;
  submit: () => Promise<void>;
  submitMode: SubmitMode;
  validationMode: ValidationMode;
  isValid: boolean;
  revalidateOnChange: boolean;
}

interface IFieldsState {
  [field: string]: { valid: boolean; validate: ValidationFn; reset: ResetFn };
}

export type ValidatedFormRef = {
  submit: () => Promise<void>;
  // returns true if there are errors
  validateForm: (skipIds?: string[]) => Promise<string | null>;
  resetValidations: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const ValidatedFormContext = React.createContext<IValidatedFormContext>(null!);

export const ValidatedForm = React.forwardRef<ValidatedFormRef, React.PropsWithChildren<IValidatedFormProps>>(
  (props, ref) => {
    const { validationMode = 'onSubmit', submitMode = 'onSubmit', revalidateOnChange = true } = props;

    const [fields] = useState<IFieldsState>({});
    const [isValid, setIsValid] = useState(false);

    useImperativeHandle(ref, () => ({
      submit,
      validateForm,
      resetValidations,
    }));

    const resetValidations = useCallback(() => {
      for (const field in fields) {
        fields[field].reset();
      }
    }, [fields]);

    const validateForm = useCallback(
      async (skipIds: string[] = []) => {
        let invalidId: string | null = null;

        for (const id in fields) {
          if (!skipIds.includes(id)) {
            fields[id].valid = await fields[id].validate();

            if (!fields[id].valid) {
              invalidId = id;
            }
          }
        }

        setIsValid(!invalidId);
        return invalidId;
      },
      [fields]
    );

    const submit = useCallback(async () => {
      const invalidId = await validateForm();

      if (!invalidId) {
        props.onSubmit();
      } else {
        props.onSubmitValidationError?.(invalidId);
      }
    }, [props, validateForm]);

    const onSubmit = useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        submit();
      },
      [submit]
    );

    const onBlur = useCallback(() => {
      if (submitMode === 'onBlur') {
        submit();
      }
    }, [submit, submitMode]);

    const setValid = useCallback(
      (field: string, valid: boolean) => {
        if (fields[field]) {
          fields[field].valid = valid;
        }

        setIsValid(Object.values(fields).every((f) => f.valid));
      },
      [fields]
    );

    const registerField = useCallback(
      (field: string, validate: ValidationFn, reset: () => void) => {
        fields[field] = fields[field] ?? {
          valid: false,
          validate,
          reset,
        };

        fields[field].validate = validate;
        fields[field].reset = reset;
      },
      [fields]
    );

    const deregisterField = useCallback(
      (field: string) => {
        delete fields[field];
      },
      [fields]
    );

    return (
      <form style={props.style} onSubmit={onSubmit} onBlur={onBlur} noValidate={true}>
        <ValidatedFormContext.Provider
          value={{
            validationMode,
            revalidateOnChange,
            isValid,
            submitMode,
            submit,
            setValid,
            registerField,
            deregisterField,
          }}
        >
          {props.children}
        </ValidatedFormContext.Provider>
      </form>
    );
  }
);

ValidatedForm.displayName = 'ValidatedForm';
