import { AddIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  FormLabel,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { OrganizationDetailResponse } from '@web/dto/api/organizationDetailResponse';
import { CInput, ValidatedForm, ValidatedFormControl } from '@web/toolkit';
import { Observer, observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useInstance } from '../../../domain/hook/useInstance';
import { useVm } from '../../../domain/hook/useVm';
import { OrganizationStore } from '../../../domain/store/OrganizationStore';
import { AppRoutes } from '../../../router/Routes';
import { OrganizationSelectorVm } from './OrganizationSelectorVm';

export const OrganizationSelector = observer(function OrganizationSelector() {
  const organizationStore = useInstance(OrganizationStore);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const vm = useVm(OrganizationSelectorVm);
  const navigate = useNavigate();

  const submitOrganization = useCallback(() => {
    vm.createOrganization.run().then(onClose).catch(console.error);
  }, [vm.createOrganization, onClose]);

  const selectOrganization = useCallback(
    (organization: OrganizationDetailResponse) => {
      organizationStore.selectOrganizationId(organization.id);
      navigate(
        generatePath(AppRoutes.dashboard, {
          slug: organization.slug,
        })
      );
    },
    [organizationStore, navigate]
  );

  const openSettings = useCallback(
    (organization: OrganizationDetailResponse) => {
      organizationStore.selectOrganizationId(organization.id);
      navigate(
        generatePath(
          organizationStore.currentOrganization?.isPersonal
            ? AppRoutes.settings.account
            : AppRoutes.settings.organization,
          { slug: organizationStore.currentSlug }
        )
      );
    },
    [navigate, organizationStore]
  );

  return (
    <>
      <Menu gutter={2} preventOverflow matchWidth>
        {({ isOpen }) => (
          <Observer>
            {() => (
              <>
                <MenuButton px={6} py={5} textAlign="left" color="gray.400">
                  <HStack>
                    <Text fontSize="md" fontWeight="bold">
                      {organizationStore.currentOrganization?.name}{' '}
                      {isOpen ? <ChevronUpIcon fontSize="xl" /> : <ChevronDownIcon fontSize="xl" />}
                    </Text>
                  </HStack>
                </MenuButton>
                <MenuList mx={6}>
                  {organizationStore.organizations.map((o) => (
                    <MenuItem
                      px={3}
                      justifyContent="space-between"
                      _focus={{ bgColor: 'initial' }}
                      py={0}
                      _hover={{ bgColor: 'gray.100' }}
                      borderLeftWidth={3}
                      borderLeftColor={o.id === organizationStore.currentOrganization?.id ? 'blue.500' : 'transparent'}
                      fontWeight="semibold"
                      key={`organization-item-${o.id}`}
                    >
                      <Text w="100%" py={3} onClick={() => selectOrganization(o)}>
                        {o.name}
                      </Text>
                      <IconButton
                        as={Box}
                        aria-label="settings"
                        size="sm"
                        icon={<FontAwesomeIcon size="1x" icon={faCog} />}
                        onClick={(e) => openSettings(o)}
                      />
                    </MenuItem>
                  ))}
                  <MenuDivider />
                  <MenuItem px={4} onClick={onOpen} icon={<AddIcon />} fontWeight="semibold">
                    New Organization
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Observer>
        )}
      </Menu>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ValidatedForm onSubmit={submitOrganization}>
            <ModalHeader>New Organization</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ValidatedFormControl schema={yup.string().required('Please provide name')} value={vm.name}>
                <FormLabel>Name</FormLabel>
                <CInput autoFocus onValueChange={vm.setName} value={vm.name} />
              </ValidatedFormControl>
            </ModalBody>

            <ModalFooter>
              <HStack>
                <Button onClick={onClose}>Cancel</Button>
                <Button isLoading={vm.createOrganization.isBusy} type="submit" colorScheme="blue">
                  Save
                </Button>
              </HStack>
            </ModalFooter>
          </ValidatedForm>
        </ModalContent>
      </Modal>
    </>
  );
});
