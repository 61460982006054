export const AppRoutes = {
  auth: {
    login: '/auth/login',
    register: '/auth/register',
    forgotPassword: '/auth/forgot-password',
    resetPassword: '/auth/reset-password/:token',
    invitation: '/auth/invitation/:id',
  },
  index: '/',
  walletConnect: '/mobile/wc',
  orgIndex: '/:slug',
  dashboard: '/:slug/dashboard',
  subscription: {
    welcome: '/subscriptions/welcome',
    change: '/:slug/subscriptions/change',
    successPayment: '/:slug/subscriptions/success',
    successUpdate: '/:slug/subscriptions/change-success',
  },
  settings: {
    index: ':slug/settings',
    account: '/:slug/settings/account',
    organization: '/:slug/settings/organization',
    security: '/:slug/settings/security',
    accessTokens: '/:slug/settings/access-tokens',
    users: '/:slug/settings/users',
    billing: '/:slug/settings/billing',
  },
  blockchainEndpoint: {
    list: '/:slug/blockchain-endpoints',
    new: '/:slug/blockchain-endpoints/new',
    detail: '/:slug/blockchain-endpoints/:id',
    general: '/:slug/blockchain-endpoints/:id/general',
    logs: '/:slug/blockchain-endpoints/:id/logs',
    activity: '/:slug/blockchain-endpoints/:id/activity',
  },
  smartContract: {
    detail: '/:slug/smart-contracts/:id',
    list: '/:slug/smart-contracts',
    new: '/:slug/smart-contracts/new',
    general: '/:slug/smart-contracts/:id/general',
    ui: '/:slug/smart-contracts/:id/ui',
    events: '/:slug/smart-contracts/:id/events',
    sourceCode: '/:slug/smart-contracts/:id/source-code',
  },
  paymaster: {
    new: '/:slug/paymasters/new',
    list: '/:slug/paymasters',
    policy: '/:slug/paymasters/policy',
    detail: '/:slug/paymasters/:id',
    general: '/:slug/paymasters/:id/general',
  },
};
