import type { BlockchainEndpointResponse } from '@web/dto/rpc/blockchainEndpointResponse';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { generatePath } from 'react-router-dom';
import { ViewModel } from '../../../../domain/ViewModel';
import { BlockchainEndpointApi } from '../../../../domain/api/BlockchainEndpointApi';
import { AsyncAction } from '../../../../domain/async/AsyncAction';
import { NotificationService } from '../../../../domain/service/NotificationService';
import { OrganizationStore } from '../../../../domain/store/OrganizationStore';
import { transient } from '../../../../inversify/decorator';
import { AppRoutes } from '../../../../router/Routes';
import { MenuItemProps } from '../../../components/LeftMenuItem';

@transient()
export class BlockchainEndpointDetailRouteVm extends ViewModel<unknown, Readonly<{ id: string }>> {
  @observable
  public blockchainEndpoint: BlockchainEndpointResponse | null = null;

  constructor(
    private readonly blockchainEndpointApi: BlockchainEndpointApi,
    private readonly notificationService: NotificationService,
    private readonly organizationStore: OrganizationStore,
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = () => {
    void this.loadBlockchainEndpoint.run();
  };

  @computed
  public get menuItems(): MenuItemProps[] {
    return [
      {
        title: 'General',
        url: generatePath(AppRoutes.blockchainEndpoint.general, {
          id: this.params.id,
          slug: this.organizationStore.currentSlug,
        }),
      },
      {
        title: 'Activity',
        url: generatePath(AppRoutes.blockchainEndpoint.activity, {
          id: this.params.id,
          slug: this.organizationStore.currentSlug,
        }),
      },
      {
        title: 'Logs',
        url: generatePath(AppRoutes.blockchainEndpoint.logs, {
          id: this.params.id,
          slug: this.organizationStore.currentSlug,
        }),
      },
      {
        title: 'Docs',
        externalUrl: 'https://docs.kriptonio.com/sdk/rpcs/introduction',
      },
    ];
  }

  public loadBlockchainEndpoint = new AsyncAction(async () => {
    try {
      const result = await this.blockchainEndpointApi.getBlockchainEndpoint(this.params.id);
      if (result.ok) {
        return runInAction(() => {
          this.blockchainEndpoint = result.data;
        });
      }

      this.notificationService.warn('Loading data error', result.error.stringify());
    } catch (e) {
      console.error(e);
      this.notificationService.error('Exception', 'Unexpected error while loading blockchain endpoint');
    }
  });
}
