import type { BlockchainResponse } from '@web/dto/api/blockchainResponse';
import { Feature } from '@web/dto/api/feature';
import { makeObservable, observable, runInAction } from 'mobx';
import { singleton } from '../../inversify/decorator';
import { BlockchainApi } from '../api/BlockchainApi';

@singleton()
export class BlockchainStore {
  @observable
  public blockchains: BlockchainResponse[] = [];

  constructor(private readonly blockchainApi: BlockchainApi) {
    makeObservable(this);
  }

  public load = async () => {
    try {
      const result = await this.blockchainApi.getBlockchains();
      if (result.ok) {
        return runInAction(() => {
          this.blockchains = result.data.sort((a, b) => (a.fqdn > b.fqdn ? 1 : -1));
        });
      }

      console.warn(`error while loading blockchain data. ${result.error.code}`);
    } catch (e) {
      console.error(`exception while loading blockchain data. ${e}`);
    }
  };

  public blockchainsForFeature = (feature: Feature) => {
    return this.blockchains.filter((b) => b.features.includes(feature));
  };
}
