import { ApiErrorCode } from '@web/dto/api/apiErrorCode';
import type { TwoFactorSetupResponse } from '@web/dto/api/twoFactorSetupResponse';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { ViewModel } from '../../../../../domain/ViewModel';
import { TwoFactorApi } from '../../../../../domain/api/TwoFactorApi';
import { AsyncAction } from '../../../../../domain/async/AsyncAction';
import { NotificationService } from '../../../../../domain/service/NotificationService';
import { SessionStore } from '../../../../../domain/store/SessionStore';
import { transient } from '../../../../../inversify/decorator';

export interface ISetupTwoFactorModalProps {
  isOpen: boolean;
  onClose: () => void;
}

@transient()
export class SetupTwoFactorModalVm extends ViewModel<ISetupTwoFactorModalProps> {
  @observable
  public twoFactorSetup: TwoFactorSetupResponse | null = null;

  @observable
  public manualCodeShown = false;

  constructor(
    private readonly twoFactorApi: TwoFactorApi,
    private readonly notification: NotificationService,
    private readonly session: SessionStore
  ) {
    super();
    makeObservable(this);
  }

  @action
  public setManualCodeShown = (shown: boolean) => {
    this.manualCodeShown = shown;
  };

  public setupTwoFactor = new AsyncAction(async () => {
    try {
      const result = await this.twoFactorApi.setupTwoFactor();
      if (result.ok) {
        return runInAction(() => {
          this.twoFactorSetup = result.data;
        });
      }
    } catch (e) {
      console.error(`exception while calling 2fa setup`, e);
      this.notification.error('Exception', 'Error while setting up 2fa. Please contact support');
    }
  });

  public submitTwoFactor = new AsyncAction(async (value: string) => {
    const code = parseInt(value);
    if (isNaN(code)) {
      return this.notification.warn('Invalid code', 'Please enter valid numeric code');
    }

    try {
      const result = await this.twoFactorApi.enableTwoFactor({ code });
      if (result.ok) {
        this.session.enableTwoFactor();
        this.notification.success('Success', 'Two factor enabled');
        return this.props.onClose();
      }

      if (result.error.code === ApiErrorCode.TwoFactorCodeInvalid) {
        return this.notification.error('Code invalid', 'Please enter valid two-factor code');
      }

      this.notification.error('Error', `Error while enabling two factor. ${result.error.code}`);
    } catch (e) {
      console.error(`exception while enabling 2fa`, e);
      this.notification.error('Exception', 'Error while enabling 2fa. Please contact support');
    }
  });
}
