import type { EntityStatsResponse } from '@web/dto/api/entityStatsResponse';
import type { BlockchainEndpointCallStats } from '@web/dto/rpc/blockchainEndpointCallStats';
import type { BlockchainEndpointMethodUsageItem } from '@web/dto/rpc/blockchainEndpointMethodUsageItem';
import type { BlockchainEndpointUsageItem } from '@web/dto/rpc/blockchainEndpointUsageItem';
import type { DataPeriod } from '@web/dto/rpc/dataPeriod';
import type { OrganizationStatsResponse } from '@web/dto/rpc/organizationStatsResponse';
import queryString from 'query-string';
import { env } from '../../env';
import { useSwr } from '../hook/useSwr';

export interface DashboardCallOptions {
  period: DataPeriod;
  blockchainEndpointId?: string;
}

export function useGetRpcStats(organizationId: string) {
  return useSwr<OrganizationStatsResponse>(`v1/organizations/${organizationId}/stats`, {
    baseURL: env.rpc,
  });
}

export function useGetOrganizationEndpointsConsumption(organizationId: string) {
  return useSwr<number>(`v1/organizations/${organizationId}/reports/endpoint-credit-usage`, {
    baseURL: env.rpc,
  });
}

export function useGetEntityStats(organizationId: string) {
  return useSwr<EntityStatsResponse>(`v1/organizations/${organizationId}/reports/entity-stats`);
}

export function useGetBlockchainEndpointCallStats(organizationId: string, opts: DashboardCallOptions) {
  return useSwr<BlockchainEndpointCallStats>(
    `v1/organizations/${organizationId}/reports/blockchain-endpoint-call-stats?${queryString.stringify(opts)}`,
    {
      baseURL: env.rpc,
    }
  );
}

export function useGetBlockchainEndpointUsage(organizationId: string, opts: DashboardCallOptions) {
  return useSwr<BlockchainEndpointUsageItem[]>(
    `v1/organizations/${organizationId}/reports/blockchain-endpoint-usage?${queryString.stringify(opts)}`,
    {
      baseURL: env.rpc,
    }
  );
}

export function useGetBlockchainEndpointMethodUsage(organizationId: string, opts: DashboardCallOptions) {
  return useSwr<BlockchainEndpointMethodUsageItem[]>(
    `v1/organizations/${organizationId}/reports/blockchain-endpoint-method-usage?${queryString.stringify(opts)}`,
    {
      baseURL: env.rpc,
    }
  );
}
