import { InfoIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormLabel,
  Heading,
  Link,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { CInput, CSelect, ValidatedForm, ValidatedFormControl } from '@web/toolkit';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';
import { useVm } from '../../../../../../domain/hook/useVm';
import { SolcErrorCode } from '../../../../../../typings/SolcErrorCode';
import { BlockchainNetworkSelect } from '../../../../endpoint/new/components/BlockchainNetworkSelect';
import { StepControls } from '../StepControls';
import { SmartContractViaUploadVm } from './SmartContractViaUploadVm';
import { SoliditySourceCode } from './components/SoliditySourceCode';

const steps = [
  { id: 'info', label: 'Basic Information' },
  { id: 'source-code', label: 'Provide Source Code' },
];

export const SmartContractViaUpload = observer(function SmartContractViaUpload() {
  const vm = useVm(SmartContractViaUploadVm);
  const { prevStep, nextStep, activeStep } = useSteps({
    initialStep: 0,
  });

  return (
    <VStack mx="auto" maxW="container.md">
      <Steps responsive variant="circles-alt" colorScheme="blue" activeStep={activeStep} py={10}>
        {steps.map(({ id, label }, index) => (
          <Step label={label} key={id}>
            {id === 'info' && (
              <Box w="100%" maxW="container.md">
                <Heading pb="5" size="md">
                  Basic Information
                </Heading>

                <ValidatedForm onSubmit={nextStep}>
                  <VStack spacing={4} width="100%">
                    <ValidatedFormControl
                      isRequired
                      schema={yup.string().required('Please provide smart contract title')}
                      value={vm.title}
                    >
                      <FormLabel>Title</FormLabel>
                      <CInput autoFocus onValueChange={vm.setTitle} type="text" value={vm.title} />
                    </ValidatedFormControl>
                    <BlockchainNetworkSelect
                      chainId={vm.blockchain?.chainId}
                      blockchains={vm.blockchains}
                      onBlockchainChange={vm.setBlockchainId}
                    />
                  </VStack>
                  <StepControls />
                </ValidatedForm>
              </Box>
            )}
            {id === 'source-code' && (
              <ValidatedForm onSubmit={vm.createSmartContract.run}>
                <VStack spacing={4} width="100%">
                  <ValidatedFormControl
                    isRequired
                    schema={yup.string().defined('Smart contract missing')}
                    value={vm.selectedFile?.name}
                  >
                    <FormLabel>Solidity Source Code</FormLabel>
                    <Accordion allowToggle mb={3}>
                      <AccordionItem borderRadius="lg" borderWidth="1px">
                        <AccordionButton>
                          <Flex align="center">
                            <InfoIcon color="gray.700" mr={3} />
                            <Text textAlign="left">
                              We support a single Solidity source code file. If you are using multiple files or external
                              libraries other than OpenZeppelin, you will need to flatten your source code first. Click
                              here to see how to flatten your source code.
                            </Text>
                          </Flex>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                          <Text marginLeft={2}>
                            If you are using Hardhat for development, check the following{' '}
                            <Link
                              href="https://coinsbench.com/how-to-flatten-a-solidity-file-using-hardhat-924871ab3139"
                              isExternal
                            >
                              link
                            </Link>{' '}
                            to see how to flatten source code. If you are using Truffle, check{' '}
                            <Link href="https://www.npmjs.com/package/truffle-flattener" isExternal>
                              truffle-flattener
                            </Link>
                            .
                          </Text>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                    <SoliditySourceCode file={vm.selectedFile} onFile={vm.setFile} />
                  </ValidatedFormControl>

                  {/* loading spinner */}
                  {vm.processingSmartContract ? <Spinner /> : null}
                  {/* compile error */}
                  {vm.compileError?.rootCause?.code === SolcErrorCode.CompileError && (
                    <Text alignSelf="flex-start" color="red.500" whiteSpace="pre-line">
                      {vm.compileError.rootCause.message}
                    </Text>
                  )}
                  {/* no contracts found */}
                  {!vm.compileError && !vm.processingSmartContract && vm.selectedFile && !vm.smartContracts.length ? (
                    <Text color="red.500">
                      Cannot find contracts in provided smart contract code. Please ensure that you code has at least
                      one contract.
                    </Text>
                  ) : null}
                  {/* select contract */}
                  {!vm.processingSmartContract && vm.selectedFile && vm.smartContracts.length ? (
                    <ValidatedFormControl
                      schema={yup.string().required('Please select smart contract to use')}
                      value={vm.selectedSmartContract}
                      isRequired
                    >
                      <FormLabel>Contract To Use</FormLabel>
                      <CSelect onValueChange={vm.setSelectedContract} value={vm.selectedSmartContract}>
                        <option key="smart-contract-empty" value={undefined}>
                          -
                        </option>
                        {vm.smartContracts.map((s) => (
                          <option key={`sc-${s}`} value={s}>
                            {s}
                          </option>
                        ))}
                      </CSelect>
                    </ValidatedFormControl>
                  ) : null}
                </VStack>
                <StepControls onBack={prevStep} isLoading={vm.createSmartContract.isBusy} final />
              </ValidatedForm>
            )}
          </Step>
        ))}
      </Steps>
    </VStack>
  );
});
