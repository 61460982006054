import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  Image,
  Stack,
  Text,
  Tooltip,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { faBars, faCog, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { useInstance } from '../domain/hook/useInstance';
import { NotificationService } from '../domain/service/NotificationService';
import { OrganizationStore } from '../domain/store/OrganizationStore';
import { SessionStore } from '../domain/store/SessionStore';
import { AppRoutes } from './Routes';

interface IProps {
  onToggle: () => void;
}

export const Navbar = observer(function Navbar(props: IProps) {
  const { colorMode, toggleColorMode } = useColorMode();

  const navigate = useNavigate();

  const session = useInstance(SessionStore);
  const organizationStore = useInstance(OrganizationStore);
  const notification = useInstance(NotificationService);

  const openSettings = useCallback(() => {
    navigate(
      generatePath(
        organizationStore.currentOrganization?.isPersonal
          ? AppRoutes.settings.account
          : AppRoutes.settings.organization,
        { slug: organizationStore.currentSlug }
      )
    );
  }, [navigate, organizationStore.currentOrganization, organizationStore.currentSlug]);

  const logout = useCallback(() => {
    session
      .logout()
      .then(() => {
        navigate(AppRoutes.auth.login);
      })
      .catch((e) => {
        console.error(e);
        notification.error('Logout error', 'Error while logging out');
      });
  }, [navigate, notification, session]);

  return (
    <Box
      bg={useColorModeValue('white', 'gray.800')}
      borderBottomWidth="1px"
      borderColor={useColorModeValue('inherit', 'gray.700')}
    >
      <Container maxW="container.xl">
        <Flex alignItems="center" h={16} justifyContent="space-between">
          <Flex>
            <IconButton
              aria-label="Toggle sidebar"
              display={{ base: 'unset', md: 'none' }}
              icon={<FontAwesomeIcon cursor="pointer" fontSize="24px" icon={faBars} />}
              onClick={props.onToggle}
              mr={2}
            />
            <HStack as={Link} to={generatePath(AppRoutes.dashboard, { slug: organizationStore.currentSlug })}>
              <Image w={7} src={require('../assets/android-chrome-192x192.png')} />
              <Text display={{ base: 'none', md: 'initial' }} fontSize="md" fontWeight="semibold">
                Kriptonio
              </Text>
            </HStack>
          </Flex>
          <Stack direction="row">
            {organizationStore.currentOrganization?.subscription.isFree ? (
              <Button
                as={Link}
                to={generatePath(AppRoutes.subscription.change, { slug: organizationStore.currentSlug })}
                variant="outline"
                colorScheme="blue"
              >
                Upgrade
              </Button>
            ) : null}
            <Tooltip label={colorMode === 'light' ? 'Turn on Dark Mode' : 'Turn on Light Mode'}>
              <IconButton
                aria-label="change theme"
                icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                onClick={toggleColorMode}
              />
            </Tooltip>
            <Tooltip label="Settings">
              <IconButton aria-label="settings" icon={<FontAwesomeIcon icon={faCog} />} onClick={openSettings} />
            </Tooltip>
            <Tooltip label="Logout">
              <IconButton aria-label="logout" icon={<FontAwesomeIcon icon={faSignOut} />} onClick={logout} />
            </Tooltip>
          </Stack>
        </Flex>
      </Container>
    </Box>
  );
});
