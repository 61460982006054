import { Button, SimpleGrid } from '@chakra-ui/react';

type SegmentedButton = {
  text: string;
  active?: boolean;
  onPress: () => void;
};

interface IProps {
  readonly buttons: SegmentedButton[];
}

export function SegmentedControl(props: IProps) {
  return (
    <SimpleGrid columns={{ lg: props.buttons.length, md: 1 }} spacing={2}>
      {props.buttons.map((button, index) => (
        <Button
          key={`button-${index}`}
          onClick={button.onPress}
          borderColor={button.active ? 'blue.500' : 'gray.100'}
          borderWidth={2}
        >
          {button.text}
        </Button>
      ))}
    </SimpleGrid>
  );
}
