import type { OrganizationSubscriptionResponse } from '@web/dto/api/organizationSubscriptionResponse';
import type { SubscriptionPlanResponse } from '@web/dto/api/subscriptionPlanResponse';
import { singleton } from '../../inversify/decorator';
import { useSwr } from '../hook/useSwr';
import { AjaxService } from '../service/AjaxService';
import { Api } from './Api';

@singleton()
export class SubscriptionApi extends Api {
  constructor(private readonly ajax: AjaxService) {
    super();
  }

  public cancelOrganizationSubscription = (organizationId: string) => {
    return this.ajax.post(`v1/subscriptions/organizations/${organizationId}/cancel-subscription`);
  };

  public changeOrganizationSubscription = (organizationId: string, planId: string) => {
    return this.ajax.put<string | undefined>(
      `v1/subscriptions/organizations/${organizationId}/subscription-plan/${planId}`
    );
  };

  public getSubscriptionBySessionId = (sessionId: string) => {
    return this.ajax.get<OrganizationSubscriptionResponse>(`v1/subscriptions/checkout-sessions/${sessionId}`);
  };
}

export function useGetSubscriptionPlans() {
  return useSwr<SubscriptionPlanResponse[]>(`v1/subscriptions/plans`);
}
