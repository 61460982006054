import type { CreateSmartContractFromTemplateBody } from '@web/dto/api/createSmartContractFromTemplateBody';
import type { CreateSmartContractResponse } from '@web/dto/api/createSmartContractResponse';
import { InputType } from '@web/dto/api/inputType';
import type { SmartContractTemplateResponse } from '@web/dto/api/smartContractTemplateResponse';
import type { TemplateInputPropertyResponse } from '@web/dto/api/templateInputPropertyResponse';
import type { ValidatedFormRef } from '@web/toolkit';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { createRef } from 'react';
import { generatePath } from 'react-router-dom';
import * as yup from 'yup';
import { ViewModel } from '../../../../../../domain/ViewModel';
import { SmartContractApi } from '../../../../../../domain/api/SmartContractApi';
import { TemplateApi } from '../../../../../../domain/api/TemplateApi';
import { AsyncAction } from '../../../../../../domain/async/AsyncAction';
import { NotificationService } from '../../../../../../domain/service/NotificationService';
import { AnalyticsEvent } from '../../../../../../domain/service/analytics/AnalyticsEvent';
import { AnalyticsService } from '../../../../../../domain/service/analytics/AnalyticsService';
import { BlockchainStore } from '../../../../../../domain/store/BlockchainStore';
import { OrganizationStore } from '../../../../../../domain/store/OrganizationStore';
import { transient } from '../../../../../../inversify/decorator';
import { AppRoutes } from '../../../../../../router/Routes';

const contractKey = 'contract';

@transient()
export class SmartContractViaTemplateVm extends ViewModel {
  @observable
  public form: CreateSmartContractFromTemplateBody = {
    title: '',
    blockchainId: '',
    contract: '',
    smartContractTemplateId: '',
    templateInput: {},
  };

  @observable
  public selectedTemplate: SmartContractTemplateResponse | null = null;

  @observable
  public templates: SmartContractTemplateResponse[] = [];

  @observable
  public sourceCode: string | null = null;

  public formRef = createRef<ValidatedFormRef>();

  constructor(
    private readonly analytics: AnalyticsService,
    readonly smartContractApi: SmartContractApi,
    private readonly blockchainStore: BlockchainStore,
    private readonly templateApi: TemplateApi,
    private readonly organizationStore: OrganizationStore,
    private readonly notification: NotificationService
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = () => {
    void this.loadTemplates.run();
  };

  public createYupSchema = (property: TemplateInputPropertyResponse) => {
    let schema: yup.Schema | null = null;

    if (property.type === InputType.String) {
      schema = yup.string().nullable();
    } else if (property.type === InputType.Number) {
      schema = yup.number().nullable();
    } else if (property.type === InputType.Boolean) {
      schema = yup.boolean().nullable();
    }

    if (!schema) {
      return null;
    }

    if (property.isRequired) {
      return schema.required(`Please provide value for ${property.title}`);
    }

    return schema;
  };

  @computed
  public get blockchains() {
    return this.blockchainStore.blockchains.filter((b) => b.features.includes('SmartContract'));
  }

  @action
  public setBooleanInputValue = (key: string, value: boolean) => {
    this.form.templateInput[key] = value;
    void this.compileTemplate.run();
  };

  @action
  public setNumberInputValue = (key: string, value: number | undefined) => {
    this.form.templateInput[key] = value;
    void this.compileTemplate.run();
  };

  @action
  public setStringValue = (key: string, value: string) => {
    if (key === contractKey) {
      value = value.replace(/[^a-zA-Z0-9_]/g, '');
    }

    this.form.templateInput[key] = value;
    void this.compileTemplate.run();
  };

  @action
  public setTitle = (value: string) => {
    this.form.title = value;
  };

  @action
  public setBlockchainId = (value: string | null) => {
    this.form.blockchainId = value!;
  };

  @action
  public setTemplate = (template: SmartContractTemplateResponse) => {
    this.selectedTemplate = template;
    this.sourceCode = null;
    this.form.smartContractTemplateId = template.id;
    this.setDefaults(template);
    void this.compileTemplate.run();
  };

  @computed
  public get blockchain() {
    if (!this.form.blockchainId) {
      return null;
    }

    return this.blockchains.find((b) => b.id === this.form.blockchainId);
  }

  public loadTemplates = new AsyncAction(async () => {
    try {
      const result = await this.templateApi.getTemplates();
      if (result.ok) {
        return runInAction(() => {
          this.templates = result.data;
        });
      }

      console.warn(`error while loading templates. ${result.error.code}`);
    } catch (e) {
      console.error(`exception while loading templates. ${e}`);
    }
  });

  @action
  private setDefaults = (template: SmartContractTemplateResponse) => {
    this.form.templateInput = template.inputSchema.properties.reduce(
      (acc, input) => {
        acc[input.key] = input.defaultValue;
        return acc;
      },
      {} as Record<string, string>
    );
  };

  public compileTemplate = new AsyncAction(async () => {
    if (!this.selectedTemplate) {
      return;
    }

    try {
      const result = await this.templateApi.compileTemplate(this.selectedTemplate.id, {
        templateInput: this.form.templateInput,
      });
      if (result.ok) {
        return runInAction(() => {
          this.sourceCode = result.data.content;
        });
      }

      this.notification.error('Error', `Error while compiling template. ${result.error.stringify()}`);
    } catch (e) {
      console.error(`exception while compiling template. ${e}`);
      this.notification.error('Exception', 'Exception while compiling template');
    }
  });

  public handleValidationError = (invalidElementId: string) => {
    // selecting parent because id belongs to the input element.
    // parent is a full form control which we want to highlight
    const element = document.getElementById(invalidElementId)?.parentElement;
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }

    return this.notification.warn('Validation error', 'Please check if you have provided all required values');
  };

  public create = new AsyncAction(async () => {
    try {
      this.form.contract = this.form.templateInput[contractKey];

      const result = await this.smartContractApi.createSmartContractFromTemplate(
        this.organizationStore.currentOrganization?.id ?? '',
        this.form
      );

      if (result.ok) {
        this.analytics.track(AnalyticsEvent.SmartContractCreated);
        return this.showDetails(result.data);
      }

      this.notification.warn('Error while creating smart contract', result.error.stringify());
    } catch (e) {
      console.error(e);
      this.notification.error(
        'Unexpected Error',
        'Unexpected error while creating smart contract. Please check provided data'
      );
    }
  });

  public showDetails = (contract: CreateSmartContractResponse) => {
    this.navigate(
      generatePath(AppRoutes.smartContract.general, { id: contract.id, slug: this.organizationStore.currentSlug })
    );
  };
}
