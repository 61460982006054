import {
  Box,
  Button,
  Center,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Link,
  PinInput,
  PinInputField,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGoogleLogin } from '@react-oauth/google';
import { CInput, CLink, ValidatedForm, ValidatedFormControl } from '@web/toolkit';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useTitle } from 'react-use';
import * as yup from 'yup';
import authBackground from '../../../assets/auth-background-gradient-blur.jpeg';
import { useInstance } from '../../../domain/hook/useInstance';
import { useVm } from '../../../domain/hook/useVm';
import { NotificationService } from '../../../domain/service/NotificationService';
import { AppRoutes } from '../../../router/Routes';
import { RegisterRouteVm } from './RegisterRouteVm';

export const RegisterRoute = observer(function LoginContainer() {
  useTitle('Register | kriptonio');
  const vm = useVm(RegisterRouteVm);
  const notification = useInstance(NotificationService);
  const bgColor = useColorModeValue('white', 'gray.700');

  const googleLogin = useGoogleLogin({
    onSuccess: (result) => {
      runInAction(() => {
        vm.googleLoginData.accessToken = result.access_token;
        void vm.googleLogin.run();
      });
    },
    onError: (err) => {
      console.error(err);
      notification.error('Google register error', err.error ?? '');
    },
    flow: 'implicit',
  });

  if (vm.loadInvitation.isBusy) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <Flex align="center" bgImage={`url(${authBackground})`} bgSize="cover" height="100vh" w="100%">
      <Box maxW="lg" minW={{ md: '450px' }} mx="auto" px={6}>
        <Box bg={bgColor} boxShadow="lg" p={8} rounded="lg">
          {!vm.waitingActivation ? (
            <>
              <Heading fontSize="3xl" mb={3} textAlign="center">
                {vm.invitation ? `Joining ${vm.invitation.organization.name} organization` : 'Register New Account'}
              </Heading>
              <ValidatedForm onSubmit={vm.register.run}>
                <Stack spacing={4}>
                  <ValidatedFormControl
                    schema={yup.string().required('Please provide First Name')}
                    value={vm.registerForm.firstName}
                  >
                    <FormLabel>First Name</FormLabel>
                    <CInput autoFocus onValueChange={vm.onFirstNameChange} value={vm.registerForm.firstName} />
                  </ValidatedFormControl>
                  <ValidatedFormControl
                    schema={yup.string().required('Please provide Last Name')}
                    value={vm.registerForm.lastName}
                  >
                    <FormLabel>Last Name</FormLabel>
                    <CInput onValueChange={vm.onLastNameChange} value={vm.registerForm.lastName} />
                  </ValidatedFormControl>
                  <ValidatedFormControl
                    schema={yup.string().email('Please provide valid email').required('Please provide email')}
                    value={vm.registerForm.email}
                  >
                    <FormLabel>Email address</FormLabel>
                    <CInput
                      isDisabled={!!vm.invitation}
                      onValueChange={vm.onEmailChange}
                      type="email"
                      value={vm.registerForm.email}
                    />
                  </ValidatedFormControl>
                  <ValidatedFormControl
                    schema={yup
                      .string()
                      .min(8, 'Password must be at least 8 characters long')
                      .required('Please provide password')
                      .test('password-strength', vm.validatePasswordStrength)}
                    value={vm.registerForm.password}
                  >
                    <FormLabel>Password</FormLabel>
                    <CInput onValueChange={vm.onPasswordChange} type="password" value={vm.registerForm.password} />
                  </ValidatedFormControl>
                  <Button colorScheme="blue" isLoading={vm.register.isBusy} type="submit">
                    Register
                  </Button>
                  {!vm.invitation && (
                    <Button
                      colorScheme="red"
                      isDisabled={vm.googleLogin.isBusy || !vm.uiStore.googleScriptLoaded}
                      isLoading={vm.googleLogin.isBusy}
                      leftIcon={<FontAwesomeIcon cursor="pointer" icon={faGoogle} size="lg" width={20} />}
                      onClick={() => googleLogin()}
                    >
                      Register via Google
                    </Button>
                  )}
                </Stack>
              </ValidatedForm>
              <VStack marginTop={5} spacing={5}>
                <Text fontSize="sm">
                  By registering you accept our{' '}
                  <Link href="https://kriptonio.com/terms-and-conditions">terms and conditions</Link> and{' '}
                  <Link href="https://kriptonio.com/privacy">privacy policy</Link>.
                </Text>
                {!vm.invitation && (
                  <Text alignSelf="center" textAlign="center">
                    Already have an account? <CLink to={AppRoutes.auth.login}>Login</CLink>
                  </Text>
                )}
              </VStack>
            </>
          ) : (
            <>
              <Heading fontSize="3xl" mb={3} textAlign="center">
                Account activation
              </Heading>
              <Text textAlign="center">
                We have sent you activation code to <b>{vm.activationEmail}</b>. Please enter code to activate your
                account
              </Text>
              <HStack justifyContent="center" marginTop={5}>
                {!vm.isBusy ? (
                  <PinInput onComplete={vm.activate.run} type="number">
                    <PinInputField autoFocus />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                ) : (
                  <Spinner />
                )}
              </HStack>
              <Center mt={4}>
                <Link marginX="auto" onClick={vm.resendActivation.run} textAlign="center" w="100%">
                  Resend activation
                </Link>
              </Center>
            </>
          )}
        </Box>
      </Box>
    </Flex>
  );
});
