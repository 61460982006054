import { Box, Spinner, Tooltip, useClipboard } from '@chakra-ui/react';
import { faCheck, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const PrismLight = require('react-syntax-highlighter/dist/esm/prism-light').default;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const js = require('react-syntax-highlighter/dist/esm/languages/prism/javascript').default;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const solidity = require('react-syntax-highlighter/dist/esm/languages/prism/solidity').default;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const json = require('react-syntax-highlighter/dist/esm/languages/prism/json').default;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const bash = require('react-syntax-highlighter/dist/esm/languages/prism/bash').default;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const codeStyle = require('react-syntax-highlighter/dist/esm/styles/prism').atomDark;

PrismLight.registerLanguage('javascript', js);
PrismLight.registerLanguage('json', json);
PrismLight.registerLanguage('bash', bash);
PrismLight.registerLanguage('solidity', solidity);

interface IProps {
  readonly text: string;
  readonly language: 'javascript' | 'json' | 'bash' | 'solidity';
  readonly wrapLines?: boolean;
  readonly isLoading?: boolean;
}

export function Colorize(props: IProps) {
  const { hasCopied, onCopy, setValue } = useClipboard(props.text);

  useEffect(() => {
    setValue(props.text);
  }, [props.text, setValue]);

  return (
    <Box maxWidth="100%" position="relative" w="100%">
      <PrismLight
        language={props.language}
        lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
        style={codeStyle}
        wrapLines={props.wrapLines}
      >
        {props.text}
      </PrismLight>
      <Box position="absolute" right={3} top={4}>
        {props.isLoading ? (
          <Spinner color="white" />
        ) : (
          <Tooltip label="Copy">
            <FontAwesomeIcon
              color={hasCopied ? 'green' : 'white'}
              cursor="pointer"
              icon={hasCopied ? faCheck : faCopy}
              onClick={onCopy}
              size="lg"
              width={20}
            />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}
