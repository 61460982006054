import { Box } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { useTitle } from 'react-use';
import { SmartContractEvents } from '../../components/smart-contract-events/SmartContractEvents';
import { SmartContractRouteVmContext } from '../detail/SmartContractDetailRoute';

export const SmartContractEventsRoute = observer(function SmartContractEventsRoute() {
  useTitle('Smart Contract Events | kriptonio');
  const context = useContext(SmartContractRouteVmContext);

  return (
    <Box h="100%" w="100%">
      {context.smartContract && context.blockchain ? (
        <SmartContractEvents smartContract={context.smartContract} blockchain={context.blockchain} />
      ) : null}
    </Box>
  );
});
