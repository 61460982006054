/**
 * Kriptonio Private API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: private
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Feature = 'Eip1559' | 'RpcEndpoint' | 'SmartContract' | 'Erc4337Bundler' | 'Erc4337Wallet' | 'VerifyingPaymasterFiat';

export const Feature = {
    Eip1559: 'Eip1559' as Feature,
    RpcEndpoint: 'RpcEndpoint' as Feature,
    SmartContract: 'SmartContract' as Feature,
    Erc4337Bundler: 'Erc4337Bundler' as Feature,
    Erc4337Wallet: 'Erc4337Wallet' as Feature,
    VerifyingPaymasterFiat: 'VerifyingPaymasterFiat' as Feature
};

