import { MenuItem, useDisclosure } from '@chakra-ui/react';
import type { BlockchainEndpointResponse } from '@web/dto/rpc/blockchainEndpointResponse';
import { generatePath, useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { BlockchainEndpointApi } from '../../../../../domain/api/BlockchainEndpointApi';
import { useCurrentSlug } from '../../../../../domain/hook/useCurrentSlug';
import { useInstance } from '../../../../../domain/hook/useInstance';
import { NotificationService } from '../../../../../domain/service/NotificationService';
import { AppRoutes } from '../../../../../router/Routes';
import { DeleteConfirmation } from '../../../components/DeleteConfirmation';

interface IProps {
  readonly blockchainEndpoint: BlockchainEndpointResponse;
}

export function DeleteBlockchainEndpointMenuItem(props: IProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const blockchainEndpointApi = useInstance(BlockchainEndpointApi);
  const notification = useInstance(NotificationService);
  const slug = useCurrentSlug();
  const navigate = useNavigate();

  const [state, runDelete] = useAsyncFn(async () => {
    try {
      const result = await blockchainEndpointApi.deleteBlockchainEndpoint(props.blockchainEndpoint.id);
      if (result.ok) {
        notification.success('Success', 'Blockchain endpoint removed');
        return navigate(generatePath(AppRoutes.blockchainEndpoint.list, { slug }));
      }

      notification.error('Error', `Error while deleting blockchain endpoint. ${result.error.stringify()}`);
    } catch (e) {
      console.error('exception while deleting blockchain endpoint', e);
      notification.error('Exception', 'Exception while deleting blockchain endpoint');
    }
  }, [props.blockchainEndpoint, blockchainEndpointApi]);

  return (
    <>
      <MenuItem color="red.500" onClick={onOpen}>
        Delete Blockchain Endpoint
      </MenuItem>
      <DeleteConfirmation
        confirmationText={props.blockchainEndpoint.title}
        description="After deleting endpoint you won't be able to access it anymore."
        isOpen={isOpen}
        isLoading={state.loading}
        onCancel={onClose}
        onConfirm={runDelete}
        title="Are you sure you want to delete this blockchain endpoint?"
      />
    </>
  );
}
