import { Box, Button, Flex, FormLabel, Heading, Stack, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { CInput, CLink, ValidatedForm, ValidatedFormControl } from '@web/toolkit';
import { observer } from 'mobx-react-lite';
import { useTitle } from 'react-use';
import * as yup from 'yup';
import authBackground from '../../../assets/auth-background-gradient-blur.jpeg';
import { useVm } from '../../../domain/hook/useVm';
import { AppRoutes } from '../../../router/Routes';
import { ForgotPasswordRouteVm } from './ForgotPasswordRouteVm';

export const ForgotPasswordRoute = observer(function ForgotPasswordRoute() {
  useTitle('Forgot Password | kriptonio');
  const vm = useVm(ForgotPasswordRouteVm);

  return (
    <Flex align="center" bgImage={`url(${authBackground})`} bgSize="cover" height="100vh" w="100%">
      <Box maxW="lg" minW={{ md: '450px' }} mx="auto" px={6}>
        <Box bg={useColorModeValue('white', 'gray.700')} boxShadow="lg" p={8} rounded="lg">
          <Heading fontSize="3xl" mb={3} textAlign="center">
            Request to reset password
          </Heading>
          <ValidatedForm onSubmit={vm.forgotPassword.run}>
            <Stack spacing={4}>
              <ValidatedFormControl
                schema={yup.string().email('Please provide valid email').required('Please provide email')}
                value={vm.form.email}
              >
                <FormLabel>Email address</FormLabel>
                <CInput autoFocus onValueChange={vm.onEmailChange} type="email" value={vm.form.email} />
              </ValidatedFormControl>
              <Button colorScheme="blue" isLoading={vm.forgotPassword.isBusy} type="submit">
                Send reset password email
              </Button>
            </Stack>
          </ValidatedForm>
          <VStack marginTop={5}>
            <Text textAlign="left">
              You know account credentials? <CLink to={AppRoutes.auth.login}>Login</CLink>
            </Text>
          </VStack>
        </Box>
      </Box>
    </Flex>
  );
});
