import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import type { OrganizationDetailResponse } from '@web/dto/api/organizationDetailResponse';
import { Observer, observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { generatePath, Outlet, useNavigate } from 'react-router-dom';
import { useCurrentSlug } from '../../domain/hook/useCurrentSlug';
import { useInstance } from '../../domain/hook/useInstance';
import { OrganizationStore } from '../../domain/store/OrganizationStore';
import { AppRoutes } from '../../router/Routes';
import { LeftMenu } from '../components/LeftMenu';

export const SettingsRoute = observer(function SettingsRoute() {
  const slug = useCurrentSlug();
  const organizationStore = useInstance(OrganizationStore);
  const navigate = useNavigate();

  const selectOrganization = useCallback(
    (org: OrganizationDetailResponse) => {
      organizationStore.selectOrganizationId(org.id);

      navigate(
        generatePath(org.isPersonal ? AppRoutes.settings.account : AppRoutes.settings.organization, {
          slug: org.slug,
        })
      );
    },
    [navigate, organizationStore]
  );

  const personalItems = useMemo(() => {
    return [
      {
        title: 'Account',
        url: generatePath(AppRoutes.settings.account, {
          slug,
        }),
      },
      {
        title: 'Billing',
        url: generatePath(AppRoutes.settings.billing, {
          slug,
        }),
      },
      {
        title: 'Security',
        url: generatePath(AppRoutes.settings.security, {
          slug,
        }),
      },
      {
        title: 'Access Tokens',
        url: generatePath(AppRoutes.settings.accessTokens, {
          slug,
        }),
      },
    ];
  }, [slug]);

  const orgItems = useMemo(() => {
    return [
      {
        title: 'Organization',
        url: generatePath(AppRoutes.settings.organization, {
          slug,
        }),
      },
      {
        title: 'Billing',
        url: generatePath(AppRoutes.settings.billing, {
          slug,
        }),
      },
      {
        title: 'Users',
        url: generatePath(AppRoutes.settings.users, {
          slug,
        }),
      },
      {
        title: 'Access Tokens',
        url: generatePath(AppRoutes.settings.accessTokens, {
          slug,
        }),
      },
    ];
  }, [slug]);

  return (
    <Container maxW="container.xl">
      <HStack my={5}>
        <Text>
          {organizationStore.currentOrganization?.isPersonal ? 'Your Personal Account' : 'Organization Account'}
        </Text>
        <Box>
          <Menu>
            {({ isOpen }) => (
              <Observer>
                {() => (
                  <>
                    <MenuButton textAlign="left">
                      <HStack>
                        <Text fontSize="md" fontWeight="bold">
                          {organizationStore.currentOrganization?.name}{' '}
                          {isOpen ? <ChevronUpIcon fontSize="xl" /> : <ChevronDownIcon fontSize="xl" />}
                        </Text>
                      </HStack>
                    </MenuButton>
                    <MenuList mx={6}>
                      {organizationStore.organizations.map((o) => (
                        <MenuItem
                          px={4}
                          onClick={() => selectOrganization(o)}
                          _focus={{ bgColor: 'initial' }}
                          _hover={{ bgColor: 'gray.100' }}
                          borderLeftWidth={3}
                          borderLeftColor={
                            o.id === organizationStore.currentOrganization?.id ? 'blue.500' : 'transparent'
                          }
                          fontWeight="semibold"
                          key={`organization-item-${o.id}`}
                        >
                          {o.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </>
                )}
              </Observer>
            )}
          </Menu>
        </Box>
      </HStack>
      <Grid
        columnGap={5}
        rowGap={5}
        h="100%"
        templateColumns={{
          base: 'repeat(auto-fit, minmax(min-content, 1fr))',
          lg: 'min-content 1fr',
        }}
      >
        <GridItem>
          <VStack spacing={6}>
            <LeftMenu items={organizationStore.currentOrganization?.isPersonal ? personalItems : orgItems} />
          </VStack>
        </GridItem>
        <GridItem>
          <Flex h="100%" minW="xs" w="100%">
            <Outlet />
          </Flex>
        </GridItem>
      </Grid>
    </Container>
  );
});
