import { Context, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Types } from '../../inversify/types';
import type { ViewModel } from '../ViewModel';
import { useContainer } from './useContainer';

export function useVm<TViewModel extends ViewModel<TProps>, TProps, TContext>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Vm: new (...args: any[]) => TViewModel,
  props: TProps = {} as TProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Context?: Context<TContext>
): TViewModel {
  const vmRef = useRef<TViewModel>();
  const container = useContainer();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const initializing = !vmRef.current;
  if (initializing) {
    const vmContainer = container.createChild();
    vmContainer.bind(Types.Params).toConstantValue(params);
    vmContainer.bind(Types.Navigate).toConstantValue(navigate);
    vmContainer.bind(Types.SearchParams).toConstantValue(searchParams);
    vmContainer.bind(Types.Props).toConstantValue(props);

    vmRef.current = vmContainer.get(Vm);
  }

  if (vmRef.current) {
    vmRef.current?.setProps(props);
    vmRef.current?.setParams(params);

    // should be run only once
    if (initializing) {
      vmRef.current.onInit();
    }

    vmRef.current.onRender();
  }

  useEffect(() => {
    return () => {
      vmRef.current?.onDestroy();
    };
  }, []);

  return vmRef.current as TViewModel;
}
