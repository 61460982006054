import { Box, Divider, Heading, HStack, Spinner, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTitle } from 'react-use';
import { useGetAccessTokens } from '../../../domain/api/OrganizationApi';
import { useInstance } from '../../../domain/hook/useInstance';
import { OrganizationStore } from '../../../domain/store/OrganizationStore';
import { AccessToken } from './components/access-token/AccessToken';

export function AccessTokensRoute() {
  useTitle('Organization Access Token | kriptonio');
  const organizationStore = useInstance(OrganizationStore);
  const { data, isLoading } = useGetAccessTokens(organizationStore.currentOrganization?.id ?? '');

  const organizationAccessTokens = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.filter((t) => t.permissions.every((p) => !p.ipfsBucketId));
  }, [data]);

  return (
    <Box maxW={700} pb={10} w="100%">
      <HStack justify="space-between">
        <Heading size="lg">{organizationAccessTokens.length === 1 ? 'Access Token' : 'Access Tokens'}</Heading>
      </HStack>
      <Divider mb={4} mt={2} />
      <VStack align="flex-start" spacing={3}>
        {isLoading ? <Spinner /> : null}
        {organizationAccessTokens.map((accessToken) => (
          <Box key={`access-token-${accessToken.id}`}>
            <AccessToken accessToken={accessToken} key={accessToken.id} />
          </Box>
        ))}
      </VStack>
    </Box>
  );
}
