import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Button, Center, Code, Collapse, HStack, Spinner, Td, Text, Tr, VStack, useDisclosure } from '@chakra-ui/react';
import type { BlockchainResponse } from '@web/dto/api/blockchainResponse';
import { CLink } from '@web/toolkit';
import { format, fromUnixTime } from 'date-fns';
import { ethers } from 'ethers';
import { useEffect, useMemo } from 'react';
import { useAsyncFn } from 'react-use';
import { createParamsObject } from '../../../../../utils/abi';
import { stringifyObject } from '../../../../../utils/json';
import { Colorize } from '../../Colorize';

interface IProps {
  readonly event: ethers.EventLog;
  readonly blockchain: BlockchainResponse;
  readonly abi: string;
}

export function SmartContractEventListItem(props: IProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [transactionResponse, fetchTransaction] = useAsyncFn(async () => {
    return await props.event.getTransaction();
  });

  const [blockResponse, fetchBlock] = useAsyncFn(async () => {
    return await props.event.getBlock();
  });

  const decodedTx = useMemo(() => {
    const tx = transactionResponse.value;
    if (!tx) {
      return null;
    }

    try {
      const iface = new ethers.Interface(props.abi);
      return iface.parseTransaction({ data: tx.data, value: tx.value });
    } catch (e) {
      return null;
    }
  }, [transactionResponse, props.abi]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    void fetchTransaction();
    void fetchBlock();
  }, [isOpen, fetchTransaction, fetchBlock]);

  return (
    <>
      <Tr cursor="pointer" onClick={() => (isOpen ? onClose() : onOpen())}>
        <Td py={2}>
          {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
          <Code>{props.event.eventSignature}</Code>
        </Td>
        <Td>
          <CLink href={`${props.blockchain.blockUrlPrefix}/${props.event.blockNumber}`} isExternal>
            {props.event.blockNumber}
          </CLink>
        </Td>
        <Td>
          <CLink href={`${props.blockchain.transactionUrlPrefix}/${props.event.transactionHash}`} isExternal>
            {props.event.transactionHash.slice(0, 8)}...
          </CLink>
        </Td>
        <Td textAlign="end">
          <Button>Details</Button>
        </Td>
      </Tr>

      <Tr>
        <Td borderBottom={0} colSpan={5} px={0} py={0}>
          <Collapse in={isOpen}>
            <VStack align="flex-start" bgColor="gray.100" px={4} py={4} w="100%">
              <VStack align="flex-start" w="100%">
                <HStack>
                  <Text fontSize="md" fontWeight="semibold">
                    Date:
                  </Text>
                  {blockResponse.loading ? (
                    <Spinner size="sm" />
                  ) : (
                    <Text whiteSpace="break-spaces" wordBreak="break-all">
                      {blockResponse.value
                        ? format(fromUnixTime(blockResponse.value?.timestamp), 'dd MMM yyyy, HH:mm:ss')
                        : null}
                    </Text>
                  )}
                </HStack>
                <VStack align="flex-start" w="100%">
                  <Text fontSize="md" fontWeight="semibold">
                    Event Parameters:
                  </Text>
                  <Colorize
                    language="json"
                    text={stringifyObject(
                      {
                        params: createParamsObject(props.event.args),
                      },
                      2
                    )}
                    wrapLines
                  />
                </VStack>
                {transactionResponse.loading ? (
                  <Center>
                    <Spinner />
                  </Center>
                ) : null}
                {!transactionResponse.loading && decodedTx ? (
                  <VStack align="flex-start" borderRadius="lg" flex={1} maxH={400} overflow="auto" w="100%">
                    <Text fontSize="md" fontWeight="semibold">
                      Smart Contract Call:
                    </Text>
                    <Colorize
                      language="json"
                      text={stringifyObject(
                        {
                          method: decodedTx.signature,
                          params: createParamsObject(decodedTx.args),
                        },
                        2
                      )}
                      wrapLines
                    />
                  </VStack>
                ) : null}
              </VStack>
            </VStack>
          </Collapse>
        </Td>
      </Tr>
    </>
  );
}
