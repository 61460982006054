import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'focus-visible/dist/focus-visible';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { SWRConfig } from 'swr';
import { ErrorBoundary } from './ErrorBoundary';
import { AjaxService, type IAjaxCallOptions } from './domain/service/AjaxService';
import { SessionStore } from './domain/store/SessionStore';
import { UiStore } from './domain/store/UiStore';
import { env } from './env';
import { ContainerContext, container } from './inversify/container';
import { Router } from './router/Router';
import { extendedTheme } from './theme';

export const App = observer(function App() {
  const session = container.get(SessionStore);
  const uiStore = container.get(UiStore);
  const ajaxService = container.get(AjaxService);

  const fetcher = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (args: any) => {
      const [url, options]: [string, IAjaxCallOptions | undefined] = args;

      return ajaxService.get(url, options).then((res) => {
        if (res.ok) {
          return res.data;
        }

        return Promise.reject(res.error);
      });
    };
  }, [ajaxService]);

  const googleLoadingFailed = useCallback(() => {
    console.error('error while loading google script');
  }, []);

  return (
    <ErrorBoundary>
      <ColorModeScript initialColorMode={extendedTheme.config.initialColorMode} storageKey="kriptonio-color" />
      <ContainerContext.Provider value={container}>
        <ChakraProvider theme={extendedTheme}>
          <GoogleOAuthProvider
            clientId={env.google.clientId}
            onScriptLoadError={googleLoadingFailed}
            onScriptLoadSuccess={uiStore.setGoogleScriptLoaded}
          >
            <SWRConfig
              value={{
                fetcher,
                revalidateOnMount: true,
                revalidateOnFocus: false,
                revalidateIfStale: true,
                shouldRetryOnError: false,
              }}
            >
              <Router />
            </SWRConfig>
          </GoogleOAuthProvider>
        </ChakraProvider>
      </ContainerContext.Provider>
    </ErrorBoundary>
  );
});
