import { Link, LinkProps } from '@chakra-ui/react';
import type { PropsWithChildren } from 'react';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface IProps extends LinkProps {
  to?: string;
}

export function CLink(props: PropsWithChildren<IProps>) {
  const { to, href, ...rest } = props;
  const navigate = useNavigate();

  const onClick = useCallback(
    (e: React.SyntheticEvent) => {
      if (href) {
        return;
      }

      if (!to) {
        return;
      }

      e.preventDefault();
      navigate(to);
    },
    [navigate, to, href]
  );

  return (
    <Link isExternal href={href ?? to} onClick={onClick} {...rest}>
      {props.children}
    </Link>
  );
}
