import { action, makeObservable, observable } from 'mobx';
import { singleton } from '../../inversify/decorator';

@singleton()
export class UiStore {
  @observable
  public googleScriptLoaded = false;

  public interrupedUrl: string | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  public setGoogleScriptLoaded = () => {
    this.googleScriptLoaded = true;
  };

  @action
  public setInterruptedUrl = (url: string | null) => {
    this.interrupedUrl = url;
  };
}
