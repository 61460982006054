import { ApiErrorResponse } from '@web/dto/api/apiErrorResponse';
import { CreatePaymasterEndpointRequest } from '@web/dto/paymaster/createPaymasterEndpointRequest';
import { OrganizationUsageResponse } from '@web/dto/paymaster/organizationUsageResponse';
import { PaymasterEndpointDetailResponse } from '@web/dto/paymaster/paymasterEndpointDetailResponse';
import { PaymasterEndpointResponse } from '@web/dto/paymaster/paymasterEndpointResponse';
import { SetPaymasterPolicyRequest } from '@web/dto/paymaster/setPaymasterPolicyRequest';
import { SponsorshipPolicyResponse } from '@web/dto/paymaster/sponsorshipPolicyResponse';
import { AxiosError } from 'axios';
import { env } from '../../env';
import { singleton } from '../../inversify/decorator';
import { useSwr } from '../hook/useSwr';
import { AjaxService } from '../service/AjaxService';

@singleton()
export class PaymasterEndpointApi {
  constructor(private readonly ajax: AjaxService) {}

  public get = (id: string) => {
    return this.ajax.get<PaymasterEndpointDetailResponse>(`v1/endpoints/${id}`, {
      baseURL: env.paymaster,
    });
  };

  public getSponsorshipPolicy = (organizationId: string) => {
    return this.ajax.get<SponsorshipPolicyResponse>(`v1/organizations/${organizationId}/sponsorship-policy`, {
      baseURL: env.paymaster,
    });
  };

  public createPaymasterEndpoint = (organizationId: string, data: CreatePaymasterEndpointRequest) => {
    return this.ajax.post<PaymasterEndpointResponse>(`v1/organizations/${organizationId}/endpoints`, {
      data,
      baseURL: env.paymaster,
    });
  };

  public setSponsorshipPolicy = (organizationId: string, data: SetPaymasterPolicyRequest) => {
    return this.ajax.put(`v1/organizations/${organizationId}/sponsorship-policy`, {
      data,
      baseURL: env.paymaster,
    });
  };

  public delete = (id: string) => {
    return this.ajax.delete(`v1/endpoints/${id}`, {
      baseURL: env.paymaster,
    });
  };
}

export function useGetPaymasterEndpoints(organizationId: string) {
  return useSwr<PaymasterEndpointResponse[]>(`v1/organizations/${organizationId}/endpoints`, {
    baseURL: env.paymaster,
  });
}

export function useGetPaymasterEndpoint(paymasterEndpointId: string, smartContractId: string | undefined) {
  const url = `v1/endpoints/${paymasterEndpointId}`;

  return useSwr<PaymasterEndpointDetailResponse, AxiosError<ApiErrorResponse>>(
    smartContractId ? `${url}?smartContractId=${smartContractId}` : url,
    {
      baseURL: env.paymaster,
    }
  );
}

export function useGetPaymasterUsage(organizationId: string) {
  return useSwr<OrganizationUsageResponse>(`v1/organizations/${organizationId}/usage`, {
    baseURL: env.paymaster,
  });
}
