import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Text,
  VStack,
} from '@chakra-ui/react';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { BlockchainEndpointResponse } from '@web/dto/rpc/blockchainEndpointResponse';
import { useInstance } from '../../../../../domain/hook/useInstance';
import { UrlService } from '../../../../../domain/service/UrlService';
import { Colorize } from '../../../components/Colorize';

interface IProps {
  readonly blockchainEndpoint: BlockchainEndpointResponse;
  readonly containerWidth: number | undefined;
}

export function BlockchainEndpointSection(props: IProps) {
  const urlService = useInstance(UrlService);
  const walletAddress = '0xBacb4D5560EA97b4574ce53087fE809eE6Fc3f23';

  const url = urlService.createBlockchainEndpointUrl(props.blockchainEndpoint.accessToken);

  return (
    <VStack align="flex-start" maxW={props.containerWidth}>
      <Accordion allowToggle defaultIndex={0} w="100%">
        <AccordionItem borderRadius="lg" borderWidth="1px" mb={2}>
          <AccordionButton>
            <Flex align="center" w="100%">
              <FontAwesomeIcon icon={faCode} size="lg" width={20} />
              <Text ml={3} textAlign="left">
                Example usage with ethers.js
              </Text>
            </Flex>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Colorize
              language="javascript"
              text={`const ethers = require('ethers');

var provider = new ethers.JsonRpcProvider(
  '${url}'
);

async function checkBalance() {
  try {
    const address = '${walletAddress}';
    const balanceInWei = await provider.getBalance(address);
    const balance = ethers.formatEther(balanceInWei);

    console.log("Balance in wei:", balanceInWei.toString());
    console.log("Balance in ETH:", balance);
  } catch (error) {
    console.log(error);
  }
}

checkBalance();`}
            />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem borderRadius="lg" borderWidth="1px" mb={2}>
          <AccordionButton>
            <Flex align="center" w="100%">
              <FontAwesomeIcon icon={faCode} size="lg" width={20} />
              <Text ml={3} textAlign="left">
                Example usage with viem
              </Text>
            </Flex>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Colorize
              language="javascript"
              text={`import { Hex, createPublicClient, formatEther, http } from 'viem';

const client = createPublicClient({
  transport: http('${url}')
});

async function checkBalance() {
  try {
    const address: Hex = '${walletAddress}';
    const balanceInWei = await client.getBalance({address});
    const balance = formatEther(balanceInWei);

    console.log("Balance in wei:", balanceInWei.toString());
    console.log("Balance in ETH:", balance);
  } catch (error) {
    console.log(error);
  }
}

checkBalance();`}
            />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem borderRadius="lg" borderWidth="1px">
          <AccordionButton>
            <Flex align="center" w="100%">
              <FontAwesomeIcon icon={faCode} size="lg" width={20} />
              <Text ml={3} textAlign="left">
                Example usage with web3.js
              </Text>
            </Flex>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Colorize
              language="javascript"
              text={`const Web3 = require("web3");

const web3 = new Web3(new Web3.providers.HttpProvider(
  '${url}'
));

async function checkBalance() {
  try {
    let address = '${walletAddress}';
    const balanceInWei = await web3.eth.getBalance(address);
    const balance = web3.utils.fromWei(balanceInWei);

    console.log("Balance in wei:", balanceInWei);
    console.log("Balance in ETH:", balance);
  } catch (error) {
    console.log(error);
  }
}

checkBalance();`}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </VStack>
  );
}
