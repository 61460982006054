import { QuestionIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  SimpleGrid,
  Spinner,
  Switch,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import { InputType } from '@web/dto/api/inputType';
import { CInput, ValidatedForm, ValidatedFormControl } from '@web/toolkit';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { NumericFormat } from 'react-number-format';
import * as yup from 'yup';
import { useVm } from '../../../../../../domain/hook/useVm';
import { Colorize } from '../../../../components/Colorize';
import { BlockchainNetworkSelect } from '../../../../endpoint/new/components/BlockchainNetworkSelect';
import { StepControls } from '../StepControls';
import { SmartContractViaTemplateVm } from './SmartContractViaTemplateVm';
import { TemplateType } from './components/TemplateType';

const steps = [
  { id: 'type', label: 'Select Type' },
  { id: 'info', label: 'Basic Information' },
  { id: 'source-code', label: 'Source Code' },
];

export const SmartContractViaTemplate = observer(function SmartContractViaTemplate() {
  const vm = useVm(SmartContractViaTemplateVm);
  const gridItemRef = useRef<HTMLDivElement>(null);
  const gridDimensions = useSize<HTMLDivElement>(gridItemRef);
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });

  return (
    <VStack w="100%" spacing={10}>
      <Steps maxW="container.md" responsive variant="circles-alt" colorScheme="blue" activeStep={activeStep} py={10}>
        {steps.map(({ id, label }) => (
          <Step label={label} key={id}>
            {id === 'type' ? (
              vm.loadTemplates.isBusy ? (
                <Center>
                  <Spinner />
                </Center>
              ) : (
                <VStack align="flex-start" w="100%" maxW="container.md" spacing={4}>
                  <Heading size="md">What type of smart contract do you want to create?</Heading>

                  <SimpleGrid w="100%" columns={vm.templates.length} spacing={2}>
                    {vm.templates.map((t) => (
                      <TemplateType
                        key={`template-${t.id}`}
                        onClick={() => {
                          vm.setTemplate(t);
                        }}
                        selected={vm.selectedTemplate?.id === t.id}
                        text={t.title}
                        description={t.subtitle}
                      />
                    ))}
                  </SimpleGrid>
                  <StepControls onNext={nextStep} nextDisabled={!vm.selectedTemplate} />
                </VStack>
              )
            ) : null}
            {id === 'info' && (
              <Box w="100%" maxW="container.md">
                <Heading pb="5" size="md">
                  Basic Information
                </Heading>

                <ValidatedForm onSubmit={nextStep}>
                  <VStack spacing={4} width="100%">
                    <ValidatedFormControl
                      isRequired
                      schema={yup.string().required('Please provide smart contract title')}
                      value={vm.form.title}
                    >
                      <FormLabel>Title</FormLabel>
                      <CInput autoFocus onValueChange={vm.setTitle} type="text" value={vm.form.title} />
                    </ValidatedFormControl>
                    <BlockchainNetworkSelect
                      chainId={vm.blockchain?.chainId}
                      blockchains={vm.blockchains}
                      onBlockchainChange={vm.setBlockchainId}
                    />
                  </VStack>
                  <StepControls onBack={prevStep} />
                </ValidatedForm>
              </Box>
            )}
            {id === 'source-code' && (
              <Grid w="100%" maxW="container.xl" templateColumns="repeat(5, 1fr)" gap={6} py={5}>
                <GridItem colSpan={{ base: 5, md: 2 }}>
                  <ValidatedForm
                    style={{ width: '100%' }}
                    onSubmit={vm.create.run}
                    onSubmitValidationError={vm.handleValidationError}
                    ref={vm.formRef}
                  >
                    <VStack align="flex-start" spacing={4}>
                      {vm.selectedTemplate ? (
                        <>
                          <VStack spacing={4} width="100%">
                            <VStack align="flex-start" w="100%">
                              <Text fontSize="xl" fontWeight="semibold" mb={2}>
                                Smart Contract Configuration
                              </Text>
                              {vm.selectedTemplate.inputSchema.properties.map((p) => (
                                <ValidatedFormControl
                                  key={`smart-contract-property-${p.key}`}
                                  schema={vm.createYupSchema(p)}
                                  value={vm.form.templateInput[p.key] as string}
                                  isRequired={p.isRequired}
                                >
                                  <Flex flexDir="row">
                                    <FormLabel mr={0}>{p.title}</FormLabel>
                                    {p.help ? (
                                      <Tooltip label={p.help}>
                                        <QuestionIcon marginLeft={1} color="gray.400" />
                                      </Tooltip>
                                    ) : null}
                                  </Flex>
                                  {p.type === InputType.Boolean ? (
                                    <Switch
                                      checked={vm.form.templateInput[p.key] as boolean}
                                      onChange={(e) => vm.setBooleanInputValue(p.key, e.target.checked)}
                                    />
                                  ) : p.type === InputType.Number ? (
                                    <NumericFormat
                                      customInput={CInput}
                                      decimalScale={0}
                                      placeholder="0"
                                      onValueChange={(value) => vm.setNumberInputValue(p.key, value.floatValue)}
                                      thousandSeparator
                                    />
                                  ) : (
                                    <CInput
                                      onValueChange={(v) => vm.setStringValue(p.key, v)}
                                      type="text"
                                      value={(vm.form.templateInput[p.key] as string) ?? ''}
                                    />
                                  )}
                                </ValidatedFormControl>
                              ))}
                            </VStack>
                          </VStack>
                          <StepControls onBack={prevStep} isLoading={vm.create.isBusy} final />
                        </>
                      ) : null}
                    </VStack>
                  </ValidatedForm>
                </GridItem>
                <GridItem ref={gridItemRef} colSpan={{ base: 5, md: 3 }}>
                  <Flex position="sticky" top={0} flex={1} maxW={gridDimensions?.width} maxH={900} overflow="auto">
                    {vm.selectedTemplate ? (
                      <Colorize
                        isLoading={vm.compileTemplate.isBusy}
                        language="solidity"
                        text={vm.sourceCode ?? '\n\n\n\n\n\n\n'}
                      />
                    ) : null}
                  </Flex>
                </GridItem>
              </Grid>
            )}
          </Step>
        ))}
      </Steps>
    </VStack>
  );
});
