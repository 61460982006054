import { Box, Center, HStack, Text, VStack } from '@chakra-ui/layout';
import { Heading, Spinner } from '@chakra-ui/react';
import { CLink } from '@web/toolkit';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';
import { useTitle } from 'react-use';
import { useInstance } from '../../../domain/hook/useInstance';
import { BlockchainStore } from '../../../domain/store/BlockchainStore';
import { HexAddress } from '../../blockchain/components/HexAddress';
import { PaymasterEndpointUrl } from '../../blockchain/components/PaymasterEndpointUrl';
import { SectionDivider } from '../../blockchain/components/SectionDivider';
import { PaymasterEndpointDetailRouteVmContext } from '../detail/PaymasterEndpointDetailRoute';
import { usePaymasterTitle } from '../hooks/usePaymasterTitle';
import { PaymasterStats } from './components/PaymasterStats';

export const PaymasterEndpointGeneralRoute = observer(function PaymasterEndpointGeneralRoute() {
  useTitle('Paymaster Endpoint General | kriptonio');
  const context = useContext(PaymasterEndpointDetailRouteVmContext);
  const blockchainStore = useInstance(BlockchainStore);
  const title = usePaymasterTitle(context.paymasterEndpoint);

  const blockchain = useMemo(() => {
    return blockchainStore.blockchains.find((b) => b.chainId === context.paymasterEndpoint?.paymaster.chainId);
  }, [blockchainStore.blockchains, context.paymasterEndpoint?.paymaster.chainId]);

  return (
    <Box pb={10} w="100%">
      <HStack justify="space-between">
        <Heading size="lg">General</Heading>
      </HStack>
      {!context.paymasterEndpoint ? (
        <Center marginTop={5} w="100%">
          <Spinner />
        </Center>
      ) : (
        <>
          <SectionDivider />
          <VStack align="flex-start">
            <Box>
              <Text fontSize="md" fontWeight="semibold">
                Title
              </Text>
              <Text>{title}</Text>
            </Box>
            {context.paymasterEndpoint.wallet ? (
              <Box>
                <Text fontSize="md" fontWeight="semibold">
                  Wallet
                </Text>
                <CLink href={`${blockchain?.addressUrlPrefix}/${context.paymasterEndpoint.wallet}`} isExternal>
                  Wallet Details
                </CLink>
              </Box>
            ) : null}
            <Box>
              <Text fontSize="md" fontWeight="semibold">
                Blockchain
              </Text>
              <Text>{blockchain?.fqdn}</Text>
            </Box>
            <Box>
              <HStack>
                <Text fontSize="md" fontWeight="semibold">
                  Paymaster Url
                </Text>
              </HStack>
              <PaymasterEndpointUrl paymasterEndpoint={context.paymasterEndpoint} />
            </Box>
            <Box>
              <Text fontSize="md" fontWeight="semibold">
                Created At
              </Text>
              <Text>{format(new Date(context.paymasterEndpoint.createdAt), 'MMM dd yyy, HH:mm:ss')}</Text>
            </Box>
          </VStack>

          {context.paymasterEndpoint.wallet ? (
            <VStack align="flex-start">
              <Heading marginTop={6} size="md">
                Policies
              </Heading>
              <Box>
                <Text fontSize="md" fontWeight="semibold">
                  Can sponsor transaction from wallet
                </Text>
                <HexAddress fontSize="small" address={context.paymasterEndpoint.wallet} />
              </Box>
            </VStack>
          ) : null}

          {blockchain && context.paymasterEndpoint ? (
            <PaymasterStats blockchain={blockchain} paymasterEndpointId={context.paymasterEndpoint.id} />
          ) : null}
        </>
      )}
    </Box>
  );
});
