import { ApiErrorCode } from '@web/dto/api/apiErrorCode';
import type { AppleLoginBody } from '@web/dto/api/appleLoginBody';
import type { GoogleLoginBody } from '@web/dto/api/googleLoginBody';
import type { LoginBody } from '@web/dto/api/loginBody';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { appleAuthHelpers } from 'react-apple-signin-auth';
import { ViewModel } from '../../../domain/ViewModel';
import { AsyncAction } from '../../../domain/async/AsyncAction';
import { NotificationService } from '../../../domain/service/NotificationService';
import { AnalyticsEvent } from '../../../domain/service/analytics/AnalyticsEvent';
import { AnalyticsService } from '../../../domain/service/analytics/AnalyticsService';
import { SessionStore } from '../../../domain/store/SessionStore';
import { UiStore } from '../../../domain/store/UiStore';
import { transient } from '../../../inversify/decorator';
import { AppRoutes } from '../../../router/Routes';

export interface IAppleLoginResponse {
  authorization: {
    code: string;
    id_token: string;
  };
  user?: {
    email: string;
    name?: {
      firstName: string;
      lastName: string;
    };
  };
}

@transient()
export class LoginRouteVm extends ViewModel {
  @observable
  public twoFactorStep = false;

  @observable
  public loginData: LoginBody = {
    email: '',
    password: '',
    twoFactorCode: null,
  };

  @observable
  public googleLoginData: GoogleLoginBody = {
    accessToken: '',
    twoFactorCode: null,
  };

  @observable
  public appleLoginData: AppleLoginBody = {
    token: '',
    firstname: '',
    lastname: '',
    platform: 'Web',
    twoFactorCode: null,
  };

  constructor(
    private readonly notification: NotificationService,
    private readonly session: SessionStore,
    public readonly uiStore: UiStore,
    private readonly analytics: AnalyticsService
  ) {
    super();
    makeObservable(this);
    this.analytics.track(AnalyticsEvent.LoginStarted);
  }

  @action
  public onEmailChange = (email: string) => {
    this.loginData.email = email;
  };

  @action
  public onPasswordChange = (password: string) => {
    this.loginData.password = password;
  };

  @action
  public devLogin = () => {
    this.loginData.email = 'john.smith@kriptonio.com';
    this.loginData.password = 'asdfasdf';

    return this.login.run();
  };

  @action
  public loginWithCode = async (value: string) => {
    if (!this.loginData) {
      return this.notification.error('Login data missing', 'Please refresh page and try again');
    }

    const code = parseInt(value);
    if (isNaN(code)) {
      return this.notification.warn('Code invalid', 'Please enter valid 2fa code');
    }

    if (this.googleLoginData.accessToken) {
      this.googleLoginData.twoFactorCode = code;
      await this.googleLogin.run();
    } else if (this.appleLoginData.token) {
      this.appleLoginData.twoFactorCode = code;
      await this.appleLogin.run();
    } else {
      this.loginData.twoFactorCode = code;
      await this.login.run();
    }
  };

  public handleGoogleLoginError = (err: unknown) => {
    console.error('google login error', err);
  };

  private fillAppleLoginData = (): Promise<boolean> => {
    return new Promise((resolve) => {
      appleAuthHelpers.signIn({
        authOptions: {
          clientId: 'com.kriptonio.app',
          scope: 'email name',
          redirectURI: window.location.origin,
          state: 'state',
          usePopup: true,
        },
        onSuccess: (data: IAppleLoginResponse) => {
          this.appleLoginData.token = data.authorization.code;
          this.appleLoginData.firstname = data.user?.name?.firstName;
          this.appleLoginData.lastname = data.user?.name?.lastName;
          resolve(true);
        },
        onError: (err: unknown) => {
          console.error('error while processing apple login', err);
          resolve(false);
        },
      });
    });
  };

  public appleLogin = new AsyncAction(async () => {
    try {
      const filled = await this.fillAppleLoginData();
      if (!filled) {
        return this.notification.error('Error', 'Error while logging in with apple. Please check provided credentials');
      }

      const result = await this.session.appleLogin(this.appleLoginData);
      if (result.ok) {
        this.analytics.track(AnalyticsEvent.LoginCompleted);
        return this.openApp();
      }

      if (result.error.code === ApiErrorCode.MissingTwoFactorCode) {
        return runInAction(() => {
          this.twoFactorStep = true;
        });
      }

      if (result.error.code === ApiErrorCode.TwoFactorCodeInvalid) {
        return this.notification.error('Invalid code', 'Provided code is invalid. Please try again.');
      }

      if (result.error.code === ApiErrorCode.TwoFactorTooManyFailedAttempts) {
        return this.notification.error(
          'Account locked',
          'Your account is locked because of too many failed 2fa attempts. Please contact our customer support to help you to unlock your account'
        );
      }

      this.notification.warn('Login failed', `Authentication data invalid. ${result.error.code}`);
    } catch (e) {
      console.error(`exception while doing login. ${e}`);
      this.notification.error('Login failed', 'Error while logging in');
    }
  });

  public googleLogin = new AsyncAction(async () => {
    try {
      const result = await this.session.googleLogin(this.googleLoginData);
      if (result.ok) {
        this.analytics.track(AnalyticsEvent.LoginCompleted);
        return this.openApp();
      }

      if (result.error.code === ApiErrorCode.MissingTwoFactorCode) {
        return runInAction(() => {
          this.twoFactorStep = true;
        });
      }

      if (result.error.code === ApiErrorCode.TwoFactorCodeInvalid) {
        return this.notification.error('Invalid code', 'Provided code is invalid. Please try again.');
      }

      if (result.error.code === ApiErrorCode.TwoFactorTooManyFailedAttempts) {
        return this.notification.error(
          'Account locked',
          'Your account is locked because of too many failed 2fa attempts. Please contact our customer support to help you to unlock your account'
        );
      }

      this.notification.warn('Login failed', `Authentication data invalid. ${result.error.code}`);
    } catch (e) {
      console.error(`exception while doing login. ${e}`);
      this.notification.error('Login failed', 'Error while logging in');
    }
  });

  public login = new AsyncAction(async () => {
    try {
      const result = await this.session.login(this.loginData);
      if (result.ok) {
        this.analytics.track(AnalyticsEvent.LoginCompleted);
        return this.openApp();
      }

      if (result.error.code === ApiErrorCode.MissingTwoFactorCode) {
        return runInAction(() => {
          this.twoFactorStep = true;
        });
      }

      if (result.error.code === ApiErrorCode.TwoFactorCodeInvalid) {
        return this.notification.error('Invalid code', 'Provided code is invalid. Please try again.');
      }

      if (result.error.code === ApiErrorCode.TwoFactorTooManyFailedAttempts) {
        return this.notification.error(
          'Account locked',
          'Your account is locked because of too many failed 2fa attempts. Please contact our customer support to help you to unlock your account'
        );
      }

      this.notification.warn('Login failed', `Authentication data invalid. ${result.error.code}`);
    } catch (e) {
      console.error(`exception while doing login. ${e}`);
      this.notification.error('Login failed', 'Error while logging in');
    }
  });

  @action
  public openApp = () => {
    if (this.uiStore.interrupedUrl) {
      window.location.replace(this.uiStore.interrupedUrl);
      this.uiStore.setInterruptedUrl(null);
    }

    return this.navigate(AppRoutes.index);
  };
}
