import { Button, ButtonProps } from '@chakra-ui/react';
import { faShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useCurrentSlug } from '../../domain/hook/useCurrentSlug';
import { AppRoutes } from '../../router/Routes';

interface IProps extends ButtonProps {
  readonly onClick?: () => void;
  readonly isLoading: boolean;
  readonly loadingText?: string;
  readonly text: string;
  readonly upgrade?: boolean;
  readonly upgradeText?: string;
}

export function CtaButton(props: IProps) {
  const { isLoading, text, upgrade, upgradeText, onClick, ...buttonProps } = props;
  const navigate = useNavigate();
  const currentSlug = useCurrentSlug();

  const localOnClick = useCallback(() => {
    if (upgrade) {
      return navigate(generatePath(AppRoutes.subscription.change, { slug: currentSlug }));
    }

    if (!onClick) {
      return;
    }

    return onClick();
  }, [upgrade, onClick, navigate, currentSlug]);

  return (
    <Button
      leftIcon={upgrade ? <FontAwesomeIcon icon={faShield} /> : undefined}
      colorScheme={upgrade ? 'orange' : 'blue'}
      flexShrink={0}
      onClick={localOnClick}
      isLoading={isLoading}
      loadingText={props.loadingText}
      {...buttonProps}
    >
      {upgrade ? upgradeText : text}
    </Button>
  );
}
