import type { SmartContractDetailResponse } from '@web/dto/api/smartContractDetailResponse';
import { makeObservable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { SmartContractApi } from '../../../../../../domain/api/SmartContractApi';
import { AsyncAction } from '../../../../../../domain/async/AsyncAction';
import { AnalyticsEvent } from '../../../../../../domain/service/analytics/AnalyticsEvent';
import { AnalyticsService } from '../../../../../../domain/service/analytics/AnalyticsService';
import { NotificationService } from '../../../../../../domain/service/NotificationService';
import { OrganizationStore } from '../../../../../../domain/store/OrganizationStore';
import { ViewModel } from '../../../../../../domain/ViewModel';
import { transient } from '../../../../../../inversify/decorator';
import { AppRoutes } from '../../../../../../router/Routes';

export interface SmartContractDeleteMenuItemProps {
  smartContract: SmartContractDetailResponse;
}

@transient()
export class SmartContractDeleteMenuItemVm extends ViewModel<SmartContractDeleteMenuItemProps> {
  constructor(
    private readonly smartContractApi: SmartContractApi,
    private readonly analytics: AnalyticsService,
    private readonly notification: NotificationService,
    private readonly organizationStore: OrganizationStore
  ) {
    super();
    makeObservable(this);
  }

  public delete = new AsyncAction(async () => {
    try {
      const result = await this.smartContractApi.delete(this.props.smartContract.id);
      if (result.ok) {
        this.analytics.track(AnalyticsEvent.SmartContractDeleted);
        return this.navigate(
          generatePath(AppRoutes.smartContract.list, {
            slug: this.organizationStore.currentSlug,
          })
        );
      }

      this.notification.error('Delete error', result.error.stringify());
    } catch (e) {
      console.error(e);
      this.notification.error('Unexpected exception', 'Unexpected error while deleting smart contract');
    }
  });
}
